import React, { useEffect } from 'react';

import './Infofrais.scss';

const Infofrais = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (


        <div className="Frais-Piece">
            <div className="Etude-Frais-center">
                {/* Rest of your content */}


                <div className="First-frais">
                    <h1 className="Demanfrais" id="title1">Frais d'admission et reçus</h1>

                    <div className="frais-line"></div>
                    <div className="First-frais-wrap">

                        <div className="Etapes-frais">
                            <h1 className="DemanFI" id="title1" >Montant des frais </h1>

                        </div>

                    </div>
                    <div className="First-frais2">

                        <h1 className="fraisIs" id="title2">Les frais d’admission en vigueur pour l'année 2024 sont de</h1>
                        <h1 className="fraisIsI" id="title2" >
                            <li> Candidats nigériens : 10.000 F
                            </li>

                            <li>
                                Candidats étrangers : 20.000 F
                            </li>

                        </h1>

                        <h1 className="fraisIsII" id="title2" >
                            Le CSP Faza se démarque par son approche pédagogique distinctive, des projets innovants axés sur des contributions sociales significatives, et une renommée locale bien méritée.
                            Modernité et Infrastructure propices à l'Apprentissage
                            Explorez nos installations modernes, équipées des dernières technologies éducatives.  </h1>
                    </div>
                    <div className="frais-line2"></div>

                    <div className="First-fraisF">
                        <div className="Etapes-frais2">
                            <h1 className="DemanFI2" id="title1" >Paiement des frais d'admission</h1>

                        </div>

                        <h1 className="fraisIs3" id="title2">Les paiement des frais d'admission se font uniquement en comptant</h1>


                    </div>

                    <div className="frais-line3"></div>

                    <div className="First-fraisF2">
                        <div className="Etapes-frais3">
                            <h1 className="DemanFI3" id="title1" >Remboursement des frais d'admission</h1>

                        </div>

                        <h1 className="fraisIs4" id="title2">Les paiement des frais d'admission se font uniquement en comptant</h1>


                    </div>
                </div>



            </div>
        </div>





    );

};


export default Infofrais;