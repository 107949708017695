import React, { useState, useEffect } from 'react';

import './InfoReg.scss';

const InfoReg = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);





    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesA(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesA, setDropdownStatesA] = useState({
        RegAI: false,
        RegA2I: false,



    });


    return (


        <div className="Etude-Reg">
            <div className="Etude-Reg-center">
                {/* Rest of your content */}


                <div className="First-Reg">
                    <h1 className="Reg" id="title1">Registrariat</h1>
                    <div className="First-Reg-wrap">

                        <div className="Etapes-Reg">
                            <h1 className="RegI" id="title1" >                                                Gardien des registres étudiants, le Registrariat contribue à la réalisation de la formation des étudiants en les accompagnant tout au long de leur parcours universitaire, et ce, dans le cadre de la réglementation universitaire et des mandats qui lui sont confiés. </h1>

                        </div>
                        <div className="Drop-Reg-wrap">
                            <div className="Pieces-Reg" >
                                <div className={`RegII  ${dropdownStatesA.RegAI ? 'dropdownCA-open' : 'dropdownCA-closed'}`}>
                                    <div className="ARegWrap" onClick={(e) => handleInformationAClick('RegAI', e)} >
                                        <h1 className="RegAI"> Principales activité du registrariat </h1>

                                        <span className={`signReg ${dropdownStatesA.RegAI ? 'minus-signReg' : 'plus-signReg'}`} >
                                            {dropdownStatesA.RegAI ? '_' : '+'}
                                        </span>

                                    </div>
                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentReg ${dropdownStatesA.RegAI ? 'active' : ''}`}>

                                        <div className="firstReg">


                                            <h1 className="Info-titleA">

                                                <li>
                                                    Recrutement
                                                </li>
                                                <li>
                                                    Admission
                                                </li>
                                                <li>
                                                    Inscription
                                                </li>
                                                <li>
                                                    Gestion du dossier universitaire étudiant
                                                </li>
                                                <li>
                                                    Diplomation
                                                </li>
                                                <li>
                                                    Service à la clientèle
                                                </li>

                                                <li>
                                                    Soutien logistique
                                                </li>

                                                <li>
                                                    Systèmes d’information
                                                </li>

                                                <li>
                                                    Information / publications officielles
                                                </li>

                                                <li>
                                                    Déclarations des clientèles
                                                </li>



                                            </h1>
                                        </div>


                                    </div>
                                </div>

                            </div>



                            <div className="Pieces-Reg2">
                                <div className={`Reg2II  ${dropdownStatesA.RegA2I ? 'dropdownCA-open' : 'dropdownCA-closed'}`}>
                                    <div className="ARegWrap" onClick={(e) => handleInformationAClick('RegA2I', e)} >
                                        <h1 className="RegA2I" id="title1" >Organismes externes</h1>

                                        {/* Dropdown content */}
                                        <span className={`signReg ${dropdownStatesA.RegA2I ? 'minus-signReg' : 'plus-signReg'}`} >
                                            {dropdownStatesA.RegA2I ? '_' : '+'}
                                        </span>

                                    </div>
                                    <div className={`dropdown-contentReg ${dropdownStatesA.RegA2I ? 'active' : ''}`}>

                                        <div className="SecondReg">
                                            <h1 className="Info-titleA">
                                                Le Registrariat procède à des vérifications de diplôme et de scolarité à la demande d’organismes externes (représentants d’employeurs, d’établissement d’enseignement, etc.).  Ces demandes sont effectuées par courriel à : cspFaza@gmail.com
                                            </h1>
                                            <h1 className="Info-titleA">
                                                La demande doit contenir :
                                            </h1>
                                            <h1 className="Info-titleA">

                                                <li>
                                                    Nom et prénom de l’étudiant
                                                </li>
                                                <li>
                                                    Date de naissance
                                                </li>
                                                <li>
                                                    Type de vérification à effectuer
                                                </li>
                                                <li>
                                                    Autorisation signée de l’étudiant
                                                </li>

                                            </h1>

                                        </div>

                                    </div>

                                </div>


                            </div>


                        </div>







                    </div>


                </div>



            </div>
        </div >





    );

};


export default InfoReg;