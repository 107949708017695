import React, { useEffect } from 'react';

import './Infobottin.scss';

const Infocouts = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (


        <div className="Etude-Bott">
            <div className="Etude-Bott-center">
                {/* Rest of your content */}


                <table>
                    <thead>
                        <tr>
                            <th >Département</th>
                            <th>Contact</th>

                        </tr>
                    </thead>
                    <tbody>
                        {/* Jardin */}
                        <tr id="Jardinb" >
                            <td colspan="3">
                                <h2>Services</h2>
                            </td>

                        </tr>

                        <tr>
                            <td> <h1>Registrariat</h1></td>
                            <td>
                                <h1>227 89 94 48 48</h1>
                            </td>


                        </tr>

                        <tr>
                            <td> <h1>Infirmerie</h1></td>
                            <td>
                                <h1>227 89 94 48 48</h1>

                            </td>


                        </tr>

                        {/* Primaire */}
                        <tr id="Jardinb">
                            <td colspan="3"><h2>Administration</h2></td>
                        </tr>
                        <tr>
                            <td> <h1> Surveillance</h1></td>
                            <td>
                                <h1>227 89 94 48 48</h1>

                            </td>


                        </tr>

                        <tr>
                            <td> <h1>Direction</h1></td>
                            <td>
                                <h1>227 89 94 48 48</h1>

                            </td>

                        </tr>

                        <tr>
                            <td> <h1>Business</h1></td>
                            <td>
                                <h1>227 89 94 48 48</h1>

                            </td>

                        </tr>



                    </tbody>
                </table>
                <div className="First-couts-Title2">
                    <h1>Mis à jour le 21 Janvier 2024</h1>

                </div>
            </div>
        </div>





    );

};


export default Infocouts;