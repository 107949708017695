import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './Diplomes.scss';
import HeaderX from './HeaderX';
import BlackSectionX from './BlackSectionX';
import InfoDip from './InfoDipPage/InfoDip';
import ModernFooter from './ModernFooter';
import InfoVerticalePageX from './InfoVerticalePageX';

const Registraire = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const location = useLocation(); // Get the current location


    return (
        <div>
            <HeaderX />

            {/* Rest of your content */}
            <div className="Grey-section-Dip">
                <div className="Grey-wrap-Dip">
                    <div className="Grey-center-Dip">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaDip-wrap">
                                <h1 className="Faza-Dip" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Diparow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposDip-wrap">
                                <h1 className="AproposDip" id="titleA">
                                    Autres
                                </h1>

                                <h2 className="DiparowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="ADip" id="titleA">
                            Diplomés
                        </h1>

                    </div>
                </div>
            </div>
            <BlackSectionX />
            <div className="infov-main-Dip">
                <h1 className="A1Dip" id="titleA">
                    <span class="bold-text">Diplomés </span>
                </h1>
                <div className="infov-Dip">
                    <div className="infov-Dip-Border">


                        <InfoVerticalePageX />
                        <InfoDip />
                    </div>
                </div>
            </div>


            <ModernFooter />

        </div>

    );
};

export default Registraire;