import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './EtudeFaza.scss';
import HeaderE from './HeaderE';
import BlackSectionE from './BlackSectionE';
import Infoetude from './Infoetude';
import ModernFooter from './ModernFooter';
import Boy from './Images/boy.png'; // Adjust the path accordingly
// import InfoVerticalePage from './InfoVerticalePage';

const EtudeFaza = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location


    return (
        <div>
            <HeaderE />

            <div className="Grey-sectionEtude">

                <div className="Grey-wrap-Etude">
                    <div className="Grey-center-Etude">
                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaEtude-wrap">
                                <h1 className="FazaEtude" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Etudarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="ApropoEtude-wrap">
                                <h1 className="AproposEtude" id="title3">
                                    Étudier à Faza
                                </h1>
                                <h2 className="EtudarowA" id="title32">{'>'}</h2>
                            </div>
                        </Link>
                        <h1 className="AEtude" id="titleA">
                            Tout sur les études
                        </h1>
                    </div>
                </div>
            </div>

            <BlackSectionE />

            <div className="infov-Etude">
                <div className="Pic-Etude">
                    <div className="Pic-Etude1">
                        <div className="Pic-Text">
                            <h1> L'école de l'excellence</h1>
                        </div>
                        <img src={Boy} alt="" className="boy-image" />
                    </div>
                </div>
                <Infoetude />

            </div>

            <ModernFooter />

        </div>

    );
};

export default EtudeFaza;