import React, { useEffect } from 'react';

import './Infodir.scss';

const Infodir = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (


        <div className="infov-Direc">
            <div className="info-Direc-center">
                {/* Rest of your content */}


                <div className="First-Dirsection">
                    <h1 className="Direct" id="title1">Direction et services</h1>
                    <h1 className="DirectI" id="title1" >Bienvenue au CSP Faza, l'institution éducative emblématique de Niamey depuis 2011. Découvrez une école dynamique, moderne et respectée, offrant une expérience éducative exceptionnelle.</h1>
                    <h1 className="DirectII" id="title1" > Au CSP Faza, notre engagement envers des valeurs éthiques guide notre approche éducative. Nous créons un environnement chaleureux, favorisant l'inclusion et l'épanouissement de chaque élève.</h1>

                </div>

                <div className="Dirsection-line"></div>
                <div className="First-Dirsection2">

                    <h1 className="Service" id="title2">Inscriptions</h1>
                    <h1 className="ServiceI" id="title2" >
                        Depuis nos débuts en 2011, le CSP Faza a tracé un chemin dynamique dans l'éducation, introduisant des programmes novateurs qui ont profondément impacté la communauté éducative à Niamey.
                        Distinguez-vous avec nous.</h1>

                    <h1 className="ServiceII" id="title2" >
                        Le CSP Faza se démarque par son approche pédagogique distinctive, des projets innovants axés sur des contributions sociales significatives, et une renommée locale bien méritée.
                        Modernité et Infrastructure propices à l'Apprentissage
                        Explorez nos installations modernes, équipées des dernières technologies éducatives.  </h1>

                    <h1 className="ServiceIII" id="title2" >
                        Au CSP Faza, nous créons un environnement stimulant pour inspirer et soutenir l'apprentissage de qualité. </h1>
                </div>

                <div className="Dirsection-line2"></div>
                <div className="First-Dirsection3">
                    <h1 className="DiserT" id="title3">Diplômes</h1>

                    <h1 className="DiserTI" id="title3" >
                        Rejoignez une communauté éducative passionnée et un corps enseignant dévoué. Au CSP Faza, nous cultivons une atmosphère inclusive, propice à des approches pédagogiques innovantes.  </h1>

                    <h1 className="DiserTII" id="title3" >
                        Célébrant plus d'une décennie de succès, le CSP Faza affiche un bilan impressionnant. Découvrez nos réalisations et nos ambitieuses perspectives d'avenir, témoignant de notre engagement envers une éducation de qualité.  </h1>

                </div>
            </div>
        </div>





    );

};


export default Infodir;