import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './Inscriptions.scss';
import HeaderA from './HeaderA';
import BlackSectionA from './BlackSectionA';
import InfoIns from './InfoInsPage/InfoIns';
import ModernFooter from './ModernFooter';
import InfoVerticalePageR from './InfoVerticalePageR';

const Inscription = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location


    return (
        <div>
            <HeaderA />
            {/* Rest of your content */}
            <div className="Grey-section-Ins">
                <div className="Grey-wrap-Ins">
                    <div className="Grey-center-Ins">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaIns-wrap">
                                <h1 className="Faza-Ins" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Insarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposIns-wrap">
                                <h1 className="AproposIns" id="titleA">
                                    Étudiants
                                </h1>

                                <h2 className="InsarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AIns" id="titleA">
                            Inscriptions
                        </h1>

                    </div>
                </div>
            </div>
            <BlackSectionA />


            {/* Rest of your content */}
            <div className="infov-main-Ins">
                <h1 className="A1Ins" id="titleA">
                    <span class="bold-text">Inscription</span>
                </h1>
                <div className="infov-Ins">

                    <div className="infov-Ins-Border">

                        <InfoVerticalePageR />
                        <InfoIns />
                    </div>
                </div>
            </div>

            <ModernFooter />


        </div>

    );
};

export default Inscription;