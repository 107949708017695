import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeaderInfo from './HeaderInfo';
import BlackSectionInfo from './BlackSectionInfo';
import './TravaillerPage.scss';
import InfoTrava from './TravaillerPage /InfoTrava';
import ModernFooter from './ModernFooter';

const TravaillerPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location

    return (
        <div>
            <HeaderInfo />
            <div className="Grey-section-Trav">
                <div className="Grey-wrapTrv">
                    <div className="Grey-centerTrv">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="Fazatrv-wrap">
                                <h1 className="Fazatrv" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>

                                <h2 className="Trarrow" id="title2">{'>'} </h2>
                            </div>
                        </Link>


                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="Apropostrv-wrap">

                                <h1 className="Apropotrv" id="titleA">
                                    À propos
                                </h1>

                                <h2 className="TrvarowA" id="titleA2">{'>'}  </h2>
                            </div>
                        </Link>



                        <h1 className="Trvirect" id="titleA">
                            Travailler à Faza
                        </h1>
                    </div>

                </div>
            </div >
            <BlackSectionInfo />


            <div className="infov-Trv">
                <div className="infov-Trv-center">
                    <InfoTrava />
                </div>

            </div>
            <ModernFooter />
        </div>
    );
};

export default TravaillerPage;