import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './Surveillant.scss';
import HeaderA from './HeaderA';
import BlackSectionA from './BlackSectionA';
import InfoSur from './InfoSurPage/InfoSur';
import ModernFooter from './ModernFooter';
import InfoVerticalePageR from './InfoVerticalePageR';

const Surv = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const location = useLocation(); // Get the current location


    return (
        <div>
            <HeaderA />
            {/* Rest of your content */}
            <div className="Grey-section-Sur">
                <div className="Grey-wrap-Sur">
                    <div className="Grey-center-Sur">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaSur-wrap">
                                <h1 className="Faza-Sur" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Surarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposSur-wrap">
                                <h1 className="AproposSur" id="titleA">
                                    Étudiants
                                </h1>

                                <h2 className="SurarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="ASur" id="titleA">
                            Surveillant
                        </h1>

                    </div>
                </div>
            </div>
            <BlackSectionA />
            <div className="infov-main-Sur">
                <h1 className="A1Sur" id="titleA">
                    <span class="bold-text">Bureau du surveillant</span>
                </h1>
                <div className="infov-Sur">
                    <div className="infov-Sur-Border">


                        <InfoVerticalePageR />
                        <InfoSur />
                    </div>
                </div>
            </div>
            <ModernFooter />

        </div>

    );
};

export default Surv;