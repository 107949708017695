import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './GreySectionSearch.scss';


const GreySectionSearch = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location


    return (
        <div>


            <div className="Grey-sectionSearch">

                <div className="Grey-wrap-Search">
                    <div className="Grey-center-Search">
                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaSearch-wrap">
                                <h1 className="FazaSearch" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Searcharow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="ApropoSearch-wrap">
                                <h1 className="AproposSearch" id="title3">
                                    CSP Faza
                                </h1>
                                <h2 className="SearcharowA" id="title32">{'>'}</h2>
                            </div>
                        </Link>
                        <h1 className="ASearch" id="titleA">
                            Recherche
                        </h1>
                    </div>
                </div>
            </div>




        </div>

    );
};

export default GreySectionSearch;