import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './MainPageActu.scss';
import HeaderActu from './HeaderActu';
import customImage1 from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Actualite/Mainactus/SiteActu.png';

import ModernFooter from './ModernFooter';

const InformationGeneralePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const location = useLocation(); // Get the current location


    return (
        <div>
            <HeaderActu />

            <div className="Grey-sectionTu">
                <div className="Grey-wrapTu">
                    <div className="Grey-centerTu">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaHead-wrapTu">

                                <h1 className="FazaTu" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>

                                <h2 className="FazarowTu" id="title2">{'>'}</h2>
                            </div>
                        </Link>


                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposHead-wrapTu">
                                <h1 className="AproposInfoTu" id="titleA">
                                    Actualités
                                </h1>

                                <h2 className="FazarowATu" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="ASommaireTu" id="titleA">
                            Actualités
                        </h1>
                    </div>
                </div>
            </div>


            <div className="infov-GeninTu">
                <h1 className="A1GenTu" id="titleA">
                    <span class="bold-text">ACTUALITÉS</span> FAZA
                </h1>
                <h1 className="Tile-Actu" id="titleA"> La mise en ligne officiel du site web de Faza  </h1>
                <div className="main-actu">

                    <Link to="\" className="main-actu2">
                        <img src={customImage1} alt="" className="main-img" />
                    </Link>
                    <div className="actu-description">
                        <Link to="\" className="actu-description">
                            <h1 className="desc1" id="titleA"> Un site Web modern à l'image de Faza  </h1>
                            <h1 className="desc2" id="titleA"> Le site web de l'école offre une plateforme interactive et informative pour les parents, les élèves et le personnel. Il met en avant les avantages et les opportunités offertes par l'établissement, tels que les programmes d'études innovants, les activités parascolaires variées et les services de soutien aux élèves.  </h1>

                        </Link>
                    </div>

                </div>
            </div>

            <ModernFooter />


        </div>

    );

};


export default InformationGeneralePage;