import React from 'react';
import { Link } from 'react-router-dom';

import './NewsSection.scss'; // Create a corresponding SCSS file
import customImage1 from './Images/Site.png';
import customImage2 from './Images/4k_chemerty.png';
import customImage3 from './Images/techer.png';
import customImage4 from './Images/alumi.png';
import customImage5 from './Images/nigr.png';
import Thebotton from './Symbols/dbutton.png';
import Thebotton2 from './Symbols/dbutton.png';
import Goldenarrow3 from './Symbols/golden arrow.png';






const NewSection = () => {

    document.querySelectorAll('.custom-img, .custom-title h1').forEach(element => {
        element.addEventListener('mouseenter', () => {
            const img = element.closest('.gallery-item').querySelector('.custom-img');
            img.classList.add('hover-effect');
        });

        element.addEventListener('mouseleave', () => {
            const img = element.closest('.gallery-item').querySelector('.custom-img');
            img.classList.remove('hover-effect');
        });
    });



    let data = [
        { id: 1, imgSrc: customImage1, title: "Nouveau site web de Faza", url: "/MainPage-Actu", date: "3 Février 2023" },
        { id: 2, imgSrc: customImage2, title: "Se préparer au changement climatique", url: "/fete-scolaire-2023", date: "3 Février 2023" },
        { id: 3, imgSrc: customImage3, title: "La réunion des professeurs", url: "/fete-scolaire-2023", date: "3 Février 2023" },
        { id: 4, imgSrc: customImage4, title: "Remise de diplomes CSP Faza 2025", url: "/fete-scolaire-2023", date: "3 Février 2023" },
        { id: 5, imgSrc: customImage5, title: "La journée culturelle 2025", url: "/fete-scolaire-2023", date: "3 Février 2023" },
    ];


    return (

        <>

            <div className="news-section">
                <div className="Admission-sticker">
                    <Link to="InformationET" className="main-sticker">
                        <h1>ADMISSION </h1>
                    </Link>

                </div>
                {/* Actualités */}

                {/* Actualités - Image top left */}

                <div className="News-wrap">

                    <div className="padding-wrap">
                        <div className="Actu-wrap">


                            <div className="Actualités">
                                <Link to="/MainPage-Actu">

                                    {/* Actualités section */}

                                    <h3>Actualités
                                        <img src={Thebotton} alt="" className="The-botton" />
                                    </h3>
                                </Link>

                                <div className="separator-line9"></div>
                            </div>



                            <div className="Interventionm-line9">



                                <Link to=" " className="Intervention-médiatique">
                                    <h2>Interventions médiatiques <img src={Goldenarrow3} alt="" className="G-arrow2" /></h2>
                                </Link>
                            </div>
                        </div>
                        <div className="Image-container">

                            <div className="Image-gallery">
                                {data.map((item) => (
                                    <div className="gallery-item" key={item.id}>

                                        <Link to={item.url} className="custom-img">
                                            <img src={item.imgSrc} alt={item.title} />
                                        </Link>
                                        {/* title-date-container to be able to move and edit them together*/}
                                        <div className="title-date-container">
                                            <Link to={item.url} className="custom-title">
                                                <h1>{item.title}</h1>
                                            </Link>
                                            {/* date-line-container to be able to move and edit them together*/}
                                            <div className="date-line-container">
                                                <span className="date-line"></span>
                                                <h2>{item.date}</h2>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>



                        </div>



                        <div className="separator-line"></div>
                        {/* Intervention-médiatique small screen*/}


                        <div className="Intervention-wrap">

                            <Link to=" " className="Intervention-médiatique2">
                                <h2>Interventions médiatiques <img src={Goldenarrow3} alt="" className="G-arrow2" /></h2>
                            </Link>
                        </div>


                        <div className="separator-line-Inter"></div>

                    </div>

                    {/* Actualités - Additional Image */}
                    <div className="Evenement-section">

                        {/* Actualités section */}
                        <div className="Événements">
                            <h3>Événements
                                <img src={Thebotton2} alt="" className="The-botton2" />
                            </h3>

                        </div>


                        {/* Main evenement img and date*/}
                        <div className="custom-image5">

                            <a href="https://example.com">


                                <img src={customImage5} alt="<" />

                            </a>
                        </div>

                        <div className="Eve-date-main">
                            <h1>6 et 7 décembre</h1>
                            <Link to="/MainPage-Actu " className="Eve-date-link">
                                <h2> Journée culturelle de Faza </h2>
                            </Link>

                            <div className="separator-lineE"></div>
                        </div>
                        <div className="Evenement-dates">
                            {/* dat1 2 Section */}



                            <div className="Eve-date1">

                                <h1>9 Avril 2024</h1>

                                <Link to="/MainPage-Actu" className="Eve-date1-link">
                                    <h2>Aïd el-Fitr</h2>
                                </Link>
                            </div>

                            {/* Separation line under Evenement date1 */}
                            <div className="separator-line2"></div>
                            {/* dat2 2 Section */}

                            <div className="Eve-date2">

                                <h1>3 Aout</h1>

                                <Link to="/MainPage-Actu " className="Eve-date2-link">
                                    <h2>Fête de l'indépendance du Niger</h2>
                                </Link>


                            </div>



                            {/* Separation line under Evenement date2 */}
                            <div className="separator-line3"></div>

                            {/* date 3 */}
                            {/* Additional Images 
                            <div className="Eve-date3">

                                <h1>15 Septembre</h1>

                                <Link to=" " className="Eve-date3-link">
                                    <h2>Fête de la girafe</h2>
                                </Link>
                            </div>
      */}
                        </div>


                    </div>



                    {/* End of Additional Titles and Images */}



                </div>
            </div >

        </>
    );
};

export default NewSection;