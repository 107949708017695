// MajorMinorPoints.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './ModernFooter.scss';
import Access from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/access.png'; // Update the path accordingly



const MajorMinorPoints = () => {

    const navigate = useNavigate();

    const goToMainPage = () => {
        navigate('/'); // Navigate to the main page
        window.scrollTo(0, 0); // Scroll to the top of the page
    };






    return (
        <div className="major-pointsI" id="major-pointsI" >
            <div className="major-points-wrapperI">



                <div className="separator-line-bottom1I"></div>
                <div className="Bottom-WrapI">
                    <div className="Bottom-Wrap-centerI">

                        <div className="Faza-joindreI">

                            <Link to="/" onClick={goToMainPage} className="Faza-wrapI">
                                <h1>Complexe Scolaire Privé Faza</h1>
                            </Link>

                            <div className="separator-lineFI"></div>

                            <Link to="/Noos-coo" className="Joindre-TelI">

                                <h2>Nous joindre</h2>

                            </Link>

                        </div>

                        <div className="separator-line-bottom2I"></div>
                        <div className="TémoinsI">
                            <div className="Témoins-wrapI">
                                <h1>Préférence des témoins</h1>
                            </div>
                            <div className="separator-line-TemoinI"></div>

                        </div>

                        <div className="major-points-top-right-TelI">

                            <Link to="/Cons-fidd" className="confidentialité-TelI">

                                <h2>Confidentialité </h2>

                            </Link>
                            {/* Separation line under Journée culturelle de Faza */}
                            <div className="separator-lineTI"></div>

                            {/* Accessibilité bas de page */}

                            <Link to="/Akks-bil" className="Access-ibilitéTelI">



                                <h3>Accessibilité Web <img className="Access-logoIG" src={Access} alt="" /></h3>


                            </Link>
                            {/* Add golden arrow demande d'ad... */}

                        </div >
                    </div >
                </div >
            </div >
        </div >
    );
};

export default MajorMinorPoints;