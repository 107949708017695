import React, { useState, useEffect } from 'react';

import './InfoTrait.scss';

const InfoTrait = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesTR(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesTR, setDropdownStatesTR] = useState({
        TraiTA0: false,
        TraiTA: false,
        TraiTA2: false,



    });


    return (


        <div className="Trait-Piece">
            <div className="Etude-Piece-center">
                {/* Rest of your content */}


                <div className="First-Piece">
                    <div className="main-Piece">
                        <h1 className="Traitm" id="title1">Traitement de demandes</h1>

                        <div className="Piece-line"></div>
                    </div>
                    <div className="First-Piece-wrap">



                        <div className="Etapes-Piece2">


                            <h2 className="TritI" id="title2" >
                                Vous trouverez dans cette page les informations concernant l'étude des dossiers d'admission.</h2>


                        </div>

                        <div className="Drop-Trait-wrap">



                            <div to="/Pieces-identité0" >
                                <div className={`TraiT0  ${dropdownStatesTR.TraiTA0 ? 'dropdownX-open' : 'dropdownX-closed'}`}>
                                    <div className="ATraiWrap " onClick={(e) => handleInformationAClick('TraiTA0', e)} >
                                        <h1 className="TraiTA0" >Temps de traitement des demandes d'admission</h1>
                                        <span className={`signTR ${dropdownStatesTR.TraiTA0 ? 'minus-signTR' : 'plus-signTR'}`} >
                                            {dropdownStatesTR.TraiTA0 ? '_' : '+'}
                                        </span>

                                    </div>

                                    <div className={`dropdown-contentT1 ${dropdownStatesTR.TraiTA0 ? 'active' : ''}`}>

                                        <div className="traitT0" >
                                            <h1 className="trait-result0" id="title1" >
                                                Temps de traitement des demandes d'admission sont d'une semaine.</h1>
                                            <h2 className="trait-result01" id="title1" >
                                                Informations complémentaires pour tous les niveaux.</h2>
                                            <h3 className="trait-result02" id="title1" >
                                                Présenter un dossier incomplet entraîne un retard dans le processus d’admission. Le Service de l’admission vous réclamera par une lettre de décision les documents manquants et vous indiquera le délai dans lequel vous êtes tenu à les fournir. Suite à la réception des documents, le dossier sera réétudié.</h3>
                                            <h4 className="trait-result03" id="title1" >
                                                Les choix de programmes sont traités en parallèle et les décisions sont émises en fonction de l’ordre de traitement de la demande d’admission et non en fonction de l’ordre des choix de programmes (une réponse relative à un deuxième choix expédiée avant celle relative à un premier choix ne signifie pas un refus au premier choix).</h4>

                                        </div>



                                    </div>
                                </div>

                            </div>

                            <div className="Pieces-identitésT" >
                                <div className={`TraiT ${dropdownStatesTR.TraiTA ? 'dropdownX-open' : 'dropdownX-closed'}`}>
                                    <div className="ATraiWrap" onClick={(e) => handleInformationAClick('TraiTA', e)} >
                                        <h1 className="TraiTA" >Décisions d'admission possibles</h1>

                                        <span className={`signTR ${dropdownStatesTR.TraiTA ? 'minus-signTR' : 'plus-signTR'}`} >
                                            {dropdownStatesTR.TraiTA ? '_' : '+'}
                                        </span>

                                    </div>

                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentT1 ${dropdownStatesTR.TraiTA ? 'active' : ''}`}>

                                        <div className="identité-Trait">


                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th><h1>Admission définitive</h1></th>
                                                        <th><h2>Le Registrariat vous fait parvenir une offre définitive dès qu’il a la preuve que vous remplissez les conditions d’admission.</h2></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> <h1>Admission conditionnelle</h1></td>
                                                        <td>

                                                            <h2>Le Registrariat vous fait une offre conditionnelle s’il n’a pas la preuve que vous remplissez toutes les conditions exigées. Vous pourrez quand même procéder à votre inscription. Toutefois, vous devrez satisfaire à une ou à plusieurs exigences dans les délais prescrits.</h2>

                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td> <h1>Refus</h1></td>
                                                        <td>
                                                            <h2>Le Registrariat vous fera connaître la ou les raison(s) du refus.</h2>

                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>



                                    </div>
                                </div>

                            </div>


                            <div to="/Pieces-identitéT2" >
                                <div className={`TraiT2  ${dropdownStatesTR.TraiTA2 ? 'dropdownX-open' : 'dropdownX-closed'}`}>
                                    <div className="ATraiWrap" onClick={(e) => handleInformationAClick('TraiTA2', e)} >
                                        <h1 className="TraiTA2" >Traitement des lettres de décision</h1>

                                        <span className={`signTR ${dropdownStatesTR.TraiTA2 ? 'minus-signTR' : 'plus-signTR'}`} >
                                            {dropdownStatesTR.TraiTA2 ? '_' : '+'}
                                        </span>

                                    </div>

                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentT1 ${dropdownStatesTR.TraiTA2 ? 'active' : ''}`}>

                                        <div className="traitT" >
                                            <h1 className="trait-result" id="title1" >
                                                Les candidats recevront un courriel à leur adresse courriel personnelle. Ils pourront prendre connaissance de la décision rendue.</h1>




                                        </div>



                                    </div>
                                </div>

                            </div>

                        </div>




                    </div>



                </div>



            </div>
        </div>





    );

};


export default InfoTrait;