import React, { useEffect, useState } from 'react';

import axios from 'axios';
import './InfoJoinA.scss';
import logoImage from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Pages/Info Générale/Symbols/Call.png'; // Update with the correct path to your image


const InfoJoin = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const initialFormData = {
        nom: '',
        prénom: '',
        email: '',
        Raison: '',
        message: ''
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const [formData, setFormData] = useState(initialFormData);
    const [isSubmitted, setIsSubmitted] = useState(false); // New state to track form submission

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5003' : 'https://cspfaza.com/Noos-coo';

            const response = await axios.post(`${baseUrl}/send`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });



            console.log(response.data);
            setFormData(initialFormData); // Reset the form data to initial values
            setIsSubmitted(true); // Update submission status
        } catch (error) {
            console.error('Error sending message:', error);
            // Handle error case here
        }
    };

    return (


        <div className="Etude-Join">
            <div className="Etude-Join-center">
                {/* Rest of your content */}
                <h1 className="J1dre" id="title1">Nous Joindre</h1>

                <div className="First-Join">

                    <div className="rectangles-containerJ">
                        <div className="rectangleJ left-rectangleJ">
                            <div className="text-inline">
                                <div className="text-1">
                                    <p>Téléphone</p> {/* Update text as needed */}
                                </div>
                                <div className="text-2">
                                    <img src={logoImage} alt="Logo" className="logo-image" />
                                    <p>227 80 34 89 95</p> {/* Update text as needed */}

                                </div>
                            </div>
                        </div>

                        <div className="rectangleJ right-rectangleJ">
                            <div className="text-inline2">
                                <div className="text-3">
                                    <p>URGENCE</p> {/* Update text as needed */}
                                </div>
                                <div className="text-4">
                                    <p>227 80 34 89 95</p> {/* Update text as needed */}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <div className="Contactez-nous">
                    <p>Contactez-nous à l'aide du formulaire ci-dessous</p> {/* Update text as needed */}
                </div>
                <div className="my-form">
                    {!isSubmitted ? (
                        <div className="my-form">

                        </div>
                    ) : (
                        <div className="success-message">
                            <p>Votre message a été envoyé avec succès!</p>
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="nom"
                            value={formData.nom}
                            onChange={handleChange}
                            placeholder="Nom"
                        />
                        <input
                            type="text2"
                            name="prénom"
                            value={formData.prénom}
                            onChange={handleChange}
                            placeholder="Prénom"
                        />
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Votre Email"
                        />
                        <input
                            type="text"
                            name="Raison"
                            value={formData.Raison}
                            onChange={handleChange}
                            placeholder="Raison"
                        />
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Votre Message"

                        />
                        <button type="submit">Envoyer</button>
                    </form>
                </div>

                <div className="Join-line"></div>
                <div className="First-Join2">
                    <h1 className="Kontakt" id="title2">Nos heures d'ouverture</h1>
                    <h1 className="KontaktI" id="title2" >
                        Lundi au vendredi : 7 h 30 à 17 h 00.</h1>

                    <h1 className="KontaktII" id="title2" >
                        Samedi : 8 h 00 à 17 h 00  </h1>

                    <h1 className="KontaktIII" id="title2" >
                        Dimanche : Fermé . </h1>
                </div>
            </div>
        </div>

    );

};


export default InfoJoin;