import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BlackSectionJ.scss';
import newarrow2 from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/golden arrow.png';

const BlackSectionJ = () => {



    ///Delay on the Navigation

    const [isMenuOpenE, setIsMenuOpenE] = useState(false);
    const handleDelayedNavigationE = (url, delay) => {
        // Start the closing animation
        const gridContainer3 = document.querySelector('.grid-container3');
        gridContainer3.classList.add('closing');

        setTimeout(() => {
            setIsMenuOpenE(false);
            gridContainer3.classList.remove('closing');

            // After the grid slides up, navigate to the new URL
            setTimeout(() => {
                window.location.href = url;
            }, delay); // Delay for the navigation

        }, 200); // Duration of the slide-up animation
    };
    ///Delay on the Navigation /End

    ///Grid

    const blackSectionERef = useRef(null);
    const gridERef = useRef(null);
    const searchERef = useRef(null);

    // Handler to toggle the grid from the menu icon and black section
    const toggleGrid3 = (event) => {
        if
            (!searchERef.current.contains(event.target)) {
            setIsMenuOpenE(!isMenuOpenE);
        }
    };


    const handleClickOutside = (event) => {
        if (blackSectionERef.current && !blackSectionERef.current.contains(event.target) &&
            gridERef.current && !gridERef.current.contains(event.target) &&
            !(searchERef.current && searchERef.current.contains(event.target))) {
            setIsMenuOpenE(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // ... existing code

    // Close the grid when clicking on the empty surface




    const location = useLocation();
    const [selectedRectangle, setSelectedRectangle] = useState(null);

    useEffect(() => {
        // Map the path to a key that represents each title
        const rectangleIndex = {
            '/No0s-coo': 1,
            '/dHeur-douv': 1,
            '/Bot-tin': 2,
            //Maintitles Joindre
            '/nos-cor2': 1,
            '/Nos-h2': 1,
            '/Bo-tti2': 2,
        };

        // Set the selected path based on the current path
        setSelectedRectangle(rectangleIndex[location.pathname] || 1);
    }, [location]);


    ///Search Engine
    const [searchTermE, setSearchTermE] = useState('');

    const handleSearchChangeE = (event) => {
        setSearchTermE(event.target.value);
    };

    const handleSearchSubmitE = () => {
        window.location.href = `/search?query=${encodeURIComponent(searchTermE)}`;
    };
    ///Search Engine /End


    // New function to handle key press
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmitE();
        }
    };
    return (
        <div className="black-sectionJ" ref={blackSectionERef} onClick={toggleGrid3}>

            <div className="mobile-menu-icon3" >
                {/* Hamburger Icon */}
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div className="CspFaza3">
                <h1>Nous joindre</h1>
            </div>


            <div className={`grid-container3 ${isMenuOpenE ? 'open' : ''}`} ref={gridERef}>


                <div className="header-wrapEtu" >
                    <div className="headerEtu">
                        <div className="search-barEtu" >
                            <input
                                type="text"
                                placeholder="Chercher..."
                                value={searchTermE}
                                onChange={handleSearchChangeE}
                                ref={searchERef}
                                onKeyPress={handleKeyPress}
                            />
                            <i className="fa fa-search search-iconEtud" onClick={handleSearchSubmitE}></i>
                        </div>
                    </div>

                    <div className="Title-wrapperJtu" >

                        <Link to="/Noos-coo" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigationE('/Noos-coo', 200); // Adjust the delay as needed
                        }}>
                            <div>
                                <div className={`NosJ ${selectedRectangle === 1 ? 'selected' : ''}`}>
                                    <h1 className="info-title">Nos coordonnés</h1>
                                </div>
                            </div>
                        </Link>
                        <div className="title-lineJ"></div>

                        {/* Add similar Link elements for other menu items */}

                        <Link to="/Bot-tin" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigationE('/Bot-tin', 200); // Adjust the delay as needed
                        }}>
                            <div>
                                <div className={`Botti ${selectedRectangle === 2 ? 'selected' : ''}`}>
                                    <h1 className="info-title">Bottin</h1>
                                </div>
                            </div>
                        </Link>




                    </div>
                    <img src={newarrow2} alt="Golden Arrow" className="D-arrow2" onClick={toggleGrid3} />
                </div>
            </div>


        </div>
    );
};

export default BlackSectionJ;