import React, { useEffect } from 'react';

import './InfoChiff.scss';

// CircleGraph component (Placeholder - Implement as per the requirements)
const CircleGraph = () => {
    // Graph implementation here
    return (
        <div className="circle-graph">
            <div className="circle-segment segment1"></div>
            <div className="circle-segment segment2"></div>
            <div className="circle-segment segment3"></div>
            {/* Add points and texts for each segment */}
            {/* ... */}
        </div>
    );
};
const InfoChiff = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);




    return (


        <div className="infov-Chiff">
            <div className="infov-Chiff-center">
                {/* Rest of your content */}


                <div className="First-Chiff">
                    <h1 className="Faits" id="title1">Faza en chiffres</h1>

                    <div className="Chiff-line"></div>
                </div>


                <div className="First-Chiff2">

                    <div className="graph-section">

                        <div className="texts-and-graph">

                            <div className="text-blocks">
                                <p className="text-part" id="text-part1">1 800</p>
                                <p className="text-part" id="text-part2">Élèves</p>
                                <p className="text-part" id="text-part3">(Avril 2023)</p>
                                <div className="Chiff-line2"></div>
                            </div>

                            <div className="grap-">
                                <p className="graph-text" id="text-part1">50%</p>
                                <p className="graph-text" id="text-part2">35%</p>
                                <p className="graph-text" id="text-part3">15%</p>

                            </div>
                            <CircleGraph />
                        </div>
                    </div>
                    <div className="Chiff-line3"></div>

                    <div className="text-blocks2">
                        <p className="text-part2" id="text-part1">3000</p>
                        <p className="text-part2" id="text-part2">diplômes décernés</p>
                        <p className="text-part2" id="text-part3">(Avril 2023)</p>
                        <div className="Chiff-line4"></div>
                    </div>



                    <div className="text-blocks3">
                        <p className="text-part3" id="text-part1">100</p>
                        <p className="text-part3" id="text-part2">Ressources humaines</p>
                        <p className="text-part3" id="text-part3">(Avril 2023)</p>
                        <div className="Chiff-line5"></div>
                    </div>
                </div>


            </div>



        </div>





    );

};


export default InfoChiff;



