import React, { useEffect } from 'react';

import './Infoplaintes.scss';

const Infoplaintes = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);




    return (


        <div className="Etude-Pl">
            <div className="Etude-Pl-center">
                {/* Rest of your content */}


                <div className="First-Pl">
                    <h1 className="DemanPl" id="title1">Plaintes et divulgations</h1>

                    <div className="Pl-line"></div>
                    <div className="First-Pl-wrap">

                        <div className="Etapes-Pl">
                            <h1 className="DemanPl" id="title1" >Plaintes </h1>

                        </div>

                    </div>
                    <div className="First-Pl2">

                        <h1 className="PlIs" id="title2">Procédure</h1>
                        <h1 className="PlIsI" id="title2" >
                            <li> Il appartient à l’élève ou au groupe d’l’élèves de franchir la première étape qui est de discuter avec l’enseignante ou l’enseignant concerné. Si cette étape n’est pas respectée, la plainte pourrait s’avérer irrecevable.
                            </li>

                            <li>
                                En cas de besoin, l’étudiante, l’étudiant ou le groupe d’étudiants peut s’adresser à la surveillance.
                            </li>

                            <li>
                                Si l’étudiante, l’étudiant ou le groupe d’étudiants n’est toujours pas satisfait du traitement de sa plainte, il peut se référer à la direction .
                            </li>

                        </h1>


                    </div>
                    <div className="Pl-line2"></div>

                    <div className="First-PlF">
                        <div className="Etapes-Pl2">
                            <h1 className="PlFI2" id="title1" >Confidentialité </h1>

                        </div>

                        <h1 className="PlIs3" id="title2">Le traitement d’une plainte demeure un processus confidentiel.</h1>

                        <h1 className="PlIs3" id="title2">Les personnes qui doivent être rencontrées dans le cadre du traitement de la plainte sont tenues de respecter la confidentialité des informations qui leur seront transmises durant le processus.</h1>

                        <h1 className="PlIs3" id="title2">De plus, les personnes impliquées dans le traitement et le suivi d’une plainte limiteront toute divulgation d’information personnelle ou nominative au minimum requis pour un traitement juste et équitable de la plainte par toutes les parties concernées.</h1>
                    </div>

                    <div className="Pl-line3"></div>


                </div>



            </div>
        </div>





    );

};


export default Infoplaintes;