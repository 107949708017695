import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './Plaintes.scss';
import HeaderA from './HeaderA';
import BlackSectionA from './BlackSectionA';
import Infoplaintes from './PlaintesPage/Infoplaintes';
import ModernFooter from './ModernFooter';
import InfoVerticalePageA from './InfoVerticalePageA';

const Plaintes = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location


    return (
        <div>
            <HeaderA />

            <div className="Grey-section-Pl">

                <div className="Grey-wrap-Pl">
                    <div className="Grey-center-Pl">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaPl-wrap">
                                <h1 className="Faza-Pl" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Plarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposPl-wrap">
                                <h1 className="AproposPl" id="titleA">
                                    Activités
                                </h1>

                                <h2 className="PlarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AAPl" id="titleA">
                            Plaintes et divulgations
                        </h1>

                    </div>
                </div>

            </div>
            <BlackSectionA />

            <div className="infov-main">
                <h1 className="A1dmin" id="titleA">
                    <span class="bold-text">Plaintes et divulgations</span>
                </h1>

                <div className="infov-Frias">
                    <div className="infov-Border">

                        <InfoVerticalePageA />

                        <Infoplaintes />
                    </div>
                </div>
            </div>
            <ModernFooter />

        </div>

    );
};

export default Plaintes;
