import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './BlackSection.scss';
// Import info from react-router-do\
//icons

import facebookIcon2 from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/Facebook white.png';
import xIcon2 from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/X White.png';
import instaIcon2 from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/Insta White.png';
import linkedInIcon2 from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/Linkdne White.png';
import newarrow from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/golden arrow.png';



const BlackSection = () => {




    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dropdownStates, setDropdownStates] = useState({
        Apropos: false,
        Etudefaza: false,
        Activités1: false,
        joindre: false,
        Étudiants: false,
        Publics: false,
        // ... other dropdowns if necessary
    });



    const toggleMenu = () => {
        if (isMenuOpen) {
            const gridContainer = document.querySelector('.grid-container');
            gridContainer.classList.add('closing');

            setTimeout(() => {
                setIsMenuOpen(false);
                gridContainer.classList.remove('closing');
            }, 200); // This duration should match the slideUp animation
        } else {
            setIsMenuOpen(true);
        }

        setDropdownStates({
            Apropos: false,
            Etudefaza: false,
            Activités1: false,
            joindre: false,
            Étudiants: false,
            Publics: false,
            // ... reset other dropdowns if necessary
        });
        setIsAnyDropdownOpen(false);


    };


    ///close grid when click outside
    const blackSection1Ref = useRef(null);
    const grid1Ref = useRef(null);
    const title1Ref = useRef(null);
    const title11Ref = useRef(null);
    const title12Ref = useRef(null);
    const title13Ref = useRef(null);
    const title14Ref = useRef(null);
    const title15Ref = useRef(null);
    const search1Ref = useRef(null);

    // Handler to toggle the grid from the menu icon and black section
    const toggleGrid1 = (event) => {
        if (!title1Ref.current.contains(event.target) &&
            !title11Ref.current.contains(event.target) &&
            !title12Ref.current.contains(event.target) &&
            !title13Ref.current.contains(event.target) &&
            !title14Ref.current.contains(event.target) &&
            !title15Ref.current.contains(event.target) &&
            !search1Ref.current.contains(event.target)) {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    // ... existing code

    // Close the grid when clicking on the empty surface
    // Handler for closing the grid when clicking outside
    const handleClickOutside = (event) => {
        if (blackSection1Ref.current && !blackSection1Ref.current.contains(event.target) &&
            grid1Ref.current && !grid1Ref.current.contains(event.target) &&
            !(title1Ref.current && title1Ref.current.contains(event.target)) &&
            !(title11Ref.current && title1Ref.current.contains(event.target)) &&
            !(title12Ref.current && title1Ref.current.contains(event.target)) &&
            !(title13Ref.current && title1Ref.current.contains(event.target)) &&
            !(title14Ref.current && title1Ref.current.contains(event.target)) &&
            !(title15Ref.current && title1Ref.current.contains(event.target)) &&
            !(search1Ref.current && search1Ref.current.contains(event.target))) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //titles dropdown content

    // New state to track if any dropdown is open
    const [isAnyDropdownOpen, setIsAnyDropdownOpen] = useState(true);
    const toggleDropdown = (dropdownKey) => {
        if (window.innerWidth <= 991) { // Replace 568 with your actual media query breakpoint
            const isDropdownOpen = !dropdownStates[dropdownKey];
            setDropdownStates(prevStates => ({
                ...prevStates,
                [dropdownKey]: isDropdownOpen
            }));

            // Update isAnyDropdownOpen state
            setIsAnyDropdownOpen(Object.values(dropdownStates).some(state => state));
        }

    };
    // useEffect to update isAnyDropdownOpen when dropdownStates changes
    useEffect(() => {
        setIsAnyDropdownOpen(Object.values(dropdownStates).some(state => state));
    }, [dropdownStates]);

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = () => {
        window.location.href = `/search?query=${encodeURIComponent(searchTerm)}`;
    };
    // New function to handle key press
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };


    return (
        <div className="black-section" ref={blackSection1Ref} onClick={toggleGrid1}>
            <div className="mobile-menu-icon" >
                {/* Hamburger Icon */}
                <span></span>
                <span></span>
                <span></span>
            </div>
            {/* Apropos Section */}
            <div className="CspFaza">
                <h1>Complexe Scolaire Privé Faza</h1>
            </div>
            <div className={`grid-container ${isMenuOpen ? 'open' : ''}`} ref={grid1Ref}>



                <div className="header-wrap">
                    <div className="header2">
                        <div className="search-bar2">
                            <input
                                type="text"
                                placeholder="Chercher..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                ref={search1Ref}
                                onKeyPress={handleKeyPress}
                            />
                            <i className="fa fa-search search-icon2" onClick={handleSearchSubmit}></i>
                        </div>
                    </div>

                    <div className="Title-wrapper">

                        <div className={`Apropos-area ${isAnyDropdownOpen ? '' : 'inactive-titleA'}`} ref={title1Ref}>

                            <div className={`Apropos ${isAnyDropdownOpen ? 'active-title' : 'inactive-title'}`}
                                onClick={() => toggleDropdown('Apropos')}>
                                <h1>  À propos   </h1>
                                <span className={`sign ${dropdownStates.Apropos ? 'minus-sign' : 'plus-sign'}`}>
                                    {dropdownStates.Apropos ? '_' : '+'}
                                </span>


                            </div>

                            <div className="tooltip" id="tooltip1">

                                {/* Content for À propos */}
                                <div className="wrap-center">
                                    <div className="tooltip-wrap">

                                        <div className="tool-title">

                                            <p>À propos</p>
                                        </div>
                                        <div className="wrap-list">

                                            <ul className="first-list">
                                                <Link to="/information-generale" className="tooltip-link" >
                                                    <li>

                                                        Information générale

                                                    </li>
                                                </Link>



                                                <Link to="/ecoles-faza" className="tooltip-link">
                                                    <li>
                                                        Les Écoles Faza
                                                    </li>
                                                </Link>




                                                <Link to="/travailler-faza" className="tooltip-link">
                                                    <li>
                                                        Travailler à Faza
                                                    </li>
                                                </Link>






                                            </ul>

                                            <ul className="second-list">


                                                <Link to="/direction-services" className="tooltip-link" >
                                                    <li>
                                                        Direction et service
                                                    </li>
                                                </Link>




                                                <Link to="/histoire-faza" className="tooltip-link" >
                                                    <li>
                                                        Histoire de Faza


                                                    </li>
                                                </Link>




                                                <Link to="/Fait-Chiff" className="tooltip-link">
                                                    <li>  Faits et chiffres
                                                    </li>
                                                </Link>


                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="title-line"></div>

                            <div className={`dropdown-content ${dropdownStates.Apropos ? 'active' : ''}`}>
                                {/* Content for Apropos */}



                                {/* Dropdown content for À propos */}
                                <ul className="first-list">

                                    <Link to="/information-generale" className="tooltip-link">
                                        <li>  Information générale
                                        </li>
                                    </Link>




                                    <Link to="/ecoles-faza" className="tooltip-link">
                                        <li>   Les Écoles Faza
                                        </li>
                                    </Link>




                                    <Link to="/travailler-faza" className="tooltip-link">
                                        <li>
                                            Travailler à Faza
                                        </li>
                                    </Link>


                                </ul>
                                <ul className="second-list">

                                    <Link to="/direction-services" className="tooltip-link" >
                                        <li>   Direction et service
                                        </li>
                                    </Link>




                                    <Link to="/histoire-faza" className="tooltip-link" >
                                        <li>
                                            Histoire de Faza
                                        </li>
                                    </Link>




                                    <Link to="/Fait-Chiff" className="tooltip-link">
                                        <li>
                                            Faits et chiffres
                                        </li>
                                    </Link>


                                </ul>

                            </div>
                        </div>




                        <div className={`Apropos-area2 ${isAnyDropdownOpen ? '' : 'inactive-titleA'}`} ref={title11Ref}>
                            <div className={`Etudefaza ${isAnyDropdownOpen ? 'active-title' : 'inactive-title'}`}
                                onClick={() => toggleDropdown('Etudefaza')}>
                                <h1>  Étudier à Faza     </h1>
                                <span className={`sign ${dropdownStates.Etudefaza ? 'minus-sign' : 'plus-sign'}`}>
                                    {dropdownStates.Etudefaza ? '_' : '+'}
                                </span>
                            </div>

                            <div className="tooltip2" id="tooltip2">
                                <div className="wrap-center2">
                                    <div className="tooltip-wrap2">

                                        <div className="tool-title2">
                                            <p>Étude à Faza</p>
                                        </div>

                                        <div className="wrap-list2">
                                            <ul className="first-list2">

                                                <Link to="/etude-faza" className="tooltip2-link2">
                                                    <li>
                                                        Tout sur les études à Faza
                                                    </li>
                                                </Link>




                                                <Link to="/demande-admin" className="tooltip2-link2">
                                                    <li>
                                                        Demande d'admission
                                                    </li>
                                                </Link>




                                                <Link to="/couts-etude" className="tooltip2-link2">
                                                    <li>    Coûts des études     </li>
                                                </Link>





                                            </ul>

                                            <ul className="second-list2">


                                                <Link to="/faza-sonuci" className="tooltip2-link2">
                                                    <li>  Faza Sonuci      </li>
                                                </Link>




                                                <Link to="/faza-yantala" className="tooltip2-link2">
                                                    <li>  Faza Yantala   </li>
                                                </Link>



                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="title-line2"></div>

                            <div className={`dropdown-content ${dropdownStates.Etudefaza ? 'active' : ''}`}>
                                {/* Dropdown content for À propos */}

                                <ul className="first-list2">
                                    <li>
                                        <Link to="/etude-faza" className="tooltip2-link2">
                                            Tout sur les études à Faza
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/demande-admin" className="tooltip2-link2">
                                            Demande d'admission
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/couts-etude" className="tooltip2-link2">
                                            Coûts des études
                                        </Link>

                                    </li>



                                </ul>

                                <ul className="second-list2">

                                    <li>
                                        <Link to="/faza-sonuci" className="tooltip2-link2">
                                            Faza Sonuci
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/faza-yantala" className="tooltip2-link2">
                                            Faza Yantala
                                        </Link>

                                    </li>

                                </ul>
                            </div>
                        </div>




                        <div className={`Apropos-area3 ${isAnyDropdownOpen ? '' : 'inactive-titleA'}`} ref={title12Ref}>
                            <div className={`Activités1 ${isAnyDropdownOpen ? 'active-title' : 'inactive-title'}`}
                                onClick={() => toggleDropdown('Activités1')}>
                                <h1> Activités  </h1>
                                <span className={`sign ${dropdownStates.Activités1 ? 'minus-sign' : 'plus-sign'}`}>
                                    {dropdownStates.Activités1 ? '_' : '+'}
                                </span>
                            </div>

                            <div className="tooltip3" id="tooltip3">
                                <div className="wrap-center3">
                                    <div className="tooltip-wrap3">

                                        <div className="tool-title3">
                                            <p>Activités</p>

                                        </div>

                                        <div className="wrap-list3">
                                            <ul className="first-list3">


                                                <Link to="/evmnt-cal" className="tooltip3-link3">
                                                    <li>     Calendrier d'événements           </li>
                                                </Link>




                                                <Link to="/sev-ali" className="tooltip3-link3">
                                                    <li>       Service alimentaire </li>
                                                </Link>




                                                <Link to="/libr-airie" className="tooltip3-link3">
                                                    <li>     Librairie
                                                    </li>
                                                </Link>



                                            </ul>

                                            <ul className="second-list3">





                                                <Link to="/medoc" className="tooltip3-link3">
                                                    <li>    Infirmerie       </li>
                                                </Link>




                                                <Link to="/vesti-code" className="tooltip3-link3">
                                                    <li>       Code verstimentaire   </li>
                                                </Link>




                                                <Link to="/pla-inte" className="tooltip3-link3">
                                                    <li>     Plaintes et divulgation </li>
                                                </Link>



                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title-line3"></div>
                            <div className={`dropdown-content ${dropdownStates.Activités1 ? 'active' : ''}`}>
                                {/* Dropdown content for Activités */}

                                <ul className="first-list3">

                                    <li>
                                        <Link to="/evmnt-cal" className="tooltip3-link3">
                                            Calendrier d'événements
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/sev-ali" className="tooltip3-link3">
                                            Service alimentaire
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/libr-airie" className="tooltip3-link3">
                                            Librairie
                                        </Link>

                                    </li>


                                </ul>

                                <ul className="second-list3">




                                    <li>
                                        <Link to="/medoc" className="tooltip3-link3">
                                            Infirmerie
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/vesti-code" className="tooltip3-link3">
                                            Code verstimentaire
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/pla-inte" className="tooltip3-link3">
                                            Plaintes et divulgation
                                        </Link>

                                    </li>

                                </ul>

                            </div>
                        </div>


                        <div className={`Apropos-area4 ${isAnyDropdownOpen ? '' : 'inactive-titleA'}`} ref={title13Ref}>
                            <div className={`joindre ${isAnyDropdownOpen ? 'active-title' : 'inactive-title'}`}
                                onClick={() => toggleDropdown('joindre')}>
                                <h1>  Nous joindre  </h1>
                                <span className={`sign ${dropdownStates.joindre ? 'minus-sign' : 'plus-sign'}`}>
                                    {dropdownStates.joindre ? '_' : '+'}
                                </span>
                            </div >

                            <div className="tooltip4" id="tooltip4">
                                <div className="wrap-center4">
                                    <div className="tooltip-wrap4">

                                        <div className="tool-title4">
                                            <p>Nous joindre</p>
                                        </div>

                                        <div className="wrap-list4">
                                            <ul className="first-list4">

                                                <Link to="/Noos-coo" className="tooltip4-link4">
                                                    <li>     Nos Coordonées       </li>
                                                </Link>




                                                <Link to="/Heur-douv" className="tooltip3-link3">
                                                    <li>    Nos heures d'ouverture   </li>
                                                </Link>




                                                <Link to="/Bot-tin" className="tooltip3-link3">
                                                    <li>   Bottin       </li>
                                                </Link>


                                            </ul>
                                            <ul className="second-list4">

                                                <li>  <div className="SocialiconF">
                                                    <a href="https://example.com">
                                                        <img src={facebookIcon2} alt="" className="facebook-image2" />
                                                        <h1>Facebook</h1>
                                                    </a>
                                                </div>
                                                </li>
                                                <li>
                                                    <div className="SocialiconX">
                                                        <a href="https://example.com">
                                                            <img src={xIcon2} alt="" className="x-image2" />
                                                            <h1>Twitter</h1>
                                                        </a>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="SocialiconI">
                                                        <a href="https://example.com">
                                                            <img src={instaIcon2} alt="" className="insta-image2" />
                                                            <h1>Instagram</h1>
                                                        </a>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="SocialiconL">
                                                        <a href="https://example.com">
                                                            <img src={linkedInIcon2} alt="" className="lkndn-image2" />
                                                            <h1>Linkdne</h1>
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title-line4"></div>

                            <div className={`dropdown-content ${dropdownStates.joindre ? 'active' : ''}`}>
                                {/* Dropdown content for Activités */}

                                <ul className="first-list4">
                                    <li>
                                        <Link to="/Noos-coo" className="tooltip4-link4">
                                            Nos Coordonées
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/Heur-douv" className="tooltip3-link3">
                                            Nos heures d'ouverture
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/Bot-tin" className="tooltip3-link3">
                                            Bottin
                                        </Link>

                                    </li>
                                </ul>
                                <ul className="second-list4">
                                    <li>
                                        <div className="SocialiconF">

                                            <Link to="https://example.com" className="tooltip3-link3">
                                                Facebook
                                            </Link>



                                        </div>
                                    </li>

                                    <li>
                                        <div className="SocialiconX">

                                            <Link to="https://example.com" className="tooltip3-link3">
                                                Twitter
                                            </Link>



                                        </div>
                                    </li>


                                    <li>
                                        <div className="SocialiconI">

                                            <Link to="https://example.com" className="tooltip3-link3">
                                                Instagram
                                            </Link>



                                        </div>
                                    </li>

                                    <li>
                                        <div className="SocialiconL">

                                            <Link to="https://example.com" className="tooltip3-link3">
                                                Linkdne
                                            </Link>



                                        </div>
                                    </li>


                                </ul>
                            </div>
                        </div>





                        <div className={`Apropos-area5 ${isAnyDropdownOpen ? '' : 'inactive-titleA'}`}  >
                            <div className={`Étudiants ${isAnyDropdownOpen ? 'active-title' : 'inactive-title'}`}
                                onClick={() => toggleDropdown('Étudiants')} ref={title14Ref}>
                                <h1> Étudiants    </h1>
                                <span className={`sign ${dropdownStates.Étudiants ? 'minus-sign' : 'plus-sign'}`}>
                                    {dropdownStates.Étudiants ? '_' : '+'}
                                </span>
                            </div>


                            <div className="tooltip5" id="tooltip5">
                                <div className="wrap-center5">
                                    <div className="tooltip-wrap5">

                                        <div className="tool-title5">
                                            <p>Étudiants</p>

                                        </div>

                                        <div className="wrap-list5">
                                            <ul className="first-list5">

                                                <Link to="/Gis-tra" className="tooltip5-link5">
                                                    <li>   Registrariat        </li>
                                                </Link>




                                                <Link to="/Ins-cirp" className="tooltip5-link5">
                                                    <li>  Inscriptions     </li>
                                                </Link>




                                                <Link to="/Surv-pv" className="tooltip5-link5">
                                                    <li>     Bureau du surveillant  </li>
                                                </Link>


                                            </ul>

                                            <ul className="second-list5">


                                                <Link to="/serv-ori" className="tooltip5-link5">
                                                    <li>   Orientation     </li>
                                                </Link>






                                                <Link to="/Cfte-ria" className="tooltip5-link5">
                                                    <li>   Cafétariat    </li>
                                                </Link>





                                                <Link to="/Biblio-tek" className="tooltip5-link5">
                                                    <li>    Bibliotheque      </li>
                                                </Link>




                                            </ul>
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className="title-lineC4"></div>

                            <div className={`dropdown-content ${dropdownStates.Étudiants ? 'active' : ''}`}>
                                {/* Dropdown content for Activités */}

                                <ul className="first-list5">
                                    <li>
                                        <Link to="/Gis-tra" className="tooltip5-link5">
                                            Registrariat
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/Ins-cirp" className="tooltip5-link5">
                                            Inscriptions
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/Surv-pv" className="tooltip5-link5">
                                            Bureau du surveillant
                                        </Link>

                                    </li>
                                </ul>

                                <ul className="second-list5">

                                    <li>
                                        <Link to="/serv-ori" className="tooltip5-link5">
                                            Orientation
                                        </Link>

                                    </li>



                                    <li>
                                        <Link to="/Cfte-ria" className="tooltip5-link5">
                                            Cafétariat
                                        </Link>

                                    </li>


                                    <li>
                                        <Link to="/Biblio-tek" className="tooltip5-link5">
                                            Bibliotheque
                                        </Link>

                                    </li>


                                </ul>

                            </div>
                        </div>



                        <div className={`Apropos-area6 ${isAnyDropdownOpen ? '' : 'inactive-titleA'}`} >
                            <div className={`Publics ${isAnyDropdownOpen ? 'active-title' : 'inactive-title'}`}
                                onClick={() => toggleDropdown('Publics')} ref={title15Ref}>
                                <h1> Publics </h1>
                                <span className={`sign ${dropdownStates.Publics ? 'minus-sign' : 'plus-sign'}`}>
                                    {dropdownStates.Publics ? '_' : '+'}
                                </span>
                            </div>

                            <div className="tooltip6" id="tooltip6">
                                <div className="wrap-center6">
                                    <div className="tooltip-wrap6">

                                        <div className="tool-title6">
                                            <p>Autre publics</p>
                                        </div>

                                        <div className="wrap-list6">
                                            <ul className="first-list6">

                                                <Link to="Diplo-mey" className="tooltip6-link6">
                                                    <li>  Diplômés     </li>
                                                </Link>




                                                <Link to="Akks-bil" className="tooltip6-link6">
                                                    <li>     Accessibilité  </li>
                                                </Link>




                                                <Link to="/Cons-fidd" className="tooltip6-link6">
                                                    <li>     Confidentialité   </li>
                                                </Link>




                                            </ul>

                                            <ul className="second-list6">




                                                <Link to="/serv-Pub" className="tooltip6-link6">
                                                    <li>  Service offer au public </li>
                                                </Link>




                                                <Link to="/Part-ney" className="tooltip6-link6">
                                                    <li>  Partenaires</li>
                                                </Link>


                                                {/* Débloquer au besoin
                                                <li>
                                                    <Link to="/Autr-2X" className="tooltip6-link6">
                                                        Qui sait même
                                                    </Link>

                                                </li>
 */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`dropdown-content ${dropdownStates.Publics ? 'active' : ''}`}>
                                {/* Dropdown content for Activités */}

                                <ul className="first-list6">
                                    <li>
                                        <Link to="Diplo-mey" className="tooltip6-link6">
                                            Diplômés
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="Akks-bil" className="tooltip6-link6">
                                            Accessibilité
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/Cons-fidd" className="tooltip6-link6">
                                            Confidentialité
                                        </Link>

                                    </li>


                                </ul>

                                <ul className="second-list6">



                                    <li>
                                        <Link to="/serv-Pub" className="tooltip6-link6">
                                            Service offer au public
                                        </Link>

                                    </li>

                                    <li>
                                        <Link to="/Part-ney" className="tooltip6-link6">
                                            Partenaires
                                        </Link>

                                    </li>
                                    {/* Débloquer au besoin
                                    <li>
                                        <Link to="/Autr-2X" className="tooltip6-link6">
                                            Qui sait même
                                        </Link>

                                    </li>
 */}
                                </ul>
                            </div>
                        </div>
                    </div >
                </div >
                <img src={newarrow} alt="Golden Arrow" className="D-arrow" onClick={toggleMenu} />
            </div >
        </div >
    );
};

export default BlackSection;