import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import './MapContainer.scss';

class MapContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPlace: null, // Initialize selectedPlace state
        };
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true,
        });

        // Set the desired coordinates for the marker
        marker.setPosition(new this.props.google.maps.LatLng(13.561757889741061, 2.0735432503334588));
    };

    onInfoWindowClose = () => {
        this.setState({
            selectedPlace: null, // Close InfoWindow by setting selectedPlace to null
        });
    };

    render() {
        return (
            <div id="map-container">
                <Map google={this.props.google} zoom={14} id="map" initialCenter={{ lat: 13.561757889741061, lng: 2.0735432503334588 }}>

                    <Marker onClick={this.onMarkerClick} name={'Current location'} />
                    <InfoWindow onClose={this.onInfoWindowClose}>
                        <div>
                            {this.state.selectedPlace && (
                                <h1>{this.state.selectedPlace.name}</h1>
                            )}
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        );
    }
}


export default GoogleApiWrapper({
    apiKey: 'AIzaSyB5SBTevYGPnUPxRIz6i53zHnaVagAXY34',
})(MapContainer);