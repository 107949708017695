import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './Registrariat.scss';
import HeaderA from './HeaderA';
import BlackSectionA from './BlackSectionA';
import InfoReg from './InfoRegPage/InfoReg';
import ModernFooter from './ModernFooter';
import InfoVerticalePageR from './InfoVerticalePageR';

const Registraire = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const location = useLocation(); // Get the current location


    return (
        <div>
            <HeaderA />

            {/* Rest of your content */}
            <div className="Grey-section-Reg">
                <div className="Grey-wrap-Reg">
                    <div className="Grey-center-Reg">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaReg-wrap">
                                <h1 className="Faza-Reg" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Regarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposReg-wrap">
                                <h1 className="AproposReg" id="titleA">
                                    Étudiants
                                </h1>

                                <h2 className="RegarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AReg" id="titleA">
                            Régistrariat
                        </h1>

                    </div>
                </div>
            </div>
            <BlackSectionA />
            <div className="infov-main-Reg">
                <h1 className="A1Reg" id="titleA">
                    <span class="bold-text">Régistrariat </span>
                </h1>
                <div className="infov-Reg">
                    <div className="infov-Reg-Border">


                        <InfoVerticalePageR />
                        <InfoReg />
                    </div>
                </div>
            </div>


            <ModernFooter />

        </div>

    );
};

export default Registraire;