import React, { useState, useEffect } from 'react';

import './InfoSur.scss';

const InfoSur = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesA(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesA, setDropdownStatesA] = useState({
        SurAI: false,
        SurAI2: false,
        SurAI3: false,


    });


    return (


        <div className="Etude-Sur">
            <div className="Etude-Sur-center">
                {/* Rest of your content */}


                <div className="First-Sur">
                    <h1 className="Sur" id="title1">Bureau du surveillant</h1>
                    <div className="First-Sur-wrap">

                        <div className="Etapes-Sur">
                            <h1 className="SurI" id="title1" >Le surveillant est responsable de l'ordre, de la sécurité des personnes et des biens dans l'établissement. Il veille au respect des droits et des devoirs de tous les membres de la communauté scolaire et assure l'application du règlement intérieur. </h1>

                        </div>
                        <div className="Drop-Sur-wrap">
                            <div className="Pieces-Sur" >
                                <div className={`SurII  ${dropdownStatesA.SurAI ? 'dropdownCo-open' : 'dropdownCo-closed'}`}>
                                    <div className="ASurWrap" onClick={(e) => handleInformationAClick('SurAI', e)}>
                                        <h1 className="SurAI"> Entrées et sorties des élèves </h1>


                                        <span className={`signSur ${dropdownStatesA.SurAI ? 'minus-signSur' : 'plus-signSur'}`} >
                                            {dropdownStatesA.SurAI ? '_' : '+'}
                                        </span>

                                    </div>
                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentSur ${dropdownStatesA.SurAI ? 'active' : ''}`}>

                                        <div className="firstSur">
                                            <h1 className="Info-titleCo">
                                                L'établissement scolaire doit vous informer, via le cahier de correspondance, de toute modification prévisible des horaires d'entrée et de sortie de votre enfant. Par exemple, en cas d'absence d'un enseignant.
                                            </h1>

                                            <h1 className="Info-titleCo">
                                                Si cette information préalable n'est pas communiquée, l'établissement doit assurer la surveillance des élèves aux horaires habituels de la classe. Cette surveillance est généralement organisée sous forme d'heures de permanence ou d'études surveillées.
                                            </h1>
                                            <h1 className="Info-titleCo">
                                                Si cette information préalable n'est pas communiquée, l'établissement doit assurer la surveillance des élèves aux horaires habituels de la classe. Cette surveillance est généralement organisée sous forme d'heures de permanence ou d'études surveillées.
                                            </h1>

                                        </div>


                                    </div>
                                </div>

                            </div>



                            <div className="Pieces-Sur2">
                                <div className={`SurII2  ${dropdownStatesA.SurAI2 ? 'dropdownCo-open' : 'dropdownCo-closed'}`}>
                                    <div className="ASurWrap" onClick={(e) => handleInformationAClick('SurAI2', e)}>
                                        <h1 className="SurAI2" id="title1" > Activités scolaires dans l'établissement</h1>



                                        <span className={`signSur ${dropdownStatesA.SurAI2 ? 'minus-signSur' : 'plus-signSur'}`} >
                                            {dropdownStatesA.SurAI2 ? '_' : '+'}
                                        </span>

                                    </div>
                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentSur ${dropdownStatesA.SurAI2 ? 'active' : ''}`}>

                                        <div className="firstSur2">
                                            <h1 className="Info-titleCo">
                                                Les élèves doivent être surveillés pendant toutes les activités scolaires qui se déroulent dans l'établissement.
                                            </h1>

                                            <h1 className="Info-titleCo">
                                                La surveillance doit donc être assurée pendant les enseignements obligatoires et les enseignements facultatifs auxquels votre enfant est inscrit.
                                            </h1>
                                            <h1 className="Info-titleCo">
                                                Elle doit aussi être assurée pendant les études dirigées ou surveillées, les permanences, les récréations, les interclasses, les repas (sauf pour les externes) et les nuitées pour les internes.
                                            </h1>

                                            <h1 className="Info-titleCo2">
                                                Plan particulier de mise en sécurité (PPMS).
                                            </h1>

                                            <h1 className="Info-titleCo">
                                                L'établissement scolaire  mettra en place un plan particulier de mise en sécurité (PPMS). Ce dispositif doit permettre la mise en sécurité des élèves et du personnel en cas d'intrusion dans l'établissement notamment.

                                                Chaque année, 2 exercices de type PPMS (mise à l'abri ou confinement) sont organisés dans l'établissement.
                                            </h1>
                                        </div>

                                    </div>

                                </div>


                            </div>



                            <div className="Pieces-Sur3">
                                <div className={`SurII3  ${dropdownStatesA.SurAI3 ? 'dropdownCo-open' : 'dropdownCo-closed'}`}>
                                    <div className="ASurWrap" onClick={(e) => handleInformationAClick('SurAI3', e)}>
                                        <h1 className="SurAI3" id="title1" > Activités scolaires hors de l'établissement</h1>

                                        <span className={`signSur ${dropdownStatesA.SurAI3 ? 'minus-signSur' : 'plus-signSur'}`}>
                                            {dropdownStatesA.SurAI3 ? '_' : '+'}
                                        </span>

                                    </div>
                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentSur ${dropdownStatesA.SurAI3 ? 'active' : ''}`}>

                                        <div className="firstSur3">
                                            <h1 className="Info-titleCo">
                                                Les élèves doivent être surveillés pendant les déplacements collectifs organisés en dehors de l'établissement.
                                            </h1>

                                            <h1 className="Info-titleCo2">
                                                Déplacements pendant le temps scolaire
                                            </h1>
                                            <h1 className="Info-titleCo">
                                                Pendant le temps scolaire, les déplacements des élèves entre le collège et le lieu d'une activité scolaire doivent être encadré.
                                            </h1>
                                            <h1 className="Info-titleCo">
                                                Toutefois, si le règlement intérieur le prévoit, vous pouvez autoriser votre enfant à s'y rendre ou à en revenir seul lorsque l'activité a lieu en début ou en fin de temps scolaire.
                                            </h1>



                                            <h1 className="Info-titleCo">
                                                Le trajet entre le domicile et le lieu de l'activité est alors considéré comme le trajet habituel entre le domicile et le collège.
                                            </h1>
                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>







                    </div>


                </div>



            </div>
        </div >





    );

};


export default InfoSur;