// MajorMinorPoints.js
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './MajorMinorPoints.scss';
import Access from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/access.png'; // Update the path accordingly
import GoldenarrowD from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/golden arrow.png'; // Update the path accordingly
import facebookIconB from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/Facebook white.png';
import xIconB from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/X White.png';
import instaIconB from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/Insta White.png';
import linkedInIconB from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/Linkdne White.png';



const MajorMinorPoints = () => {
    const location = useLocation();
    const [selectedRectangle, setSelectedRectangle] = useState(null);

    useEffect(() => {
        // Map the path to a key that represents each title
        const rectangleIndex = {

            '/Fazaz': 1,
        };

        // Set the selected path based on the current path
        setSelectedRectangle(rectangleIndex[location.pathname] || 1);
    }, [location]);





    return (
        <div className="major-points" id="major-points" >
            <div className="major-points-wrapper">
                <div className="major-points-top">


                    <div className="demande-admiss">
                        <Link to="/InformationET">

                            <div className="Boda">
                                <div className={`white-borda ${selectedRectangle === 1 ? 'selected1' : ''}`}>

                                    <h2>Demande d'admission</h2>

                                    {/* Add your social media icons or links here */}
                                    <img className="GoldenarrowD" src={GoldenarrowD} alt="" />
                                </div>

                            </div>

                        </Link>
                        {/* confidentialité bas de page */}

                    </div>
                    <div className="major-points-top-right">
                        {/* Social Media Icons */}
                        <div className="SocialiconB">
                            <a href="https://example.com">
                                <img src={facebookIconB} alt="" className="facebook-imageB" />
                            </a>
                        </div>

                        <div className="SocialiconB">
                            <a href="https://example.com">
                                <img src={xIconB} alt="" className="x-imageB" />
                            </a>
                        </div>

                        <div className="SocialiconB">
                            <a href="https://example.com">
                                <img src={instaIconB} alt="" className="insta-imageB" />
                            </a>
                        </div>

                        <div className="SocialiconB">
                            <a href="https://example.com">
                                <img src={linkedInIconB} alt="" className="lkndn-imageB" />
                            </a>
                        </div>





                        <Link to="/Cons-fidd" className="confidentialité">

                            <h2>Confidentialité </h2>

                        </Link>
                        {/* Separation line under Journée culturelle de Faza */}
                        <div className="separator-line4"></div>

                        {/* Accessibilité bas de page */}

                        <Link to="/Akks-bil" className="Access-ibilité">



                            <h3>Accessibilité Web <img className="Access-logo" src={Access} alt="" /></h3>


                        </Link>
                        {/* Add golden arrow demande d'ad... */}

                    </div>
                </div>
                <div className="major-points-bottom">
                    <div className="major-points-Aliner">
                        {/* Major Point 1 */}
                        <div className="Àpropos major-point">
                            <h3>À propos</h3>
                            <ul>

                                <li>
                                    <Link to="/information-G" className="major-link">
                                        Information générale
                                    </Link>

                                </li>


                                <li>
                                    <Link to="/direction-service2" className="major-link">
                                        Direction et services
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Histoire-faza2" className="major-link">
                                        Histoire de Faza
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Faits-chiffre2" className="major-link">
                                        Faits et Chiffres
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/ecoles-faza2" className="major-link" >
                                        Les Écoles Faza
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/travailler-faza2" className="major-link">
                                        Travailler à Faza
                                    </Link>

                                </li>





                            </ul>


                        </div>

                        {/* Major Point 2 */}
                        <div className="Étudier major-point">
                            <h3>Étudier à Faza</h3>
                            <ul>
                                <li>
                                    <Link to="/Etud-G" className="major-link">
                                        Tout sur les études à Faza
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Dem-adm" className="major-link" >
                                        Demande d'admission
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Cout-G2" className="major-link">
                                        Coût des études
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Faz-Son2" className="major-link">
                                        Faza Sonuci
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Faz-Yan2" className="major-link">
                                        Faza Yantala
                                    </Link>

                                </li>


                            </ul>
                        </div>

                        {/* Major Point 3 */}
                        <div className="Activités major-point">
                            <h3>Activités</h3>
                            <ul>

                                <li>
                                    <Link to="/Kal-G" className="major-link">
                                        Calendrier d'événements de Faza
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Inf-irm" className="major-link">
                                        Infirmerie
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Cod-v2" className="major-link">
                                        Code vestimentaire
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Plt-div" className="major-link">
                                        Plaintes et divulgation
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Sevi-ali" className="major-link" >
                                        Services alimentaires
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Libr-aria" className="major-link" >
                                        Libraire
                                    </Link>

                                </li>



                            </ul>
                        </div>

                        {/* Major Point 4 */}
                        <div className="Joindr3 major-point">
                            <h3>Nous Joindre</h3>
                            <ul>

                                <li>
                                    <Link to="/nos-cor2" className="major-link">
                                        Nos coordonnées
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Nos-h2" className="major-link">
                                        Heures d’ouverture
                                    </Link>

                                </li>

                                <li>
                                    <Link to="/Bo-tti2" className="major-link">
                                        Bottin
                                    </Link>

                                </li>
                                <li><a href="https://example.com" rel="noreferrer" className="major-link">Nos réseaux sociaux</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="separator-line-bottom1"></div>
                <div className="Bottom-Wrap">
                    <div className="Bottom-Wrap-center">
                        <div className="separator-line-bottom"></div>
                        <div className="Faza-joindre">

                            <a href="#" className="Faza-wrap" onclick="window.location-reload(); return false;">
                                <h1>Complexe Scolaire Privé Faza</h1>
                            </a>

                            <div className="separator-lineF"></div>

                            <Link to="/Noos-coo" className="Joindre-Tel">

                                <h2>Nous joindre</h2>

                            </Link>

                        </div>

                        <div className="separator-line-bottom2"></div>
                        <div className="Témoins">
                            <div className="Témoins-wrap">
                                <h1>Préférence des témoins</h1>
                            </div>
                            <div className="separator-line-Temoin"></div>

                        </div>

                        <div className="major-points-top-right-Tel">

                            <Link to="/Cons-fidd" className="confidentialité-Tel">

                                <h2>Confidentialité </h2>

                            </Link>
                            {/* Separation line under Journée culturelle de Faza */}
                            <div className="separator-lineT"></div>

                            {/* Accessibilité bas de page */}

                            <Link to="/Akks-bil" className="Access-ibilitéTel">



                                <h3>Accessibilité Web <img className="Access-logo" src={Access} alt="" /></h3>


                            </Link>
                            {/* Add golden arrow demande d'ad... */}

                        </div >
                    </div >
                </div >
            </div >
        </div >
    );
};

export default MajorMinorPoints;