import React, { useEffect } from 'react';

import './InfoBib.scss';
import logom from './Images/Subject.png'; // Update the path accordingly

const InfoBib = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);




    return (


        <div className="Etude-Bib">
            <div className="Etude-Bib-center">
                {/* Rest of your content */}


                <div className="First-Bib">
                    <h1 className="DemanBib" id="title1">Cette page sera bientôt disponible</h1>
                    <img className="new-logom" src={logom} alt="" />


                </div>


            </div>
        </div>





    );

};


export default InfoBib;