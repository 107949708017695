import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './InfoVerticalePageR.scss';

const InfoVerticalePageR = () => {
    const location = useLocation();
    const [selectedRectangleA, setSelectedRectangleA] = useState(null);

    useEffect(() => {
        // Function to map the path to rectangle index
        const rectangleAIndex = {

            '/Gis-tra': 1,
            '/Ins-cirp': 2,

            '/Surv-pv': 3,

            '/serv-ori': 4,

            '/Serve': 1,
            '/ServA': 2,

            '/LibA': 3,

            '/JoindreS': 4,



        };

        // Set the selected rectangle based on the current path
        setSelectedRectangleA(rectangleAIndex[location.pathname] || 1);
    }, [location]);


    return (
        <div className="info-verticale-pageR">
            <div className="info-verticaleBackR">
                <div className="info-verticale-wrapR">
                    <Link to="/Gis-tra">
                        <div className={`info-rectangleA ${selectedRectangleA === 1 ? 'selectedA' : ''}`}>
                            <h1 className="info-titleA">Registrariat</h1>
                        </div>
                    </Link>

                    <Link to="/Ins-cirp">
                        <div className={`info-rectangleA ${selectedRectangleA === 2 ? 'selectedA' : ''}`}>

                            <h1 className="info-titleA">Inscriptions</h1>
                        </div>






                    </Link >

                    <Link to="/Surv-pv">
                        <div className={`info-rectangleA ${selectedRectangleA === 3 ? 'selectedA' : ''}`}>
                            <h1 className="info-titleA">Bureau du surveillant</h1>
                        </div>
                    </Link>

                    <Link to="/serv-ori">
                        <div className={`info-rectangleA ${selectedRectangleA === 4 ? 'selectedA' : ''}`}>
                            <h1 className="info-titleA">Orientation</h1>
                        </div>
                    </Link>
                </div >
            </div >
        </div>
    );
};

export default InfoVerticalePageR;
