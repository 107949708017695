import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeaderInfo from './HeaderInfo';
import BlackSectionInfo from './BlackSectionInfo';
import './NousJoindrePage.scss';
import InfoJoin from './NousJoindrePage/InfoJoin';
import ModernFooter from './ModernFooter';



const NousJoindrePage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location
    return (
        <div>
            <HeaderInfo />
            <div className="Grey-section-Join">
                <div className="Grey-wrapJoin">
                    <div className="Grey-centerJoin">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaJoin-wrap">
                                <h1 className="FazaJoin" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>

                                <h2 className="Joinarrow" id="title2">{'>'} </h2>
                            </div>
                        </Link>


                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposJoin-wrap">

                                <h1 className="ApropoJoin" id="titleA">
                                    À propos
                                </h1>

                                <h2 className="JoinarowA" id="titleA2">{'>'}  </h2>
                            </div>
                        </Link>



                        <h1 className="Joinirect" id="titleA">
                            Nous joindre
                        </h1>
                    </div>

                </div>
            </div >



            <BlackSectionInfo />

            <div className="infov-Join">
                <div className="infov-Join-center">
                    <InfoJoin />
                </div>

            </div>

            <ModernFooter />
        </div>
    );
};

export default NousJoindrePage;