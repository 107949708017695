import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BlackSectionE.scss';
import '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Pages/Étude à Faza/EtudeFaza.scss';

import newarrow2 from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/golden arrow.png';

const BlackSectionE = () => {

    // Toggle dropdown and maintain its state
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleInformationTClick = (event) => {
        if (window.innerWidth <= 991) {
            event.preventDefault();
            // Toggle dropdown and maintain its state
            setDropdownOpen(!dropdownOpen);
            setDropdownStatesT(prevStates => ({
                ...prevStates,
                AdmiE: !prevStates.AdmiE

            }));


        }


    };

    ///Delay on the Navigation


    const handleDelayedNavigationE = (url, delay) => {
        // Start the closing animation
        const gridContainer3 = document.querySelector('.grid-container3');
        gridContainer3.classList.add('closing');

        setTimeout(() => {
            setIsMenuOpenE(false);
            gridContainer3.classList.remove('closing');

            // After the grid slides up, navigate to the new URL
            setTimeout(() => {
                window.location.href = url;
            }, delay); // Delay for the navigation

        }, 200); // Duration of the slide-up animation
    };
    ///Delay on the Navigation /End

    ///Grid


    ///close grid when click outside
    const blackSectionERef = useRef(null);
    const gridERef = useRef(null);
    const titleRef = useRef(null);
    const searchERef = useRef(null);

    // Handler to toggle the grid from the menu icon and black section
    const toggleGrid3 = (event) => {
        if (!titleRef.current.contains(event.target) &&
            !searchERef.current.contains(event.target)) {
            setIsMenuOpenE(!isMenuOpenE);
        }
    };

    // ... existing code

    // Close the grid when clicking on the empty surface
    // Handler for closing the grid when clicking outside
    const handleClickOutside = (event) => {
        if (blackSectionERef.current && !blackSectionERef.current.contains(event.target) &&
            gridERef.current && !gridERef.current.contains(event.target) &&
            !(titleRef.current && titleRef.current.contains(event.target)) &&
            !(searchERef.current && searchERef.current.contains(event.target))) {
            setIsMenuOpenE(false);
        }
    };


    const [isMenuOpenE, setIsMenuOpenE] = useState(false);
    const [dropdownStatesT, setDropdownStatesT] = useState({
        EtudeE: false,
        AdmiE: false,
        CoutE: false
        // ... other dropdowns if necessary

    });

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    ///close grid when click outside /End


    ///Grid /End




    const location = useLocation();
    const [selectedRectangleE, setSelectedRectangleE] = useState(null);
    const [selectedRectangleT, setSelectedRectangleT] = useState(null);
    useEffect(() => {
        // Map the path to a key that represents each title
        const rectangleEIndex = {
            '/etude-faza': 1,
            '/demande-admin': 2,
            '/couts-etude': 3,

            // Majortitles Etudier a Faza
            '/Etud-G': 1,
            '/Dem-adm': 2,
            '/Cout-G2': 3,
        };


        const rectangleTIndex = {
            '/InformationET': 1,

            '/Pieces-requises': 2,
            '/demande-admin': 1,
            '/frais-admission': 3,

            '/dates-limites': 4,

            '/Traitment-demandes': 5,

            '/Test-dentree': 6,


        };

        // Check if the current path is one of the dropdown items
        const isDropdownItem = Object.keys(rectangleTIndex).includes(location.pathname);
        // Set the selected rectangle based on the current path
        setSelectedRectangleT(rectangleTIndex[location.pathname] || null);
        // Set the selected path based on the current path
        setSelectedRectangleE(rectangleEIndex[location.pathname] || 2);



        // Set the dropdown to open if the current path is a dropdown item


        if (isDropdownItem) {
            setDropdownStatesT(prevStates => ({
                ...prevStates,
                AdmiE: true
            }));
            setDropdownOpen(true); // Ensure dropdownOpen state is also set
        }
    }, [location]);


    ///Search Engine
    const [searchTermE, setSearchTermE] = useState('');

    const handleSearchChangeE = (event) => {
        setSearchTermE(event.target.value);
    };

    const handleSearchSubmitE = () => {
        window.location.href = `/search?query=${encodeURIComponent(searchTermE)}`;
    };
    ///Search Engine /End
    // New function to handle key press
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmitE();
        }
    };


    return (

        <div className="black-sectionE" ref={blackSectionERef} onClick={toggleGrid3}>
            <div className="mobile-menu-icon3" >
                {/* Hamburger Icon */}
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div className="CspFaza3">
                <h1>Étudier à Faza</h1>
            </div>


            <div className={`grid-container3 ${isMenuOpenE ? 'open' : ''}`} ref={gridERef}>


                <div className="header-wrapEtu" >
                    <div className="headerEtu">
                        <div className="search-barEtu" >
                            <input
                                type="text"
                                placeholder="Chercher..."
                                value={searchTermE}
                                onChange={handleSearchChangeE}
                                ref={searchERef}
                                onKeyPress={handleKeyPress}
                            />
                            <i className="fa fa-search search-iconEtud" onClick={handleSearchSubmitE}></i>
                        </div>
                    </div>

                    <div className="Title-wrapperEtu" >







                        <Link to="/etude-faza" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigationE('/etude-faza', 200); // Adjust the delay as needed
                        }}>


                            <div>
                                <div className={`EtudeE ${selectedRectangleE === 1 ? 'selectedE' : ''}`}>
                                    <h1 className="info-title" >Études à Faza</h1>
                                </div>
                            </div>
                        </Link>



                        <div className="title-lineEI"></div>



                        <Link to="/demande-admin" ref={titleRef} onClick={handleInformationTClick} >
                            <div className={`AdmiE ${selectedRectangleE === 2 ? 'selectedE' : ''} ${dropdownStatesT.AdmiE ? 'dropdown-openT' : ''}`}>
                                <div className="AmiEWrap" ref={titleRef}>
                                    <h1 className="info-title" ref={titleRef}>Demande d'admission</h1>
                                    <span className={`signA ${dropdownStatesT.AdmiE ? 'minus-signA' : 'plus-signA'}`}>
                                        {dropdownStatesT.AdmiE ? '_' : '+'}
                                    </span>
                                </div>
                                <div className={`dropdown-contentT ${dropdownStatesT.AdmiE ? 'activeE' : ''}`}>

                                    <Link to="/InformationET" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/InformationET', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleET ${selectedRectangleT === 1 ? 'selectedT' : ''}`}>
                                            <h1 className="info-titleT">Étapes pour déposer une demande d'admission</h1>
                                        </div>
                                    </Link>



                                    <Link to="/Pieces-requises" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/Pieces-requises', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleET ${selectedRectangleT === 2 ? 'selectedT' : ''}`}>

                                            <h1 className="info-titleT">Pièces requises</h1>
                                        </div>
                                    </Link >


                                    <Link to="/frais-admission" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/frais-admission', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleET ${selectedRectangleT === 3 ? 'selectedT' : ''}`}>
                                            <h1 className="info-titleT">Frais d'admission et reçu</h1>
                                        </div>
                                    </Link>


                                    <Link to="/dates-limites" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/dates-limites', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleET ${selectedRectangleT === 4 ? 'selectedT' : ''}`}>
                                            <h1 className="info-titleT">Dates limites d'admission</h1>
                                        </div>
                                    </Link>

                                    <Link to="/Traitment-demandes" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/Traitment-demandes', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleET ${selectedRectangleT === 5 ? 'selectedT' : ''}`}>
                                            <h1 className="info-titleT">Traitement des demandes</h1>
                                        </div>
                                    </Link>

                                    <Link to="/Test-dentree" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/Test-dentree', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleET ${selectedRectangleT === 6 ? 'selectedT' : ''}`}>
                                            <h1 className="info-titleT">Test d'entrée </h1>
                                        </div>
                                    </Link>

                                </div>
                            </div>


                        </Link>

                        {/* Add similar Link elements for other menu items */}
                        <div className="title-lineEI2"></div>


                        <Link to="/couts-etude" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigationE('/couts-etude', 200); // Adjust the delay as needed
                        }}>
                            <div>
                                <div className={`CoutE ${selectedRectangleE === 3 ? 'selectedE' : ''}`}>
                                    <h1 className="info-title" >Coût des études</h1>
                                </div>
                            </div>
                        </Link>






                    </div>
                    <img src={newarrow2} alt="Golden Arrow" className="D-arrow2" onClick={toggleGrid3} />
                </div>
            </div >


        </div >


    );
};

export default BlackSectionE;