import React from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from './Header';
import GreySectionSearch from './GreySectionSearch';
import ModernFooter from './ModernFooter';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Highlight, RefinementList, Pagination, Configure } from 'react-instantsearch-dom';
import './SearchResults.scss';

const searchClient = algoliasearch('KUPASCK7V7', '4fb88e025f76ea40ea1eb890fdbdafe9');

function Hit({ hit }) {
    return (
        <article>
            <h1>
                <a href={hit.url}>
                    <Highlight attribute="title" hit={hit} />
                </a>
            </h1>
            {hit.description && <p>{hit.description}</p>}
        </article>
    );
}

const SearchResults = () => {
    const [searchParams] = useSearchParams();
    const searchTerm = searchParams.get('query');


    return (
        <div>
            <Header />
            <GreySectionSearch />

            <h1 className='Recherche-T'>
                Complexe Scolaire Privé FAZA
            </h1>

            <div className="contentr">



                <InstantSearch searchClient={searchClient} indexName="CSPFAZA" insights>
                    <Configure hitsPerPage={10} query={searchTerm} />
                    <SearchBox defaultRefinement={searchTerm} />
                    <RefinementList attribute="brand" />
                    <Hits hitComponent={Hit} />
                    <Pagination />
                </InstantSearch>
            </div>
            <ModernFooter />
        </div>
    );
};

export default SearchResults;
