import React, { useEffect } from 'react';

import './InfoHist.scss';

const InfoHist = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (


        <div className="info-Histoire">
            <div className="info-Histoire-center">
                {/* Rest of your content */}


                <div className="First-Hist">
                    <h1 className="Hist" id="title1">Histoire de Faza</h1>
                    <h1 className="HistI" id="title1" >Bienvenue au CSP Faza, l'institution éducative emblématique de Niamey depuis 2011. Découvrez une école dynamique, moderne et respectée, offrant une expérience éducative exceptionnelle.</h1>
                    <h1 className="HistII" id="title1" > Au CSP Faza, notre engagement envers des valeurs éthiques guide notre approche éducative. Nous créons un environnement chaleureux, favorisant l'inclusion et l'épanouissement de chaque élève.</h1>

                </div>

                <div className="Hist-line"></div>
                <div className="First-Hist2">

                    <h1 className="Story" id="title2">Faza Yantala</h1>
                    <h1 className="StoryI" id="title2" >
                        Depuis nos débuts en 2011, le CSP Faza a tracé un chemin dynamique dans l'éducation, introduisant des programmes novateurs qui ont profondément impacté la communauté éducative à Niamey.
                        Distinguez-vous avec nous.</h1>

                    <h1 className="StoryII" id="title2" >
                        Le CSP Faza se démarque par son approche pédagogique distinctive, des projets innovants axés sur des contributions sociales significatives, et une renommée locale bien méritée.
                        Modernité et Infrastructure propices à l'Apprentissage
                        Explorez nos installations modernes, équipées des dernières technologies éducatives.  </h1>
                </div>

                <div className="Hist-line2"></div>
                <div className="First-Hist3">
                    <h1 className="HFaza" id="title3">Diplômes</h1>

                    <h1 className="HFazaI" id="title3" >
                        Rejoignez une communauté éducative passionnée et un corps enseignant dévoué. Au CSP Faza, nous cultivons une atmosphère inclusive, propice à des approches pédagogiques innovantes.  </h1>

                    <h1 className="HFazaII" id="title3" >
                        Célébrant plus d'une décennie de succès, le CSP Faza affiche un bilan impressionnant. Découvrez nos réalisations et nos ambitieuses perspectives d'avenir, témoignant de notre engagement envers une éducation de qualité.  </h1>

                </div>
            </div>
        </div>





    );

};


export default InfoHist;