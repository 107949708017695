import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './InfoIns.scss';

const Infoinfirm = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesA(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesA, setDropdownStatesA] = useState({
        InsAI: false,
        InsA2I: false,
        InsA3I: false,


    });


    return (


        <div className="Etude-Ins">
            <div className="Etude-Ins-center">
                {/* Rest of your content */}


                <div className="First-Ins">
                    <h1 className="Ins" id="title1">Inscriptions</h1>
                    <div className="First-Ins-wrap">

                        <div className="Etapes-Ins">
                            <h1 className="InsI" id="title1" > Les Inscriptions se font du lundi au Samedi du 13 Mars au 1er Septembre au Registrariat.</h1>
                            {/* <h1 className="InsI2" id="title1" > </h1>*/}
                        </div>
                        <div className="Drop-Ins-wrap">
                            <div className="Pieces-Ins" >
                                <div className={`InsII  ${dropdownStatesA.InsAI ? 'dropdownInf-open' : 'dropdownInf-closed'}`}>
                                    <div className="AInsWrap" onClick={(e) => handleInformationAClick('InsAI', e)}>
                                        <h1 className="InsAI" >Mode de Paiement</h1>


                                        <span className={`signIns ${dropdownStatesA.InsAI ? 'minus-signIns' : 'plus-signIns'}`}>
                                            {dropdownStatesA.InsAI ? '_' : '+'}
                                        </span>

                                    </div>
                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentIns ${dropdownStatesA.InsAI ? 'active' : ''}`}>

                                        <div className="firstIns">
                                            <h1 className="Info-titleIns">Le paiement accepté est le virement bancaire</h1>

                                            <h1 className="Info-titleIns"> Veuillez <Link to="/Noos-coo"><span className="Info-titleIns2"> nous contacter </span> </Link>pour plus d'information</h1>
                                        </div>
                                        <div className="Ligen-Biento">
                                            <h1 className="Info-titleIns" id="title1" > </h1>
                                        </div>

                                    </div>
                                </div>

                            </div>



                            <div className="Pieces-Ins2">
                                <div className={`Ins2II  ${dropdownStatesA.InsA2I ? 'dropdownInf-open' : 'dropdownInf-closed'}`}>
                                    <div className="AInsWrap" onClick={(e) => handleInformationAClick('InsA2I', e)}>
                                        <h1 className="InsA2I" id="title1" > Modalité de versement</h1>


                                        <span className={`signIns ${dropdownStatesA.InsA2I ? 'minus-signIns' : 'plus-signIns'}`} >
                                            {dropdownStatesA.InsA2I ? '_' : '+'}
                                        </span>

                                    </div>
                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentIns ${dropdownStatesA.InsA2I ? 'active' : ''}`}>

                                        <div className="SecondIns">
                                            <h1 className="Info-titleIns">Premier versement : <span className="Info-titleIns2">50% du montant total</span></h1>
                                            <h1 className="Info-titleIns">Deuxième versement  : <span className="Info-titleIns2">25% du montant total</span></h1>
                                            <h1 className="Info-titleIns">Troisième versement  : <span className="Info-titleIns2">25% du montant total</span></h1>

                                        </div>

                                    </div>

                                </div>


                            </div>


                            <div className="Pieces-Ins3">

                                <div className={`Ins3II  ${dropdownStatesA.InsA3I ? 'dropdownInf-open' : 'dropdownInf-closed'}`}>
                                    <div className="AInsWrap" onClick={(e) => handleInformationAClick('InsA3I', e)}>
                                        <h1 className="InsA3I" id="title1" > Penalité de retard</h1>

                                        <span className={`signIns ${dropdownStatesA.InsA3I ? 'minus-signIns' : 'plus-signIns'}`} >
                                            {dropdownStatesA.InsA3I ? '_' : '+'}
                                        </span>

                                    </div>

                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentIns ${dropdownStatesA.InsA3I ? 'active' : ''}`}>

                                        <div className="ThirdIns">
                                            <h1 className="Info-titleIns">En cas de retard de paiement, Les Pénalités sont de : <span className="Info-titleIns2">10.000F.</span></h1>
                                            <h1 className="Info-titleIns"> Veuillez <Link to="/Noos-coo"><span className="Info-titleIns2"> nous contacter </span> </Link>pour plus d'information</h1>
                                        </div>

                                    </div>

                                </div>


                            </div>

                        </div>







                    </div>


                </div>



            </div>
        </div >





    );

};


export default Infoinfirm;