import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './Infirmerie.scss';
import HeaderA from './HeaderA';
import BlackSectionA from './BlackSectionA';
import InfoInfirm from './InfoInfirmPage/InfoInfirm';
import ModernFooter from './ModernFooter';
import InfoVerticalePageA from './InfoVerticalePageA';

const Infirmerie = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location


    return (
        <div>
            <HeaderA />
            {/* Rest of your content */}
            <div className="Grey-section-Inf">
                <div className="Grey-wrap-Inf">
                    <div className="Grey-center-Inf">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaInf-wrap">
                                <h1 className="Faza-Inf" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Infarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposInf-wrap">
                                <h1 className="AproposInf" id="titleA">
                                    Activités
                                </h1>

                                <h2 className="InfarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AInf" id="titleA">
                            Infirmerie
                        </h1>

                    </div>
                </div>
            </div>
            <BlackSectionA />


            {/* Rest of your content */}
            <div className="infov-main-Inf">
                <h1 className="A1Inf" id="titleA">
                    <span class="bold-text">Infirmerie scolaire</span>
                </h1>
                <div className="infov-Inf">
                    <div className="infov-Inf-Border">


                        <InfoVerticalePageA />
                        <InfoInfirm />
                    </div>
                </div>
            </div>

            <ModernFooter />

        </div>

    );
};

export default Infirmerie;