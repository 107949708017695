import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './InfoAdmin.scss';

const InfoAdmin = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesA(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesA, setDropdownStatesA] = useState({
        DemanAI: false,
        DecisionI: false,
        firstversementI: false,


    });


    return (


        <div className="Etude-Admin">
            <div className="Etude-Admin-center">
                {/* Rest of your content */}


                <div className="First-Admin">
                    <h1 className="Deman" id="title1">Étapes pour déposer une demande d'admission</h1>
                    <div className="First-Admin-wrap">

                        <div className="Etapes-Admin">
                            <h1 className="DemanI" id="title1" >Pour faire une demande d'admission à Faza veuillez suivre les 6 étapes suivantes: </h1>

                        </div>

                        <div className="Pieces-frais" >
                            <div className={`DemanII  ${dropdownStatesA.DemanAI ? 'dropdownA-open' : 'dropdownA-closed'}`}>
                                <div className="ADamaWrap" onClick={(e) => handleInformationAClick('DemanAI', e)}>
                                    <h1 className="DemanAI" >Etape 1 : Transmettre les Pieces requises et aquiter les frais d'admission</h1>
                                    <span className={`signAd ${dropdownStatesA.DemanAI ? 'minus-signAd' : 'plus-signAd'}`} >
                                        {dropdownStatesA.DemanAI ? '_' : '+'}
                                    </span>

                                </div>
                                {/* Dropdown content */}

                                <div className={`dropdown-contentA1a ${dropdownStatesA.DemanAI ? 'active' : ''}`}>

                                    <div className="firstversementA">
                                        <h1 className="Info-titleA">Assurez-vous de transmettre toutes les  <Link to="/Pieces-requises"> <span className="Piece2"> pièces requises </span> </Link>et les <Link to="/frais-admission"> <span className="Piece2"> frais d'admission </span> </Link>en personne.</h1>
                                    </div>
                                    <div className="Ligen-Biento">
                                        <h1 className="Info-titleA" id="title1" > <span className="Info-titleL">Admission en ligne Bientot disponible.</span></h1>
                                    </div>

                                </div>
                            </div>

                        </div>



                        <div className="Decision-admin">
                            <div className={`DecisionA  ${dropdownStatesA.DecisionI ? 'dropdownA-open' : 'dropdownA-closed'}`}>
                                <div className="ADamaWrap" onClick={(e) => handleInformationAClick('DecisionI', e)} >
                                    <h1 className="DecisionI" id="title1" > Etape 2 : Recevoir vote décision d'admission.</h1>
                                    <span className={`signAd ${dropdownStatesA.DecisionI ? 'minus-signAd' : 'plus-signAd'}`}  >
                                        {dropdownStatesA.DecisionI ? '_' : '+'}
                                    </span>
                                </div>

                                {/* Dropdown content */}

                                <div className={`dropdown-contentA1a ${dropdownStatesA.DecisionI ? 'active' : ''}`}>

                                    <div to="/Trait-ment">
                                        <Link to="/Traitment-demandes">
                                            <h1 className="Info-T"> Traitement des demandes d’admission</h1>
                                        </Link>
                                        <h1 className="Info-T2"> Pour en connaître davantage sur l'étude de votre demande d'admission, les délais pour recevoir une décision d’admission et les décisions possibles.</h1>

                                    </div>

                                </div>

                            </div>


                        </div>

                        <div className="first-versement" >
                            <div className={`firstversementA  ${dropdownStatesA.firstversementI ? 'dropdownA-open' : 'dropdownA-closed'}`}>
                                <div className="ADamaWrap" onClick={(e) => handleInformationAClick('firstversementI', e)}  >
                                    <h1 className="firstversementI" id="title1" > Etape 3 : Éffectuer le premier versement pour confirmer votre admission.</h1>
                                    <span className={`signAd ${dropdownStatesA.firstversementI ? 'minus-signAd' : 'plus-signAd'}`} >
                                        {dropdownStatesA.firstversementI ? '_' : '+'}
                                    </span>
                                </div>
                            </div>
                            {/* Dropdown content */}

                            <div className={`dropdown-contentA1a ${dropdownStatesA.firstversementI ? 'active' : ''}`}>

                                <div to="/Trait-ment">
                                    <div to="/firstversementA">
                                        <h1 className="Info-titleA">Après avoir reçu une <Link to="/Traitment-demandes"> <span className="Piece2"> lettre de décision </span> </Link> vous revevrez les intructions pour vous inscrire</h1>
                                        <h2 className="Info-titleA">Consultez les dates importantes du <Link to="/evmnt-cal"> <span className="Piece2"> calendrier scolaire</span> </Link> </h2>
                                    </div>


                                </div>

                            </div>

                        </div>










                    </div>


                </div>



            </div>
        </div>





    );

};


export default InfoAdmin;