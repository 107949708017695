import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BlackSectionInfo.scss';
import '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Pages/Info Générale/InformationGeneralePage.scss';

import newarrow2 from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/golden arrow.png';



const BlackSectionI = () => {

    // Toggle dropdown and maintain its state
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleInformationGClick = (event) => {
        if (window.innerWidth <= 991) {
            event.preventDefault();
            // Toggle dropdown and maintain its state
            setDropdownOpen(!dropdownOpen);
            setDropdownStates(prevStates => ({
                ...prevStates,
                InformationG: !prevStates.InformationG

            }));


        }


    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [dropdownStates, setDropdownStates] = useState({
        InformationG: false,
        Lesecoles: false,
        Travail: false,
        JoindreI: false
        // ... other dropdowns if necessary

    });





    ///Delay on the Navigation

    const handleDelayedNavigation = (url, delay) => {
        // Start the closing animation
        const gridContainer2 = document.querySelector('.grid-container2');
        gridContainer2.classList.add('closing');

        setTimeout(() => {
            setIsMenuOpen(false);
            gridContainer2.classList.remove('closing');

            // After the grid slides up, navigate to the new URL
            setTimeout(() => {
                window.location.href = url;
            }, delay); // Delay for the navigation

        }, 340); // Duration of the slide-up animation
    };
    ///Delay on the Navigation /End

    ///Grid


    ///close grid when click outside
    const blackSectionRef = useRef(null);
    const gridRef = useRef(null);
    const titleRef = useRef(null);
    const searchRef = useRef(null);

    // Handler to toggle the grid from the menu icon and black section
    const toggleGrid = (event) => {
        if (!titleRef.current.contains(event.target) &&
            !searchRef.current.contains(event.target)) {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    // ... existing code

    // Close the grid when clicking on the empty surface
    // Handler for closing the grid when clicking outside
    const handleClickOutside = (event) => {
        if (blackSectionRef.current && !blackSectionRef.current.contains(event.target) &&
            gridRef.current && !gridRef.current.contains(event.target) &&
            !(titleRef.current && titleRef.current.contains(event.target)) &&
            !(searchRef.current && searchRef.current.contains(event.target))) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    ///close grid when click outside /End


    ///Grid /End


    const location = useLocation();
    const [selectedRectangle, setSelectedRectangle] = useState(null);
    const [selectedRectanglev, setSelectedRectanglev] = useState(null);
    useEffect(() => {
        // Map the path to a key that represents each title
        const rectangleIndex = {
            '/information-generale': 1,
            '/ecoles-faza': 2,
            '/faza-sonuci': 2,
            '/faza-yantala': 2,
            '/travailler-faza': 3,
            '/JoindreI': 4,
            // Majortitles
            'information-G': 1,
            '/ecoles-faza2': 2,
            '/Faz-Son2': 2,
            '/Faz-Yan2': 2,
            '/travailler-faza2': 3,

        };
        //Map titles from infomrationVerticalePage
        const rectanglevIndex = {
            '/information-generale': 1,
            '/InformationG': 1,
            '/directions-et-service': 2,
            '/direction-services': 2,
            '/histoire-de-faza': 3,
            '/histoire-faza': 3,
            '/faits-et-chiffres': 4,
            '/Fait-Chiff': 4,
            // Majortitles A propos
            '/information-G': 1,
            '/direction-service2': 2,
            '/Histoire-faza2': 3,
            '/Faits-chiffre2': 4,

        };
        // Check if the current path is one of the dropdown items
        const isDropdownItem = Object.keys(rectanglevIndex).includes(location.pathname);

        setSelectedRectangle(rectangleIndex[location.pathname] || 1);
        setSelectedRectanglev(rectanglevIndex[location.pathname] || null);

        // Set the dropdown to open if the current path is a dropdown item
        if (isDropdownItem) {
            setDropdownStates(prevStates => ({
                ...prevStates,
                InformationG: true
            }));
            setDropdownOpen(true); // Ensure dropdownOpen state is also set
        }
    }, [location]);

    ///Search Engine
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = () => {
        window.location.href = `/search?query=${encodeURIComponent(searchTerm)}`;
    };
    ///Search Engine /End

    // New function to handle key press
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };
    return (
        <div className="black-sectionI" ref={blackSectionRef} onClick={toggleGrid}>
            <div className="mobile-menu-icon2" >
                {/* Hamburger Icon */}
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div className="CspFaza2">
                <h1>À propos</h1>
            </div>


            <div className={`grid-container2 ${isMenuOpen ? 'open' : ''}`} ref={gridRef}>


                <div className="header-wrapI" >
                    <div className="header7">
                        <div className="search-bar7" >
                            <input
                                type="text"
                                placeholder="Chercher..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                ref={searchRef}
                                onKeyPress={handleKeyPress}
                            />
                            <i className="fa fa-search search-icon7" onClick={handleSearchSubmit}></i>
                        </div>
                    </div>

                    <div className="Title-wrapperI" >



                        <Link to="/information-generale" ref={titleRef} onClick={handleInformationGClick} >
                            <div className={`InformationG ${selectedRectangle === 1 ? 'selected' : ''} ${dropdownStates.InformationG ? 'dropdown-open' : ''}`}>
                                <h1 className="info-title" ref={titleRef}>Information générale</h1>
                                <span className={`signI ${dropdownStates.InformationG ? 'minus-signI' : 'plus-signI'}`}>
                                    {dropdownStates.InformationG ? '_' : '+'}
                                </span>

                            </div>
                        </Link>


                        <div className="title-lineI"></div>

                        <div className={`dropdown-contentV ${dropdownStates.InformationG ? 'active' : ''}`}>

                            <Link to="/InformationG" onClick={(e) => {
                                e.preventDefault(); // Prevent default link behavior
                                handleDelayedNavigation('/InformationG', 250); // Adjust the delay as needed
                            }}>
                                <div className={`info-rectanglev ${selectedRectanglev === 1 ? 'selectedv' : ''}`}>
                                    <h1 className="info-title">Présentation sommaire</h1>
                                </div>
                            </Link>



                            <Link to="/directions-et-service" onClick={(e) => {
                                e.preventDefault(); // Prevent default link behavior
                                handleDelayedNavigation('/directions-et-service', 250); // Adjust the delay as needed
                            }}>
                                <div className={`info-rectanglev ${selectedRectanglev === 2 ? 'selectedv' : ''}`}>

                                    <h1 className="info-title">Directions et service</h1>
                                </div>
                            </Link >


                            <Link to="/histoire-de-faza" onClick={(e) => {
                                e.preventDefault(); // Prevent default link behavior
                                handleDelayedNavigation('/histoire-de-faza', 250); // Adjust the delay as needed
                            }}>
                                <div className={`info-rectanglev ${selectedRectanglev === 3 ? 'selectedv' : ''}`}>
                                    <h1 className="info-title">Histoire de Faza</h1>
                                </div>
                            </Link>


                            <Link to="/faits-et-chiffres" onClick={(e) => {
                                e.preventDefault(); // Prevent default link behavior
                                handleDelayedNavigation('/faits-et-chiffres', 250); // Adjust the delay as needed
                            }}>
                                <div className={`info-rectanglev ${selectedRectanglev === 4 ? 'selectedv' : ''}`}>
                                    <h1 className="info-title">Faits et chiffres</h1>
                                </div>
                            </Link>
                        </div>




                        <Link to="/ecoles-faza" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigation('/ecoles-faza', 250); // Adjust the delay as needed
                        }}>
                            <div>
                                <div className={`Lesecoles ${selectedRectangle === 2 ? 'selected' : ''}`}>
                                    <h1 className="info-title">Les écoles Faza</h1>
                                </div>
                            </div>
                        </Link>





                        {/* Add similar Link elements for other menu items */}
                        <div className="title-lineI2"></div>

                        <div className={`dropdown-contentV ${dropdownStates.Lesecoles ? 'active' : ''}`}>

                            <Link to="/ecoles-faza">
                                <div>
                                    <div className={`Lesecoles ${selectedRectangle === 2 ? 'selected' : ''}`}>
                                        <h1 className="info-title">Les écoles Faza</h1>
                                    </div>
                                </div>
                            </Link>

                        </div>


                        <Link to="/travailler-faza" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigation('/travailler-faza', 250); // Adjust the delay as needed
                        }}>
                            <div>
                                <div className={`Travail ${selectedRectangle === 3 ? 'selected' : ''}`}>
                                    <h1 className="info-title">Travail </h1>
                                </div>
                            </div>
                        </Link>
                        <div className="title-lineI3"></div>
                        <div className={`dropdown-contentV ${dropdownStates.Travail ? 'active' : ''}`}>
                            <Link to="/travailler-faza">
                                <div>
                                    <div className={`Travail ${selectedRectangle === 3 ? 'selected' : ''}`}>
                                        <h1 className="info-title">Travail </h1>
                                    </div>
                                </div>
                            </Link>
                        </div>


                        <Link to="/JoindreI" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigation('/JoindreI', 250); // Adjust the delay as needed
                        }}>
                            <div>
                                <div className={`JoindreI ${selectedRectangle === 4 ? 'selected' : ''}`}>
                                    <h1 className="info-title">Nous Joindre </h1>
                                </div>
                            </div>
                        </Link>

                        <div className={`dropdown-contentV ${dropdownStates.JoindreI ? 'active' : ''}`}>

                            <Link to="/JoindreI">
                                <div>
                                    <div className={`JoindreI ${selectedRectangle === 4 ? 'selected' : ''}`}>
                                        <h1 className="info-title">Nous Joindre </h1>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <img src={newarrow2} alt="Golden Arrow" className="D-arrow2" onClick={toggleGrid} />
            </div>
        </div>

    );
};

export default BlackSectionI;

