import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './InfoVerticalePageX.scss';

const InfoVerticalePageR = () => {
    const location = useLocation();
    const [selectedRectangleA, setSelectedRectangleA] = useState(null);

    useEffect(() => {
        // Function to map the path to rectangle index
        const rectangleAIndex = {

            'Ploma': 1,
            '/Akss': 2,
            '/Akks-bil': 2,
            '/ConfX': 3,
            '/Cons-fidd': 3,
            '/AutrX': 4,
            '/Autr-2X': 4,



        };

        // Set the selected rectangle based on the current path
        setSelectedRectangleA(rectangleAIndex[location.pathname] || 1);
    }, [location]);


    return (
        <div className="info-verticale-pageR">
            <div className="info-verticaleBackR">
                <div className="info-verticale-wrapR">
                    <Link to="/Diplo-mey">
                        <div className={`info-rectangleA ${selectedRectangleA === 1 ? 'selectedA' : ''}`}>
                            <h1 className="info-titleA">Diplômes</h1>
                        </div>
                    </Link>

                    <Link to="/Akks-bil">
                        <div className={`info-rectangleA ${selectedRectangleA === 2 ? 'selectedA' : ''}`}>

                            <h1 className="info-titleA">Accessibilité</h1>
                        </div>






                    </Link >

                    <Link to="/Cons-fidd">
                        <div className={`info-rectangleA ${selectedRectangleA === 3 ? 'selectedA' : ''}`}>
                            <h1 className="info-titleA">Confidentialité</h1>
                        </div>
                    </Link>

                    {/* Débloquer au besoin
                    <Link to="/Autr-2X">
                        <div className={`info-rectangleA ${selectedRectangleA === 4 ? 'selectedA' : ''}`}>
                            <h1 className="info-titleA">Autre</h1>
                        </div>
                    </Link>
                     */}
                </div >
            </div >
        </div>
    );
};

export default InfoVerticalePageR;
