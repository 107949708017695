import React, { useState, useEffect } from 'react';

import './InfoCal.scss';

const InfoCal = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesA(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesA, setDropdownStatesA] = useState({
        CalAI: false,
        CalA2I: false,



    });


    return (


        <div className="Etude-Cal">
            <div className="Etude-Cal-center">
                {/* Rest of your content */}


                <div className="First-Cal">
                    <h1 className="Cal" id="title1">Calendrier scolaire </h1>
                    <div className="First-Cal-wrap">

                        <div className="Etapes-Cal">
                            <h1 className="CalI" id="title1" >Dates à retenir année scolaire 2024 :  </h1>

                        </div>
                        <div className="Drop-Cal-wrap">
                            <div className="Pieces-Cal" >
                                <div className={`CalII  ${dropdownStatesA.CalAI ? 'dropdownCA-open' : 'dropdownCA-closed'}`}>
                                    <div className="ACalWrap" onClick={(e) => handleInformationAClick('CalAI', e)} >
                                        <h1 className="CalAI" > Premier trimestre </h1>
                                        <span className={`signCAL ${dropdownStatesA.CalAI ? 'minus-signCAL' : 'plus-signCAL'}`} >
                                            {dropdownStatesA.CalAI ? '_' : '+'}
                                        </span>

                                    </div>



                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentCAL ${dropdownStatesA.CalAI ? 'active' : ''}`}>

                                        <div className="firstSemester">
                                            <h1 className="Info-titleA">Bientôt disponible.</h1>
                                        </div>


                                    </div>
                                </div>

                            </div>



                            <div className="Pieces-Cal2">
                                <div className={`Cal2II  ${dropdownStatesA.CalA2I ? 'dropdownCA-open' : 'dropdownCA-closed'}`}>
                                    <div className="ACalWrap " onClick={(e) => handleInformationAClick('CalA2I', e)}>
                                        <h1 className="CalA2I" id="title1" > Deuxième trimestre</h1>


                                        <span className={`signCAL ${dropdownStatesA.CalA2I ? 'minus-signCAL' : 'plus-signCAL'}`} >
                                            {dropdownStatesA.CalA2I ? '_' : '+'}
                                        </span>

                                    </div>

                                    <div className={`dropdown-contentCAL ${dropdownStatesA.CalA2I ? 'active' : ''}`}>

                                        <div className="SecondSemester">
                                            <h1 className="Info-titleA">Bientôt disponible.</h1>

                                        </div>

                                    </div>

                                </div>


                            </div>


                        </div>







                    </div>


                </div>



            </div>
        </div >





    );

};


export default InfoCal;