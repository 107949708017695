import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './InfoVerticalePage.scss';

const InfoVerticalePage = () => {


    const location = useLocation();
    const [selectedRectanglev, setSelectedRectanglev] = useState(null);

    useEffect(() => {
        // Function to map the path to rectangle index
        const rectanglevIndex = {
            '/InformationG': 1,
            '/directions-et-service': 2,
            '/direction-services': 2,
            '/histoire-de-faza': 3,
            '/histoire-faza': 3,
            '/faits-et-chiffres': 4,
            '/Fait-Chiff': 4,
            // Majortitles A propos
            '/information-G': 1,
            '/direction-service2': 2,
            '/Histoire-faza2': 3,
            '/Faits-chiffre2': 4,
            '/information-generale': 1,
        };

        // Set the selected rectangle based on the current path
        setSelectedRectanglev(rectanglevIndex[location.pathname] || 1);

    }, [location]);

    return (
        <div className="info-verticale-page">
            <div className="info-verticaleBackI">
                <div className="info-verticale-wrapI">
                    <Link to="/InformationG">
                        <div className={`info-rectanglev ${selectedRectanglev === 1 ? 'selectedv' : ''}`}>
                            <h1 className="info-title-I">Présentation sommaire</h1>
                        </div>
                    </Link>

                    <Link to="/directions-et-service">
                        <div className={`info-rectanglev ${selectedRectanglev === 2 ? 'selectedv' : ''}`}>

                            <h1 className="info-title-I">Directions et service</h1>
                        </div>
                    </Link >

                    <Link to="/histoire-de-faza">
                        <div className={`info-rectanglev ${selectedRectanglev === 3 ? 'selectedv' : ''}`}>
                            <h1 className="info-title-I">Histoire de Faza</h1>
                        </div>
                    </Link>

                    <Link to="/faits-et-chiffres">
                        <div className={`info-rectanglev ${selectedRectanglev === 4 ? 'selectedv' : ''}`}>
                            <h1 className="info-title-I">Faits et chiffres</h1>
                        </div>
                    </Link>
                </div >
            </div >
        </div >
    );
};

export default InfoVerticalePage;
