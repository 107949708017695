import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import HeaderA from './HeaderA';
import BlackSectionA from './BlackSectionA';
import './Bibliotheque.scss';
import FooterA from './FooterA';
import InfoBib from './InfoBibPage/InfoBib';
const Bibliotheque = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location
    return (
        <div>
            <HeaderA />
            <div className="Grey-section-Bib">

                <div className="Grey-wrap-Bib">
                    <div className="Grey-center-Bib">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaBib-wrap">
                                <h1 className="Faza-Bib" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Bibarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposBib-wrap">
                                <h1 className="AproposBib" id="titleA">
                                    Étudiants
                                </h1>

                                <h2 className="BibarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AABib" id="titleA">
                            Bibliothèque
                        </h1>

                    </div>
                </div>

            </div>
            <BlackSectionA />

            <div className="infov-main">
                <h1 className="A1Bib" id="titleA">
                    <span class="bold-text">Bibliothèque</span>
                </h1>

                <div className="infov-Bib">
                    <div className="infov-Border">



                        <InfoBib />
                    </div>
                </div>
            </div>





            <FooterA />
        </div >
    );
};

export default Bibliotheque;