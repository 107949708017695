import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeaderE from './HeaderE';
import BlackSectionE from './BlackSectionE';
import './Fraisadmission.scss';
import Infofrais from './FraisAdmissionPage/Infofrais';
import ModernFooter from './ModernFooter';
import InfoVerticalePageET from './InfoVerticalePageET';

const FraisAdmin = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location
    return (
        <div>

            <HeaderE />

            <div className="Grey-section-Admin">

                <div className="Grey-wrap-Admin">
                    <div className="Grey-center-Admin">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaAdmin-wrap">
                                <h1 className="Faza-Admin" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Adminarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposAdmin-wrap">
                                <h1 className="AproposFrias" id="titleA">
                                    Étudier à Faza
                                </h1>

                                <h2 className="AdminarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AAFrais" id="titleA">
                            Frais d'admission
                        </h1>

                    </div>
                </div>

            </div>



            <BlackSectionE />
            <div className="infov-main">
                <h1 className="A1dmin" id="titleA">
                    Faire une <span class="bold-text">demande d'admission</span>
                </h1>

                <div className="infov-Frias">
                    <div className="infov-Border">

                        <InfoVerticalePageET />
                        <Infofrais />
                    </div>
                </div>
            </div>
            <ModernFooter />

        </div>
    );
};

export default FraisAdmin;