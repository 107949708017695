import React, { useState, useEffect } from 'react';

import './InfoPiece.scss';

const InfoPiece = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesP(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesP, setDropdownStatesP] = useState({
        identitéAI: false,
        identitéAI2: false,



    });


    return (


        <div className="Etude-Piece">
            <div className="Etude-Piece-center">
                {/* Rest of your content */}


                <div className="First-Piece">
                    <div className="main-Piece">
                        <h1 className="Piec" id="title1">Pièces requises</h1>

                        <div className="Piece-line"></div>
                    </div>
                    <div className="First-Piece-wrap">

                        <div className="Etapes-Piece">
                            <h1 className="PieceI" id="title1" >Il est dans l’intérêt du candidat qui présente une demande d’admission de fournir toutes les pièces requises lors du dépôt de la demande d’admission. Aussi, même si vous avez déjà un dossier à Faza, il est important de nous fournir des pièces récentes afin de maintenir votre dossier à jour. Le Registrariat - Admission ne peut tenir compte des pièces reçues une fois la décision d’admission émise. </h1>
                            <h1 className="PieceII" id="title2" >
                                Toutes les pièces fournies lors d’une demande d’admission demeurent la propriété de Faza et ne sont pas rendues aux candidats..</h1>

                        </div>

                        <div className="Etapes-Piece2">

                            <h1 className=" Etp" id="title2">Demande d'admission en version imprimée</h1>
                            <h2 className="EtpI" id="title2" >
                                Les candidats qui ne peuvent compléter une demande d’admission en ligne peuvent utiliser le formulaire d'admission imprimé.</h2>

                            <h3 className="EtpII" id="title2" >
                                Vous devez nous faire parvenir les pièces requises par la poste ou en personne. Toutes pièces envoyées sous pli séparé doivent être accompagnées de la fiche d'identification personnelle.   </h3>

                            <h4 className="EtpIII" id="title2" >
                                Elles doivent être expédiées au plus tard dix jours après avoir transmis votre demande.   </h4>


                        </div>

                        <div className="Drop-Piece-wrap">


                            <div className="First-Piece2">
                                <h1 className="Pie-req" id="title1">Pièces requises lors du dépôt de la demande d'admission</h1>
                            </div>


                            <div className="Pieces-identité" >
                                <div className={`identitéI  ${dropdownStatesP.identitéAI ? 'dropdownP-open' : 'dropdownP-closed'}`}>
                                    <div className="APiecWrap" onClick={(e) => handleInformationAClick('identitéAI', e)}>
                                        <h1 className="identitéAI">Transmettre les Pieces requises et aquiter les frais d'admission</h1>
                                        <span className={`signAP ${dropdownStatesP.identitéAI ? 'minus-signAP' : 'plus-signAP'}`} >
                                            {dropdownStatesP.identitéAI ? '_' : '+'}
                                        </span>

                                    </div>
                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentP ${dropdownStatesP.identitéAI ? 'active' : ''}`}>

                                        <div to="/identité-Piece">

                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th >Votre situation</th>
                                                        <th>Document(s) à transmettre</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> <h1>Citoyens nigériens</h1></td>
                                                        <td>
                                                            <li>
                                                                Photocopie du certificat de naissance avec les noms des parents. Si l'identité parentale n'est pas mentionnée dans le document officiel présenté, celui-ci devra être accompagné d'une déclaration solennelle.
                                                            </li>
                                                            <li>
                                                                Photocopie (recto verso) de votre certificat de nationalité  nigérienne
                                                            </li>


                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td> <h1>Citoyens étrangers</h1></td>
                                                        <td>
                                                            <li>
                                                                Visa d'étudiant
                                                            </li>
                                                            <li>
                                                                Photocopie du certificat de naissance avec les noms des parents. Si l'identité parentale n'est pas mentionnée dans le document officiel présenté, celui-ci devra être accompagné d'une déclaration solennelle.
                                                            </li>
                                                            <li>
                                                                Photocopie (recto verso) de votre certificat de nationalité
                                                            </li>

                                                            <li>
                                                                Photocopie du passeport valide
                                                            </li>

                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>



                                    </div>
                                </div>

                            </div>


                            <div className="Pieces-identité2" >
                                <div className={`identitéI2  ${dropdownStatesP.identitéAI2 ? 'dropdownP-open' : 'dropdownP-closed'}`}>
                                    <div className="APiecWrap" onClick={(e) => handleInformationAClick('identitéAI2', e)}>
                                        <h1 className="identitéAI2" >Relevé de notes, diplômes et attestation</h1>
                                        <span className={`signAP ${dropdownStatesP.identitéAI2 ? 'minus-signAP' : 'plus-signAP'}`} >
                                            {dropdownStatesP.identitéAI2 ? '_' : '+'}
                                        </span>

                                    </div>


                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentP ${dropdownStatesP.identitéAI2 ? 'active' : ''}`}>

                                        <div to="/identité-Piece2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="Section">Sections</th>
                                                        <th>Relevé de notes, diplômes et/ou attestation</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> <h1>Primaire et collège</h1></td>
                                                        <td>
                                                            <li>
                                                                Tous les relevés de notes des classes précedentes.
                                                            </li>



                                                        </td>

                                                    </tr>

                                                    <tr>
                                                        <td> <h1>Lycée</h1></td>
                                                        <td>
                                                            <li>
                                                                Certificat de BEPC
                                                            </li>
                                                            <li>
                                                                Tous les relevés de notes des classes précedentes.
                                                            </li>


                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>


                                    </div>
                                </div>

                            </div>


                        </div>





                        <div className="Etapes-Piece3">
                            <div className="Piece-line2"></div>
                            <h1 className=" Etpi" id="title2">Traduction des documents</h1>
                            <h2 className="EtpiI" id="title2" >
                                Une traduction française ou anglaise certifiée par le consulat, l’ambassade du pays d’origine ou l’Ordre des traducteurs, terminologues et interprètes agréés du Québec (OTTIAQ) doit accompagner toutes les pièces que vous devez fournir, si elles sont rédigées dans une langue autre que le français ou l’anglais. Une traduction seule ne peut être acceptée, elle doit toujours être accompagnée du document en langue originale.</h2>




                        </div>




                    </div>



                </div>



            </div>
        </div >





    );

};


export default InfoPiece;