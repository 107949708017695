import React, { useState, useEffect } from 'react';
import './InfoCode.scss';

const InfoCode = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesA(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesA, setDropdownStatesA] = useState({
        CodAI: false,
        CodAI2: false,



    });


    return (


        <div className="Etude-Cod">
            <div className="Etude-Cod-center">
                {/* Rest of your content */}


                <div className="First-Cod">
                    <h1 className="Cod" id="title1">Code vestimentaire</h1>
                    <div className="First-Cod-wrap">

                        <div className="Etapes-Cod">
                            <h1 className="CodI" id="title1" >Notez que le code vestimentaire doit être respecté dès le premier jour de la rentrée scolaire et ce, jusqu’au dernier jour des examens. Merci </h1>

                        </div>
                        <div className="Drop-Cod-wrap">
                            <div className="Pieces-Cod" >
                                <div className={`CodII  ${dropdownStatesA.CodAI ? 'dropdownCo-open' : 'dropdownCo-closed'}`}>
                                    <div className="ACodWrap" onClick={(e) => handleInformationAClick('CodAI', e)} >
                                        <h1 className="CodAI"> Sont autorisés </h1>

                                        <span className={`signCod ${dropdownStatesA.CodAI ? 'minus-signCod' : 'plus-signCod'}`} >
                                            {dropdownStatesA.CodAI ? '_' : '+'}
                                        </span>

                                    </div>

                                    <div className={`dropdown-contentCoD ${dropdownStatesA.CodAI ? 'active' : ''}`}>

                                        <div className="firstCode">
                                            <h1 className="Info-titleCo"> <li>
                                                Polo de type classique porté aux hanches, manches courtes ou longues;
                                            </li>
                                                Chemise ou blouse avec col, manches courtes ou longues, boutonnée convenablement et à l’intérieur de la jupe ou du pantalon;
                                                <li>
                                                    Pantalon de ville de couleur neutre;
                                                </li>
                                                Bermuda de style « golf », robe avec col boutonné convenablement ou jupe, portés sous les genou;
                                                Chaussettes ou collants opaques;


                                                <li>
                                                    Chaussures de cuir fermées, non marquantes, de couleur noire, brune ou marine, dont le talon ou la semelle ne dépasse pas 5 cm de hauteur.
                                                </li>
                                            </h1>
                                        </div>


                                    </div>
                                </div>

                            </div>



                            <div className="Pieces-Cod2">
                                <div className={`CodII  ${dropdownStatesA.CodAI2 ? 'dropdownCo-open' : 'dropdownCo-closed'}`}>
                                    <div className="ACodWrap" onClick={(e) => handleInformationAClick('CodAI2', e)}>
                                        <h1 className="CodAI2" id="title1" > Ne sont pas autorisés</h1>


                                        <span className={`signCod ${dropdownStatesA.CodAI2 ? 'minus-signCod' : 'plus-signCod'}`} >
                                            {dropdownStatesA.CodAI2 ? '_' : '+'}
                                        </span>

                                    </div>
                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentCoD ${dropdownStatesA.CodAI2 ? 'active' : ''}`}>

                                        <div className="firstCode2">
                                            <h1 className="Info-titleCo">
                                                <li>
                                                    Les vêtements troués ou effilochés;
                                                </li>
                                                Les vêtements qui découvrent les épaules, le ventre, le dos et les cuisses;
                                                <li>
                                                    Les casquettes, chapeaux, bonnets;
                                                </li>
                                                Les motifs de type « camouflage »;
                                                <li>
                                                    Tout vêtement ou accessoire faisant la promotion de l’alcool, de la drogue et de la pornographie, ou incitant à la violence, au racisme, au sexisme, etc.
                                                </li>
                                                <li>
                                                    Les claquettes, et autres chaussures ouvertes
                                                </li>
                                            </h1>

                                        </div>

                                    </div>

                                </div>


                            </div>


                        </div>







                    </div>


                </div>



            </div>
        </div >





    );

};


export default InfoCode;