// BottomImage.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './BottomImage.scss';
import background1 from './Images/web.png';
import overlay1_1 from './Images/Girl 1.png';

import background2 from './Images/web2.png';
import overlay2_1 from './Images/Girl 2.png';

// Import more images as needed

const imageSets = [
    {
        bgImage: background1,
        text: "Faites votre demande d'admission!",
        text2: "Consultez les dates d'admission!",
        overlayImages: [overlay1_1],

    },
    {
        bgImage: background2,
        text3: 'Les inscriptions sont ouvertes!',
        text4: "Consultez les dates d'inscrpritions!",
        overlayImages: [overlay2_1],

    },
    // Add more sets as needed
];


const BottomImage = () => {

    const [selectedSet, setSelectedSet] = useState({});

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * imageSets.length);
        setSelectedSet(imageSets[randomIndex]);
    }, []);

    return (
        <div className="background" style={{ backgroundImage: `url(${selectedSet.bgImage})` }}>
            <div className="content">
                <div className="content-text">
                    <Link to="/demande-admin">
                        <p>{selectedSet.text}</p>
                    </Link>
                    <Link to="/demande-admin">
                        <h1>{selectedSet.text2}</h1>
                    </Link>

                    <Link to="/Ins-cirp">
                        <h2>{selectedSet.text3}</h2>
                    </Link>
                    <Link to="/Ins-cirp">
                        <h3>{selectedSet.text4}</h3>
                    </Link>

                </div>
                <div className="content-imgs">
                    {selectedSet.overlayImages?.map((image, index) => (
                        <img key={index} src={image} alt={`Overlay ${index}`} className="overlay-image" />
                    ))}
                </div>
            </div>
        </div >
    );
}



export default BottomImage;