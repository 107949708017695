import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation

import './JoindreJ.scss';
import HeaderJ from './HeaderJ';
import BlackSectionJ from './BlackSectionJ';
import InfoJoinA from './NousJoindrePageA/InfoJoinA';
import ModernFooter from './ModernFooter';
// import InfoVerticalePage from './InfoVerticalePage';

const Joindre = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const location = useLocation(); // Get the current location





    return (
        <div>
            <HeaderJ />

            <div className="Grey-section-Join">
                <div className="Grey-wrapJoin">
                    <div className="Grey-centerJoin">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaJoin-wrap">
                                <h1 className="FazaJoin" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>

                                <h2 className="Joinarrow" id="title2">{'>'} </h2>
                            </div>
                        </Link>


                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposJoin-wrap">

                                <h1 className="ApropoJoinA" id="titleA">
                                    Nous joindre
                                </h1>

                                <h2 className="JoinarowA" id="titleA2">{'>'}  </h2>
                            </div>
                        </Link>



                        <h1 className="Joinirect" id="titleA">
                            Nous joindre
                        </h1>
                    </div>

                </div>
            </div >

            <BlackSectionJ />

            {/* Rest of your content */}

            <div className="infov-Join">
                <div className="infov-Join-center">
                    <InfoJoinA />
                </div>

            </div>


            {/*<InfoVerticalePage />*/}
            <ModernFooter />

        </div>

    );
};

export default Joindre;