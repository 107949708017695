import React, { useEffect } from 'react';

import './InfoAli.scss';
import logom from './Images/Subject.png'; // Update the path accordingly

const InfoAli = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);





    return (


        <div className="Etude-Al">
            <div className="Etude-Al-center">
                {/* Rest of your content */}


                <div className="First-Al">
                    <h1 className="DemanAl" id="title1">Cette page sera bientôt disponible</h1>
                    <img className="new-logom" src={logom} alt="" />


                </div>


            </div>
        </div>





    );

};


export default InfoAli;