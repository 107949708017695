import React, { useState, useEffect } from 'react';


import './InfoAcess.scss';


const InfoAcess = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesA(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesA, setDropdownStatesA] = useState({
        ACAI: false,
        ACA2I: false,



    });





    return (


        <div className="Etude-AC">
            <div className="Etude-AC-center">


                <div className="First-AC">
                    <h1 className="DemanAC" id="title1">Accessibilité du site Web de Faza</h1>

                    <div className="AC-line"></div>
                    <div className="First-AC-wrap">

                        <div className="Etapes-AC">
                            <h1 className="DemanAC2" id="title1" >Définition de l’accessibilité Web</h1>

                        </div>

                    </div>
                    <div className="First-AC2">

                        <h1 className="ACIs" id="title2">L’accessibilité Web désigne un ensemble de normes et de bonnes pratiques afin de rendre le Web accessible à tous les utilisateurs, quel que soit leur matériel ou logiciel, leur infrastructure réseau, leur langue maternelle, leur culture, leur localisation géographique, leur âge ou leurs aptitudes physiques ou mentales.</h1>
                        <h1 className="ACIsI" id="title2" >
                            <li> L’accessibilité Web permet donc d’offrir un accès sans discrimination aux utilisateurs, peu importe les technologies utilisées pour accéder à une information. Par exemple, les personnes non voyantes doivent pouvoir naviguer sur un site Web accessible sur lequel les logiciels de synthèse vocale peuvent interpréter le contenu informationnel des pages Web.
                            </li>

                            <li>
                                Le W3C, World Wide Web Consortium, est une communauté internationale à but non lucratif de standardisation des technologies liées au Web. Cette communauté promeut et développe des protocoles et des directives permettant la compatibilité des technologies et une croissance à long terme du Web pour « Un seul web partout et pour tous ». Le W3C supervise un ensemble de standards, dont l’accessibilité (WCAG 2.0 et UAAG 2.0), qui compte trois niveaux (A, AA et AAA).
                            </li>

                            <li>
                                Si l’étudiante, l’étudiant ou le groupe d’étudiants n’est toujours pas satisfait du traitement de sa plainte, il peut se référer à la direction .
                            </li>

                        </h1>


                    </div>
                    <div className="AC-line2"></div>
                </div>
                <div className="First-ACF">
                    <div className="Etapes-AC2">

                        <h1 className="ACFI" id="title2">Faza porte une attention particulière au respect des normes d’accessibilité dans la production des sites Web de l’université.</h1>
                    </div>
                    <div className="Drop-AC-wrap">
                        <div className="Pieces-AC" >
                            <div className={`ACII  ${dropdownStatesA.ACAI ? 'dropdownACC-open' : 'dropdownACC-closed'}`}>
                                <div className="AACWrap" onClick={(e) => handleInformationAClick('ACAI', e)}>

                                    <h1 className="ACAI"> Normes respectées pour la conception fonctionnelle et graphique
                                    </h1>


                                    <span className={`signAC ${dropdownStatesA.ACAI ? 'minus-signAC' : 'plus-signAC'}`} >
                                        {dropdownStatesA.ACAI ? '_' : '+'}
                                    </span>

                                </div>

                                {/* Dropdown content */}

                                <div className={`dropdown-contentAC ${dropdownStatesA.ACAI ? 'active' : ''}`}>

                                    <div className="firstAC">


                                        <h1 className="Info-titleAC">

                                            <h1 className="ACFI2" id="title1" >Navigation </h1>
                                            <ul>
                                                <li>
                                                    Respect des contrastes de couleur
                                                </li>
                                                <li>
                                                    Prévoir un fil d’Ariane
                                                </li>
                                                <li>
                                                    Prévoir au moins deux (2) moyens de navigation parmi un menu principal, un plan du site et un moteur de recherche
                                                </li>
                                                <li>
                                                    Différencier visuellement la position courante dans les menus
                                                </li>
                                                <li>
                                                    Assurer la cohérence visuelle de la navigation
                                                </li>

                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Textes et symboles </h1>

                                            <ul>
                                                <li>
                                                    Conserver les accents sur les lettres capitales
                                                </li>
                                                <li>
                                                    Ne pas justifier le texte
                                                </li>
                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Couleurs </h1>
                                            <ul>
                                                <li>
                                                    Assurer un contraste suffisant entre les textes et l’arrière plan ou proposer une alternative contrastée
                                                </li>
                                                <li>
                                                    Assurer la compréhension de l’information même en l’absence de couleurs
                                                </li>
                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Liens </h1>
                                            <ul>
                                                <li>
                                                    Prévoir un intitulé explicite pour chaque lien
                                                </li>
                                                <li>
                                                    Différencier visuellement les liens présents dans du texte
                                                </li>
                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Tableaux </h1>
                                            <ul>
                                                <li>
                                                    Prévoir un titre pour chaque tableau de données
                                                </li>
                                                <li>
                                                    Ne pas utiliser de balises ou d’attributs propres aux tableaux de données dans les tableaux de mise en forme
                                                </li>
                                                <li>
                                                    veiller à l’ordre de lecture des tableaux de mise en forme
                                                </li>
                                            </ul>





                                            <h1 className="ACFI2" id="title1" >Formulaires </h1>
                                            <ul>
                                                <li>
                                                    Prévoir un intitulé explicite pour chaque champ de formulaire
                                                </li>
                                                <li>
                                                    Prévoir des intitulés identiques pour les champs dont la fonction est identique
                                                </li>
                                                <li>
                                                    Positionner chaque intitulé à proximité de son champ
                                                </li>
                                                <li>
                                                    Positionner un bouton de soumission à la fin de chaque formulaire
                                                </li>
                                                <li>
                                                    Prévoir un intitulé explicite pour chaque bouton de formulaire
                                                </li>

                                                <li>
                                                    Indiquer clairement les champs obligatoires
                                                </li>
                                                <li>
                                                    Prévoir des messages d’erreur explicites et des suggestions de correction
                                                </li>
                                                <li>
                                                    Prévoir un message de confirmation
                                                </li>
                                                <li>
                                                    Permettre à l’utilisateur de revenir en arrière sur les formulaires à étapes multiples
                                                </li>
                                                <li>
                                                    Utiliser la balise &lt;label&gt; ainsi que les attributs for et id pour étiqueter les champs avec intitulé visible
                                                </li>

                                                <li>
                                                    Utiliser title ou aria-label pour étiqueter les champs sans intitulé visible
                                                </li>
                                                <li>
                                                    Mettre à jour le &lt;title&gt; de la page quand celle-ci se recharge pour afficher une erreur ou un message de confirmation
                                                </li>

                                                <li>
                                                    Regrouper et titrer les champs de même nature avec &lt;fieldset&gt; et &lt;legend&gt;
                                                </li>
                                                <li>
                                                    Ordonner les options de manière logique dans les listes déroulantes
                                                </li>
                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Multimédia </h1>
                                            <ul>
                                                <li>
                                                    Prévoir des moyens pour contrôler l’avancement et le volume sonore de chaque vidéo et contenu audio
                                                </li>
                                                <li>
                                                    Prévoir un moyen pour stopper chaque contenu animé
                                                </li>
                                                <li>
                                                    Ne pas lancer de son automatiquement
                                                </li>

                                                <li>
                                                    Normes respectées pour l’intégration technique HTML, CSS et JavaScript
                                                </li>
                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Structure générale </h1>
                                            <ul>
                                                <li>
                                                    Structurer les menus de navigation avec des listes
                                                </li>
                                                <li>
                                                    Mettre en place une hiérarchie de titres logique et exhaustive avec les balises &lt;h1&gt;  à &lt;h6&gt;
                                                </li>

                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Titre de la page </h1>
                                            <ul>
                                                <li>
                                                    Renseigner un &lt;title&gt; précis sur chaque page
                                                </li>

                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Langues</h1>
                                            <ul>

                                                <li>
                                                    Renseigner la langue principale de la page avec l’attribut lang sur la balise&lt;html&gt;
                                                </li>
                                                <li>
                                                    Utiliser l’attribut lang pour signaler les changements de langue
                                                </li>

                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Grammaire HTML et sémantique</h1>
                                            <ul>

                                                <li>
                                                    Écrire un code HTML valide selon les règles de grammaire du DOCTYPE utilisé;
                                                </li>
                                                <li>
                                                    Employer les balises HTML pour leur valeur sémantique
                                                </li>

                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Liens et boutons</h1>
                                            <ul>

                                                <li>
                                                    Différencier les boutons des liens;
                                                </li>
                                                <li>
                                                    Compléter les liens et les boutons non explicites avec du texte caché, aria-label ou title
                                                </li>
                                                <li>
                                                    Ne pas utiliser les attributs title et aria-label sur des liens ou boutons explicites
                                                </li>

                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Images et icônes</h1>
                                            <ul>

                                                <li>
                                                    svg (images vectorielles);
                                                </li>
                                                <li>
                                                    Balises  &lt;img /&gt;
                                                </li>


                                            </ul>


                                            <h1 className="ACFI2" id="title1" >Listes</h1>
                                            <ul>

                                                <li>
                                                    Baliser les listes non ordonnées avec &lt;ul&gt; et &lt;li&gt;;
                                                </li>
                                                <li>
                                                    Baliser les listes non ordonnées avec &lt;ol&gt; et &lt;li&gt;;
                                                </li>

                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Usage des CSS </h1>
                                            <ul>
                                                <li>
                                                    Utiliser CSS pour mettre en forme les textes
                                                </li>
                                                <li>
                                                    Utiliser uniquement des tailles relatives (em, %, small, etc.) pour les polices de caractères
                                                </li>
                                                <li>
                                                    Garantir la lisibilité des contenus même lorsque la taille du texte est doublée
                                                </li>

                                                <li>
                                                    Garantir la lisibilité des contenus lorsque les images ne sont pas affichées
                                                </li>
                                                <li>
                                                    Garantir la compréhension de l’information
                                                </li>
                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Navigation au clavier </h1>
                                            <ul>
                                                <li>
                                                    Veiller à ce que l’ordre de tabulation suive la logique de l’ordre de lecture
                                                </li>
                                                <li>
                                                    Garantir la visibilité de la prise de focus au clavier
                                                </li>


                                            </ul>
                                        </h1>


                                    </div>


                                </div>
                            </div>

                        </div>



                        <div className="Pieces-AC2">
                            <div className={`AC2II  ${dropdownStatesA.ACA2I ? 'dropdownACC-open' : 'dropdownACC-closed'}`}>
                                <div className="AACWrap" onClick={(e) => handleInformationAClick('ACA2I', e)}>
                                    <h1 className="ACA2I" id="title1" >Normes respectées pour l’accessibilité éditoriale</h1>


                                    <span className={`signAC ${dropdownStatesA.ACA2I ? 'minus-signAC' : 'plus-signAC'}`} >
                                        {dropdownStatesA.ACA2I ? '_' : '+'}
                                    </span>

                                </div>
                                {/* Dropdown content */}

                                <div className={`dropdown-contentAC ${dropdownStatesA.ACA2I ? 'active' : ''}`}>

                                    <div className="SecondAC">
                                        <h1 className="Info-titleAC">
                                            <h1 className="ACFI2" id="title1" >Mettre en forme les contenus de manière accessible </h1>
                                            <ul>
                                                <li>
                                                    Ne pas justifier le texte
                                                </li>
                                                <li>
                                                    Éviter les sauts de lignes multiples
                                                </li>

                                            </ul>

                                            <h1 className="ACFI2I" id="title1" >Utiliser correctement la hiérarchie des titres</h1>

                                            <h1 className="ACFI2" id="title1" >Écrire des liens de manière accessible</h1>

                                            <ul>
                                                <li>
                                                    Rédiger des intitulés de liens explicites
                                                </li>
                                                <li>
                                                    Savoir utiliser les titres de liens, en dernier recours
                                                </li>


                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Utiliser les images de manière accessible</h1>

                                            <ul>
                                                <li>
                                                    Images décoratives/illustratives : ne pas rédiger de texte de remplacement
                                                </li>
                                                <li>
                                                    Images informatives : rédiger un texte de remplacement
                                                </li>

                                                <li>
                                                    Images-liens : rédiger un texte de remplacement qui décrit la fonction du lien
                                                </li>
                                                <li>
                                                    Images informatives complexes : rédiger un court texte de remplacement ainsi qu’une description détaillée
                                                </li>

                                            </ul>

                                            <h1 className="ACFI2I" id="title1" >Utiliser correctement les listes à puces et listes numérotées</h1>

                                            <h1 className="ACFI2" id="title1" >Écrire les contenus de manière accessible</h1>

                                            <ul>
                                                <li>
                                                    Conserver les accents sur les lettres capitales
                                                </li>
                                                <li>
                                                    Expliciter les abréviations
                                                </li>

                                                <li>
                                                    Déclarer les citations en ligne et les blocs de citations
                                                </li>
                                                <li>
                                                    Ne pas faire référence à un élément en se basant uniquement sur sa couleur, sa forme ou sa position
                                                </li>

                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Utiliser correctement les couleurs et les contrastes</h1>

                                            <ul>
                                                <li>
                                                    Veiller à ce que les contrastes entre le texte et la couleur d’arrière-plan soient suffisants
                                                </li>
                                                <li>
                                                    Veiller à ce que les couleurs ne soient pas le seul moyen pour véhiculer de l’information
                                                </li>


                                            </ul>

                                            <h1 className="ACFI2" id="title1" >Utiliser correctement les contenus riches et multimédias</h1>

                                            <ul>
                                                <li>
                                                    Vérifier la possibilité de mettre en pause les contenus en mouvement
                                                </li>


                                            </ul>


                                        </h1>
                                    </div>

                                </div>

                            </div>


                        </div>


                    </div>
                </div>

                <div className="AC-line3"></div>






            </div>
        </div>





    );

};


export default InfoAcess;