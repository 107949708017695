// SocialMediaSection.js

import React from 'react';
import './SocialMediaSection.scss'; // Make sure to adjust the path if needed

import social1Image from './Images/IMG_0271.png';
import social2Image from './Images/fzds.png';
import social3Image from './Images/tgg.png';
import social4Image from './Images/Faza BEPC Resultat.png';
import socialMImage from './Images/grad.png';
import facebookIcon from './Symbols/Facebook white.png';
import xIcon from './Symbols/X White.png';
import instaIcon from './Symbols/Insta White.png';
import linkedInIcon from './Symbols/Linkdne White.png';
import Garrow from './Symbols/golden arrow.png';






const SocialMediaSection = () => {

    let data = [
        { id: 1, imgSrc: social1Image },
        { id: 2, imgSrc: social2Image },
        { id: 3, imgSrc: social3Image },
        { id: 4, imgSrc: social4Image },
        { id: 5, imgSrc: socialMImage, isMain: true } // Mark the main image
    ];


    return (

        <>






            <div className="Suivez-nous">





                <h2>Suivez-nous</h2>

                <div className="gallery">
                    {data.map((item, index) => (
                        <div className={`pics ${item.isMain ? 'main-img' : ''}`} key={index}>
                            <img src={item.imgSrc} alt={`Social Media ${index + 1}`} />
                            <div className="rectangle2"></div>
                        </div>
                    ))}
                </div>

                <div className="social-vertical">
                    <div className="rectangle"></div>
                    {/* Social Media Images */}
                    <div className="Socialimage1">
                        <a href="https://example.com">
                            <img src={social1Image} alt="" className="social1-image" />
                        </a>
                    </div>

                    <div className="Socialimage2">
                        <a href="https://example.com">
                            <img src={social2Image} alt="" className="social2-image" />
                        </a>
                    </div>



                    <div className="Socialimage3">
                        <a href="https://example.com">
                            <img src={social3Image} alt="" className="social3-image" />
                        </a>
                    </div>

                    <div className="Socialimage4">
                        <a href="https://example.com">
                            <img src={social4Image} alt="" className="social4-image" />
                        </a>
                    </div>


                    <div className="Socialimagem">
                        <a href="https://example.com">
                            <img src={socialMImage} alt="" className="socialm-image" />
                        </a>
                    </div>


                </div>

                <div className="social-Horizontal">
                    {/* Social Media Icons */}
                    <div className="Socialicon">
                        <a href="https://example.com">
                            <img src={facebookIcon} alt="" className="facebook-image" />
                        </a>
                    </div>

                    <div className="Socialicon">
                        <a href="https://example.com">
                            <img src={xIcon} alt="" className="x-image" />
                        </a>
                    </div>

                    <div className="Socialicon">
                        <a href="https://example.com">
                            <img src={instaIcon} alt="" className="insta-image" />
                        </a>
                    </div>

                    <div className="Socialicon">
                        <a href="https://example.com">
                            <img src={linkedInIcon} alt="" className="lkndn-image" />
                        </a>
                    </div>
                </div>

                <div className="social-Horizontal2">
                    <div className="Abonnez-vous">
                        <a href="https://example.com">
                            <h3>
                                Abonnez-vous à nos réseaux sociaux
                                <img src={Garrow} alt="Golden Arrow" className="G-arrow" />
                            </h3>
                            {/* Follow us section */}
                        </a>
                    </div>

                </div>
            </div>


        </>
    );

};

export default SocialMediaSection;