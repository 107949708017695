import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './Confidentialite.scss';
import HeaderX from './HeaderX';
import BlackSectionX from './BlackSectionX';
import InfoConfid from './ConfidPage/InfoConfid';
import ModernFooter from './ModernFooter';
import InfoVerticalePageX from './InfoVerticalePageX';

const Accessibilité = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location


    return (
        <div>
            <HeaderX />

            <div className="Grey-section-Con">

                <div className="Grey-wrap-Con">
                    <div className="Grey-center-Con">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaCon-wrap">
                                <h1 className="Faza-Con" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Conarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposCon-wrap">
                                <h1 className="AproposCon" id="titleA">
                                    Autre
                                </h1>

                                <h2 className="ConarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AACon" id="titleA">
                            Confidentialité
                        </h1>

                    </div>
                </div>

            </div>
            <BlackSectionX />

            <div className="infov-mainC">
                <h1 className="A1Con" id="titleA">
                    <span class="bold-text">Confidentialité</span>
                </h1>

                <div className="infov-Con">
                    <div className="infov-BorderCon">

                        <InfoVerticalePageX />

                        <InfoConfid />
                    </div>
                </div>
            </div>
            <ModernFooter />

        </div>

    );
};

export default Accessibilité;
