import React from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './Autre.scss';
import HeaderX from './HeaderX';
import BlackSectionX from './BlackSectionX';

import FooterX from './FooterX';
import InfoVerticalePageX from './InfoVerticalePageX';

const Autre = () => {
    const location = useLocation(); // Get the current location


    return (
        <div>
            <HeaderX />
            <div className="Grey-sectionI">
                <Link to="/"> {/* Add Link to navigate back to the main page */}
                    <h1 className="FazaI" id="title1">
                        Complexe Scolaire Privé Faza {'>'}
                    </h1>
                </Link>
                <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                    <h1 className="AproposGI" id="titleA">
                        À propos {'>'}
                    </h1>
                </Link>
                <h1 className="AutrI" id="titleA">
                    Autre
                </h1>
            </div>
            <BlackSectionX />


            {/* Rest of your content */}

            <h1 className="Autr" id="title1">Registrariat</h1>


            <h1 className="AutrI" id="title3">Communauté Éducative Engagée et Atmosphère Inclusive</h1>

            <h1 className="AutrII" id="title3" >
                Rejoignez une communauté éducative passionnée et un corps enseignant dévoué. Au CSP Faza, nous cultivons une atmosphère inclusive, propice à des approches pédagogiques innovantes.  </h1>

            <h1 className="AutrII" id="title3" >
                Célébrant plus d'une décennie de succès, le CSP Faza affiche un bilan impressionnant. Découvrez nos réalisations et nos ambitieuses perspectives d'avenir, témoignant de notre engagement envers une éducation de qualité.  </h1>
            <InfoVerticalePageX />


            <FooterX />

        </div>

    );
};

export default Autre;

