import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BlackSectionX.scss';
import newarrow2 from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/golden arrow.png';

const BlackSectionA = () => {

    // Toggle dropdown and maintain its state
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleInformationTClick = (event) => {
        if (window.innerWidth <= 991) {
            event.preventDefault();
            // Toggle dropdown and maintain its state
            setDropdownOpen(!dropdownOpen);
            setDropdownStatesT(prevStates => ({
                ...prevStates,
                Servex: !prevStates.Servex

            }));


        }


    };

    ///Delay on the Navigation


    const handleDelayedNavigationE = (url, delay) => {
        // Start the closing animation
        const gridContainer3 = document.querySelector('.grid-container3');
        gridContainer3.classList.add('closing');

        setTimeout(() => {
            setIsMenuOpenE(false);
            gridContainer3.classList.remove('closing');

            // After the grid slides up, navigate to the new URL
            setTimeout(() => {
                window.location.href = url;
            }, delay); // Delay for the navigation

        }, 200); // Duration of the slide-up animation
    };
    ///Delay on the Navigation /End

    ///Grid


    ///close grid when click outside
    const blackSectionERef = useRef(null);
    const gridERef = useRef(null);
    const titleRef = useRef(null);
    const searchERef = useRef(null);

    // Handler to toggle the grid from the menu icon and black section
    const toggleGrid3 = (event) => {
        if (!titleRef.current.contains(event.target) &&
            !searchERef.current.contains(event.target)) {
            setIsMenuOpenE(!isMenuOpenE);
        }
    };

    // ... existing code

    // Close the grid when clicking on the empty surface
    // Handler for closing the grid when clicking outside
    const handleClickOutside = (event) => {
        if (blackSectionERef.current && !blackSectionERef.current.contains(event.target) &&
            gridERef.current && !gridERef.current.contains(event.target) &&
            !(titleRef.current && titleRef.current.contains(event.target)) &&
            !(searchERef.current && searchERef.current.contains(event.target))) {
            setIsMenuOpenE(false);
        }
    };


    const [isMenuOpenE, setIsMenuOpenE] = useState(false);
    const [dropdownStatesT, setDropdownStatesT] = useState({
        Servex: false,
        ServP: false,
        LibP: false,
        JoindreP: false
        // ... other dropdowns if necessary

    });

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    ///close grid when click outside /End


    ///Grid /End










    const location = useLocation();
    const [selectedRectangle, setSelectedRectangle] = useState(null);
    const [selectedRectangleA, setSelectedRectangleA] = useState(null);

    useEffect(() => {
        // Map the path to a key that represents each title
        const rectangleIndex = {
            '/Diplo-mey': 1,
            '/serv-Pub': 2,
            '/Part-ney': 3,
            '/JoindreX': 4,


        };


        const rectangleAIndex = {
            '/Diplo-mey': 1,
            'Ploma': 1,
            '/Akss': 2,
            '/Akks-bil': 2,
            '/ConfX': 3,
            '/Cons-fidd': 3,
            '/AutrX': 4,
            '/Autr-2X': 4,



        };

        // Check if the current path is one of the dropdown items
        const isDropdownItem = Object.keys(rectangleAIndex).includes(location.pathname);
        // Set the selected rectangle based on the current path
        setSelectedRectangleA(rectangleAIndex[location.pathname] || null);
        // Set the selected path based on the current path
        setSelectedRectangle(rectangleIndex[location.pathname] || 1);


        // Set the dropdown to open if the current path is a dropdown item


        if (isDropdownItem) {
            setDropdownStatesT(prevStates => ({
                ...prevStates,
                Servex: true
            }));
            setDropdownOpen(true); // Ensure dropdownOpen state is also set
        }
    }, [location]);



    ///Search Engine
    const [searchTermE, setSearchTermE] = useState('');

    const handleSearchChangeE = (event) => {
        setSearchTermE(event.target.value);
    };

    const handleSearchSubmitE = () => {
        window.location.href = `/search?query=${encodeURIComponent(searchTermE)}`;
    };
    ///Search Engine /End

    // New function to handle key press
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmitE();
        }
    };

    return (
        <div className="black-sectionP" ref={blackSectionERef} onClick={toggleGrid3}>

            <div className="mobile-menu-icon3" >
                {/* Hamburger Icon */}
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div className="CspFaza4">
                <h1>Publics</h1>
            </div>


            <div className={`grid-container3 ${isMenuOpenE ? 'open' : ''}`} ref={gridERef}>
                <div className="header-wrapAut" >
                    <div className="headerAut">
                        <div className="search-barEtu" >
                            <input
                                type="text"
                                placeholder="Chercher..."
                                value={searchTermE}
                                onChange={handleSearchChangeE}
                                ref={searchERef}
                                onKeyPress={handleKeyPress}
                            />
                            <i className="fa fa-search search-iconEtud" onClick={handleSearchSubmitE}></i>
                        </div>
                    </div>

                    <div className="Title-wrapperAut" >


                        <Link to="/Diplo-mey" ref={titleRef} onClick={handleInformationTClick} >

                            <div className={`Servex ${selectedRectangle === 1 ? 'selected' : ''} ${dropdownStatesT.Servex ? 'dropdown-openT' : ''}`}>
                                <div className="AUWrap" ref={titleRef}>
                                    <h1 className="info-titleP" >Autres Services</h1>


                                    <span className={`signAU ${dropdownStatesT.Servex ? 'minus-signAU' : 'plus-signAU'}`}>
                                        {dropdownStatesT.Servex ? '_' : '+'}
                                    </span>


                                </div>

                                <div className={`dropdown-contentAU ${dropdownStatesT.Servex ? 'activeE' : ''}`}>

                                    <Link to="/Diplo-mey" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/Diplo-mey', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleA ${selectedRectangleA === 1 ? 'selectedA' : ''}`}>
                                            <h1 className="info-titleA">Diplômes</h1>
                                        </div>
                                    </Link>



                                    <Link to="/Akks-bil" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/Akks-bil', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleA ${selectedRectangleA === 2 ? 'selectedA' : ''}`}>

                                            <h1 className="info-titleA">Accessibilité</h1>
                                        </div>
                                    </Link >


                                    <Link to="/Cons-fidd" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/Cons-fidd', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleA ${selectedRectangleA === 3 ? 'selectedA' : ''}`}>
                                            <h1 className="info-titleA"> Confidentialité</h1>
                                        </div>
                                    </Link>

                                    {/* Débloquer au besoin
                            <Link to="/Autr-2X" onClick={(e) => {
                                e.preventDefault(); // Prevent default link behavior
                                handleDelayedNavigationE('/Autr-2X', 250); // Adjust the delay as needed
                            }}>
                                <div className={`info-rectangleA ${selectedRectangleA === 4 ? 'selectedA' : ''}`}>
                                    <h1 className="info-titleA">Autre</h1>
                                </div>
                            </Link>
                     */}
                                </div>
                            </div>
                        </Link>
                        <div className="title-lineA"></div>


                        <Link to="/serv-Pub" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigationE('/serv-Pub', 200)
                        }}>
                            <div>
                                <div className={`ServP ${selectedRectangle === 2 ? 'selected' : ''}`}>
                                    <h1 className="info-titleP">Service au public</h1>
                                </div>
                            </div>
                        </Link>



                        <div className="title-lineA2"></div>

                        <Link to="/Part-ney" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigationE('/Part-ney', 200)
                        }}>
                            <div>
                                <div className={`LibP ${selectedRectangle === 3 ? 'selected' : ''}`}>
                                    <h1 className="info-titleP">Partenaires</h1>
                                </div>
                            </div>
                        </Link>

                        <div className="title-lineA3"></div>

                        <Link to="/JoindreX" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigationE('/JoindreX', 200)
                        }}>
                            <div>
                                <div className={`JoindreP ${selectedRectangle === 4 ? 'selected' : ''}`}>
                                    <h1 className="info-titleP">Nous Joindre </h1>
                                </div>
                            </div>
                        </Link>



                    </div>
                    <img src={newarrow2} alt="Golden Arrow" className="D-arrow2" onClick={toggleGrid3} />
                </div>
            </div>
        </div>
    );
};

export default BlackSectionA;