import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import HeaderX from './HeaderX';
import BlackSectionX from './BlackSectionX';
import './Public.scss';
import FooterX from './FooterX';
import InfoBib from './InfoBibPage/InfoBib';
const Bibliotheque = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location
    return (
        <div>
            <HeaderX />
            <div className="Grey-section-Pub">

                <div className="Grey-wrap-Pub">
                    <div className="Grey-center-Pub">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaPub-wrap">
                                <h1 className="Faza-Pub" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Pubarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposPub-wrap">
                                <h1 className="AproposPub" id="titleA">
                                    Autres
                                </h1>

                                <h2 className="PubarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AAPub" id="titleA">
                            Services au public
                        </h1>

                    </div>
                </div>

            </div>
            <BlackSectionX />

            <div className="infov-main">
                <h1 className="A1Pub" id="titleA">
                    <span class="bold-text"> Services au public</span>
                </h1>

                <div className="infov-Pub">
                    <div className="infov-Border-Pub">



                        <InfoBib />
                    </div>
                </div>
            </div>





            <FooterX />
        </div >
    );
};

export default Bibliotheque;