import React, { useEffect } from 'react';

import './Infodates.scss';

const Infodates = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    return (


        <div className="Trait-dates">
            <div className="Etude-Piece-center">
                {/* Rest of your content */}


                <div className="First-dates">
                    <h1 className="Demandates" id="title1">Dates limites d'admission</h1>

                    <div className="dates-line"></div>
                    <div className="First-dates-wrap">

                        <div className="Etapes-dates">
                            <h1 className="DatesF" id="title1" >Dates l'imites d'admission 2024 : </h1>
                            <h2 className="DatesF2" id="title1" >15 Aout 2024 </h2>
                        </div>

                    </div>
                    <div className="First-dates2">
                        <h1 className="DatesF1" id="title1" >Dates l'imites d'Inscriptions 2024 : </h1>
                        <h2 className="DatesF12" id="title1" >30 Aout 2024 </h2>

                    </div>
                    <div className="frais-line2"></div>

                    <div className="First-fraisF">
                        <div className="Etapes-frais2">
                            <h1 className="DemanFD2" id="title1" >Paiement des frais d'admission</h1>

                        </div>

                        <h1 className="fraisIs3" id="title2">Les paiement des frais d'admission se font uniquement en comptant</h1>


                    </div>


                </div>



            </div>
        </div>





    );

};


export default Infodates;