import React, { useEffect } from 'react';

import './InfoJoinA.scss';
import logoImage from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Pages/Info Générale/Symbols/Call.png'; // Update with the correct path to your image


const InfoJoin = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (


        <div className="Etude-Join">
            <div className="Etude-Join-center">
                {/* Rest of your content */}
                <h1 className="J1dre" id="title1">Nous Joindre</h1>

                <div className="First-Join">

                    <div className="rectangles-containerJ">
                        <div className="rectangleJ left-rectangleJ">
                            <div className="text-inline">
                                <div className="text-1">
                                    <p>Téléphone</p> {/* Update text as needed */}
                                </div>
                                <div className="text-2">
                                    <img src={logoImage} alt="Logo" className="logo-image" />
                                    <p>227 80 34 89 95</p> {/* Update text as needed */}

                                </div>
                            </div>
                        </div>

                        <div className="rectangleJ right-rectangleJ">
                            <div className="text-inline2">
                                <div className="text-3">
                                    <p>URGENCE</p> {/* Update text as needed */}
                                </div>
                                <div className="text-4">
                                    <p>227 80 34 89 95</p> {/* Update text as needed */}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>



                <div className="Join-line"></div>
                <div className="First-Join2">
                    <h1 className="Kontakt" id="title2">Nos heures d'ouverture</h1>
                    <h1 className="KontaktI" id="title2" >
                        Lundi au vendredi : 7 h 30 à 17 h 00.</h1>

                    <h1 className="KontaktII" id="title2" >
                        Samedi : 8 h 00 à 17 h 00  </h1>

                    <h1 className="KontaktIII" id="title2" >
                        Dimanche : Fermé . </h1>
                </div>
            </div>
        </div>

    );

};


export default InfoJoin;