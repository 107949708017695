import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './Direction.scss';
import HeaderInfo from './HeaderInfo';
import BlackSectionI from './BlackSectionInfo';
import Infodir from './DirectionPage/Infodir';
import ModernFooter from './ModernFooter';
import InfoVerticalePage from './InfoVerticalePage';

const Direction = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location


    return (
        <div>

            <HeaderInfo />

            <div className="Grey-sectionD">

                <div className="Grey-wrapD">
                    <div className="Grey-centerD">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="Fazadir-wrap">
                                <h1 className="FazaDir" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>

                                <h2 className="Dirrow" id="title2">{'>'} </h2>
                            </div>
                        </Link>


                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposDir-wrap">

                                <h1 className="AproposDI" id="titleA">
                                    À propos
                                </h1>

                                <h2 className="ApprarowA" id="titleA2">{'>'}  </h2>
                            </div>
                        </Link>



                        <h1 className="ADirectID" id="titleA">
                            Direction et services{' '}
                        </h1>
                    </div>

                </div>

            </div>

            <BlackSectionI />
            <div className="infov-mainD">
                <h1 className="A1Dir" id="titleA">
                    <span class="bold-text">Direction et services</span>
                </h1>
                <div className="infov-Dir">
                    <div className="infov-Dir-center">
                        <InfoVerticalePage />
                        <Infodir />
                    </div>

                </div>
            </div>


            <ModernFooter />

        </div>

    );
};

export default Direction;