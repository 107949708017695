import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './FaitsChiffres.scss';
import HeaderInfo from './HeaderInfo';
import BlackSectionI from './BlackSectionInfo';
import InfoChiff from './FaitsChiffresPage/InfoChiff';
import ModernFooter from './ModernFooter';
import InfoVerticalePage from './InfoVerticalePage';

const FaitsChiffres = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location



    return (
        <div>
            <HeaderInfo />

            <div className="Grey-sectionChiff">



                <div className="Grey-wrapChiff">
                    <div className="Grey-centerChiff">
                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaChiff-wrap">
                                <h1 className="FazaChiff" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Chiffrow" id="title2">{'>'} </h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="ApropoChiff-wrap">
                                <h1 className="AproposChiff" id="titleA">
                                    À propos
                                </h1>

                                <h2 className="ApprarowChiff" id="titleA2">{'>'}  </h2>
                            </div>
                        </Link>
                        <h1 className="Achiff" id="titleA">
                            Faits et chiffres
                        </h1>
                    </div>
                </div>
            </div>
            <BlackSectionI />


            <div className="infov-mainCh">
                <h1 className="A1Ch" id="titleA">
                    <span class="bold-text">Faits et Chiffres</span>
                </h1>

                <div className="info-Chiff">
                    <div className="info-Chiff-center">
                        <InfoVerticalePage />
                        <InfoChiff />
                    </div>

                </div>
            </div>



            <ModernFooter />

        </div>

    );
};

export default FaitsChiffres;
