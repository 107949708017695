// App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import 'instantsearch.css/themes/satellite.css';
import './App.scss';
import Header from './Header';
import BlackSection from './BlackSection';
import BottomImage from './BottomImage';
import NewsSection from './NewsSection';
import SocialMediaSection from './SocialMediaSection';
import Footer from './Footer';
import MapContainer from './MapContainer';
import MajorMinorPoints from './MajorMinorPoints';
import SearchResults from './SearchResults';

// Title 1
import InformationGeneralePage from './Pages/Info Générale/InformationGeneralePage'; // Import InformationGeneralePage
import LesEcolesPage from './Pages/Info Générale/LesEcolesPage'; // Import other pages
import TravaillerPage from './Pages/Info Générale/TravaillerPage'; // Import other pages
import NousJoindrePage from './Pages/Info Générale/NousJoindrePage'; // Import other pages
import Direction from './Pages/Info Générale/Direction'; // Import the new page component
import HistoireFaza from './Pages/Info Générale/HistoireFaza'; // Import the new page component
import FaitsChiffres from './Pages/Info Générale/FaitsChiffres'; // Import the new page component

// Title 2
import EtudeFaza from './Pages/Étude à Faza/EtudeFaza';
import DemandeAdmin from './Pages/Étude à Faza/DemandeAdmin';
import CoutsEtudes from './Pages/Étude à Faza/CoutsEtudes';

// Title 3
import Calendrier from './Pages/Activite/Calendrier'; // Import InformationGeneralePage
import Aliment from './Pages/Activite/Aliment'; // Import other pages
import Librairie from './Pages/Activite/Librairie'; // Import other pages
import Infirmerie from './Pages/Activite/Infirmerie'; // Import the new page component
import CodeV from './Pages/Activite/CodeV'; // Import the new page component
import Plaintes from './Pages/Activite/Plaintes'; // Import the new page component
import NousJoindrePageA from './Pages/Activite/NousJoindrePageA'; // Import other pages

// Title 4
import JoindreJ from './Pages/Joindre/JoindreJ';
import Bottin from './Pages/Joindre/Bottin';

// Title 5
import Inscriptions from './Pages/Etudiants/Inscriptions'; // Import InformationGeneralePage
import Orientation from './Pages/Etudiants/Orientation'; // Import other pages
import Bibliotheque from './Pages/Etudiants/Bibliotheque'; // Import other pages
import Cafetariat from './Pages/Etudiants/Cafetariat'; // Import other pages
import Surveillant from './Pages/Etudiants/Surveillant'; // Import the new page component
import Registrariat from './Pages/Etudiants/Registrariat'; // Import the new page component
import JoindreS from './Pages/Etudiants/NousJoindrePageS';

// Title 6
import Diplomes from './Pages/Autres/Diplomes'; // Import InformationGeneralePage
import Public from './Pages/Autres/Public'; // Import other pages
import Partenaires from './Pages/Autres/Partenaires'; // Import other pages
import Accessibilité from './Pages/Autres/Accessibilité'; // Import the new page component
import Confidentialite from './Pages/Autres/Confidentialite'; // Import the new page component
import Autre from './Pages/Autres/Autre'; // Import the new page component
import JoindreX from './Pages/Autres/NousJoindrePageX'; // Import other pages

///Demande d'admission

import PiecesRequises from './Pages/Étude à Faza/PiecesRequises'; // Import other pages
import FraisAdmission from './Pages/Étude à Faza/FraisAdmission'; // Import other pages
import DatesLimites from './Pages/Étude à Faza/DatesLimites'; // Import other pages
import TraitmentDemandes from './Pages/Étude à Faza/TraitmentDemandes'; // Import the new page component
import TestDentree from './Pages/Étude à Faza/TestDentree'; // Import the new page component

import MainPageActu from './Actualite/MainPageActu'; // Import the new page component


const App = () => {


  return (
    <>

      <div className="app">

        <Routes>

          {/* Main Page Routes Infoverticalepage */}

          <Route path="/information-generale" element={<InformationGeneralePage />} />
          <Route path="/ecoles-faza" element={<LesEcolesPage />} />
          <Route path="/travailler-faza" element={<TravaillerPage />} />
          <Route path="/directions-et-service" element={<Direction />} />
          <Route path="/histoire-de-faza" element={<HistoireFaza />} />
          <Route path="/faits-et-chiffres" element={<FaitsChiffres />} />


          {/* Main Page Routes InfoverticalepageET */}

          <Route path="/InformationET" element={<DemandeAdmin />} />
          <Route path="/Pieces-requises" element={<PiecesRequises />} />
          <Route path="/frais-admission" element={<FraisAdmission />} />
          <Route path="/dates-limites" element={<DatesLimites />} />
          <Route path="/Traitment-demandes" element={<TraitmentDemandes />} />
          <Route path="/Test-dentree" element={<TestDentree />} />



          {/* Main Page Routes 2 */}

          <Route path="/etude-faza" element={<EtudeFaza />} />
          <Route path="/demande-admin" element={<DemandeAdmin />} />
          <Route path="/couts-etude" element={<CoutsEtudes />} />
          <Route path="/faza-sonuci" element={<LesEcolesPage />} />
          <Route path="/faza-yantala" element={<LesEcolesPage />} />

          {/* Main Page Routes 3 */}

          <Route path="/evmnt-cal" element={<Calendrier />} />
          <Route path="/sev-ali" element={<Aliment />} />
          <Route path="/libr-airie" element={<Librairie />} />
          <Route path="/medoc" element={<Infirmerie />} />
          <Route path="/vesti-code" element={<CodeV />} />
          <Route path="/pla-inte" element={<Plaintes />} />

          {/* Main Page Routes 4 */}

          <Route path="/Noos-coo" element={<JoindreJ />} />
          <Route path="/Heur-douv" element={<JoindreJ />} />
          <Route path="/Bot-tin" element={<Bottin />} />

          {/* Main Page Route5 */}

          <Route path="/Gis-tra" element={<Registrariat />} />
          <Route path="/Ins-cirp" element={<Inscriptions />} />
          <Route path="/serv-ori" element={<Orientation />} />
          <Route path="/Biblio-tek" element={<Bibliotheque />} />
          <Route path="/Cfte-ria" element={<Cafetariat />} />
          <Route path="/Surv-pv" element={<Surveillant />} />

          <Route path="/JoindreS" element={<JoindreS />} />

          {/* Main Page Routes6 */}

          <Route path="/Diplo-mey" element={<Diplomes />} />
          <Route path="/serv-Pub" element={<Public />} />
          <Route path="/Part-ney" element={<Partenaires />} />
          <Route path="/Akks-bil" element={<Accessibilité />} />
          <Route path="/Cons-fidd" element={<Confidentialite />} />
          <Route path="/Autr-2X" element={<Autre />} />
          <Route path="/JoindreX" element={<JoindreX />} />

          {/* Tooltip1 Routes */}

          <Route path="/InformationG" element={<InformationGeneralePage />} />
          <Route path="/InformationG2" element={<InformationGeneralePage />} />
          <Route path="/Lesecoles" element={<LesEcolesPage />} />
          <Route path="/Travail" element={<TravaillerPage />} />
          <Route path="/direction-services" element={<Direction />} />
          <Route path="/histoire-faza" element={<HistoireFaza />} />
          <Route path="/Fait-Chiff" element={<FaitsChiffres />} />
          <Route path="/JoindreI" element={<NousJoindrePage />} />

          {/* Tooltip2 Routes */}

          <Route path="/EtudeE" element={<EtudeFaza />} />
          <Route path="/EcoleE" element={<LesEcolesPage />} />
          <Route path="/AdmiE" element={<DemandeAdmin />} />
          <Route path="/CoutE" element={<CoutsEtudes />} />


          {/* Tooltip3 Routes */}

          <Route path="/CalenA" element={<Calendrier />} />
          <Route path="/ServA" element={<Aliment />} />
          <Route path="/LibA" element={<Librairie />} />
          <Route path="/InfrimA" element={<Infirmerie />} />
          <Route path="/codevestiA" element={<CodeV />} />
          <Route path="/PlaintesA" element={<Plaintes />} />
          <Route path="/JoindreA" element={<NousJoindrePageA />} />

          {/* Tooltip4 Routes */}

          <Route path="/NosJ " element={<JoindreJ />} />
          <Route path="/Heur-douv " element={<JoindreJ />} />
          <Route path="/Botti" element={<Bottin />} />

          {/* Tooltip5 Routes */}

          <Route path="/IsciS" element={<Inscriptions />} />
          <Route path="/Orit" element={<Orientation />} />
          <Route path="/BlioS " element={<Bibliotheque />} />
          <Route path="/Kafid" element={<Cafetariat />} />
          <Route path="/Bsurv" element={<Surveillant />} />
          <Route path="/regS" element={<Registrariat />} />

          {/* Tooltip6 Routes */}

          <Route path="/Ploma" element={<Diplomes />} />
          <Route path="/Blik " element={<Public />} />
          <Route path="/ParN " element={<Partenaires />} />
          <Route path="/ConfX" element={<Confidentialite />} />
          <Route path="/Akss" element={<Accessibilité />} />
          <Route path="/AutrX" element={<Autre />} />
          <Route path="/faits-et-chiffres" element={<JoindreX />} />


          {/* Major titles A propos */}

          <Route path="/information-G" element={<InformationGeneralePage />} />
          <Route exact path="/ecoles-faza2" element={<LesEcolesPage />} />
          <Route path="/travailler-faza2" element={<TravaillerPage />} />
          <Route path="/direction-service2" element={<Direction />} />
          <Route path="/Histoire-faza2" element={<HistoireFaza />} />
          <Route path="/Faits-chiffre2" element={<FaitsChiffres />} />

          {/* Major titles Etudier a Faza */}

          <Route path="/Etud-G" element={<EtudeFaza />} />
          <Route path="/Dem-adm" element={<DemandeAdmin />} />
          <Route path="/Cout-G2" element={<CoutsEtudes />} />
          <Route path="/Faz-Son2" element={<LesEcolesPage />} />
          <Route path="/Faz-Yan2" element={<LesEcolesPage />} />
          {/* Major titles Activites */}
          <Route path="/Kal-G" element={<Calendrier />} />
          <Route path="/Sevi-ali" element={<Aliment />} />
          <Route path="/Libr-aria" element={<Librairie />} />
          <Route path="/Inf-irm" element={<Infirmerie />} />
          <Route path="/Cod-v2" element={<CodeV />} />
          <Route path="/Plt-div" element={<Plaintes />} />


          {/* Major titles Etudier Joindre  */}

          <Route path="/nos-cor2" element={<JoindreJ />} />
          <Route path="/Nos-h2" element={<JoindreJ />} />
          <Route path="/Bo-tti2" element={<Bottin />} />
          {/* Demande d'admission  */}
          <Route path="/white-border" element={<DemandeAdmin />} />

          <Route path="/search" element={<SearchResults />} />
          <Route path="/MainPage-Actu" element={<MainPageActu />} />
          <Route path="/" element={<>

            {/* other components */}

            <Header />

            <BlackSection />
            <BottomImage />
            <NewsSection />
            <SocialMediaSection />
            <MapContainer />
            <MajorMinorPoints />
            <Footer />

          </>} />
        </Routes>

      </div>
    </>
  );
};

export default App;