import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import './InformationGeneralePage.scss';
import HeaderInfo from './HeaderInfo';
import BlackSectionI from './BlackSectionInfo';
import Infomain from './Infomain';
import InfoVerticalePage from './InfoVerticalePage';
import ModernFooter from './ModernFooter';

const InformationGeneralePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const location = useLocation(); // Get the current location


    return (
        <div>
            <HeaderInfo />

            <div className="Grey-section">
                <div className="Grey-wrap">
                    <div className="Grey-center">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaHead-wrap">

                                <h1 className="Faza" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>

                                <h2 className="Fazarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>


                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposHead-wrap">
                                <h1 className="AproposInfo" id="titleA">
                                    À propos
                                </h1>

                                <h2 className="FazarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="ASommaire" id="titleA">
                            Présentation sommaires{' '}
                        </h1>
                    </div>
                </div>
            </div>

            <BlackSectionI />
            <div className="infov-Genin">
                <h1 className="A1Gen" id="titleA">
                    <span class="bold-text">Présentation et Sommaire</span>
                </h1>
                <div className="infov-Gen">
                    <div className="infov-center">
                        <InfoVerticalePage />
                        <Infomain />
                    </div>

                </div>
            </div>

            <ModernFooter />


        </div>

    );

};


export default InformationGeneralePage;