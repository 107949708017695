// Header.js
import React, { useState } from 'react';
import './HeaderJ.scss';
import { Link } from 'react-router-dom';
import logoI from './Symbols/LOGOWEB.png'; // Update the path accordingly
import logo2I from './Symbols/logo2.png'; // Update the path accordingly

const Header = () => {

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = () => {
        window.location.href = `/search?query=${encodeURIComponent(searchTerm)}`;
    };

    // New function to handle key press
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmit();
        }
    };

    return (
        <header>


            <div className="header-backgroundI">
                <div className="top-wrapI">
                    <div className="logo-wrapI">
                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <img className="new-logoI" src={logoI} alt="" />
                        </Link>
                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <img className="new-logo2I" src={logo2I} alt="" />
                        </Link>
                        <div className="logo-Line"></div>
                        <h1 className="logo-Tile"> Complexe Scolaire Privé Faza    </h1>

                    </div>
                    <div className="headerH">
                        <div className="search-barH">
                            <input
                                type="text"
                                placeholder="Chercher..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onKeyPress={handleKeyPress}
                            />
                            <i className="fa fa-search search-iconH" onClick={handleSearchSubmit}></i>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;