import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeaderInfo from './HeaderInfo';
import BlackSectionInfo from './BlackSectionInfo';
import './LesEcolesPage.scss';
import InfoEcoles from './LesEcolesPage/InfoEcoles';

import ModernFooter from './ModernFooter';
const LesEcolesPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location
    return (
        <div>

            <HeaderInfo />

            <div className="Grey-section-Ecoles">
                <div className="Grey-wrapEc">
                    <div className="Grey-centerEc">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaEc-wrap">
                                <h1 className="FazaEc" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>

                                <h2 className="Ecarrow" id="title2">{'>'} </h2>
                            </div>
                        </Link>


                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposEc-wrap">

                                <h1 className="AproposEC" id="titleA">
                                    À propos
                                </h1>

                                <h2 className="EcarowA" id="titleA2">{'>'}  </h2>
                            </div>
                        </Link>



                        <h1 className="ECirect" id="titleA">
                            Les Écoles Faza
                        </h1>
                    </div>

                </div>
            </div >
            <BlackSectionInfo />

            <div className="infov-Ec">
                <div className="infov-Ec-center">

                    <InfoEcoles />
                </div>

            </div>
            <ModernFooter />

        </div>
    );
};

export default LesEcolesPage;