import React, { useState, useEffect } from 'react';

import './InfoInfirm.scss';

const InfoCal = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesA(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesA, setDropdownStatesA] = useState({
        InfAI: false,
        InfA2I: false,



    });


    return (


        <div className="Etude-Inf">
            <div className="Etude-Inf-center">
                {/* Rest of your content */}


                <div className="First-Inf">
                    <h1 className="Inf" id="title1">Premiers soins</h1>
                    <div className="First-Inf-wrap">

                        <div className="Etapes-Inf">
                            <h1 className="InfI" id="title1" >L'Infirmerie  propose une variété de services spécialisés afin d’assurer un retour rapide à vos activités.</h1>
                            <h1 className="InfI2" id="title1" > Les soins sont offer gratuitement aux élèves et au personel de l'école sans rendez-vous.</h1>
                        </div>
                        <div className="Drop-Inf-wrap">
                            <div className="Pieces-Inf" >
                                <div className={`InfII  ${dropdownStatesA.InfAI ? 'dropdownInf-open' : 'dropdownInf-closed'}`}>
                                    <div className="AInfWrap" onClick={(e) => handleInformationAClick('InfAI', e)}>
                                        <h1 className="InfAI" >Informations</h1>



                                        <span className={`signInf ${dropdownStatesA.InfAI ? 'minus-signInf' : 'plus-signInf'}`} >
                                            {dropdownStatesA.InfAI ? '_' : '+'}
                                        </span>

                                    </div>

                                    <div className={`dropdown-contentInf ${dropdownStatesA.InfAI ? 'active' : ''}`}>

                                        <div className="firstSoins">
                                            <h1 className="Info-titleInf">Information additionnelle sur l'infirmerie</h1>
                                        </div>
                                        <div className="Ligen-Biento">
                                            <h1 className="Info-titleInf" id="title1" > </h1>
                                        </div>

                                    </div>
                                </div>

                            </div>



                            <div className="Pieces-Inf2">
                                <div className={`Inf2II  ${dropdownStatesA.InfA2I ? 'dropdownInf-open' : 'dropdownInf-closed'}`}>
                                    <div className="AInfWrap" onClick={(e) => handleInformationAClick('InfA2I', e)}  >
                                        <h1 className="InfA2I" id="title1" > Public</h1>

                                        <span className={`signInf ${dropdownStatesA.InfA2I ? 'minus-signInf' : 'plus-signInf'}`} >
                                            {dropdownStatesA.InfA2I ? '_' : '+'}
                                        </span>

                                    </div>

                                    {/* Dropdown content */}

                                    <div className={`dropdown-contentInf ${dropdownStatesA.InfA2I ? 'active' : ''}`}>

                                        <div className="SecondSoins">
                                            <h1 className="Info-titleInf">Frais : <span className="Info-titleInf2">20.000F.</span></h1>
                                            <h1 className="Info-titleInf">Prenez remdez-vous au  : <span className="Info-titleInf2">227 90 23 38 09</span></h1>
                                        </div>

                                    </div>

                                </div>


                            </div>


                        </div>







                    </div>


                </div>



            </div>
        </div >





    );

};


export default InfoCal;