import React, { useEffect } from 'react';

import './InfoCaf.scss';
import logom from './Images/Subject.png'; // Update the path accordingly

const InfoCaf = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (


        <div className="Etude-Caf">
            <div className="Etude-Caf-center">
                {/* Rest of your content */}


                <div className="First-Caf">
                    <h1 className="DemanCaf" id="title1">Cette page sera bientôt disponible</h1>
                    <img className="new-logom" src={logom} alt="" />


                </div>


            </div>
        </div>





    );

};


export default InfoCaf;