import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './InfoVerticalePageA.scss';

const InfoVerticalePageA = () => {
    const location = useLocation();
    const [selectedRectangleA, setSelectedRectangleA] = useState(null);

    useEffect(() => {
        // Function to map the path to rectangle index
        const rectangleAIndex = {
            '/CalenA': 1,
            '/InfrimA': 2,
            '/medoc': 2,
            '/codevestiA': 3,
            '/vesti-code': 3,
            '/PlaintesA': 4,
            '/pla-inte': 4,
            //Major titles Activite

            '/Inf-irm': 2,
            '/Cod-v2': 3,
            '/Plt-div': 4,

        };

        // Set the selected rectangle based on the current path
        setSelectedRectangleA(rectangleAIndex[location.pathname] || 1);
    }, [location]);

    return (
        <div className="info-verticale-pageA">
            <div className="info-verticaleBackA">
                <div className="info-verticale-wrapA">
                    <Link to="/CalenA">
                        <div className={`info-rectangleA ${selectedRectangleA === 1 ? 'selectedA' : ''}`}>
                            <h1 className="info-titleA">Calendrier</h1>
                        </div>
                    </Link>

                    <Link to="/InfrimA">
                        <div className={`info-rectangleA ${selectedRectangleA === 2 ? 'selectedA' : ''}`}>

                            <h1 className="info-titleA">Infirmerie</h1>
                        </div>






                    </Link >

                    <Link to="/codevestiA">
                        <div className={`info-rectangleA ${selectedRectangleA === 3 ? 'selectedA' : ''}`}>
                            <h1 className="info-titleA">Code vestimentaire</h1>
                        </div>
                    </Link>

                    <Link to="/PlaintesA">
                        <div className={`info-rectangleA ${selectedRectangleA === 4 ? 'selectedA' : ''}`}>
                            <h1 className="info-titleA">Plaintes et divulgation</h1>
                        </div>
                    </Link>
                </div >
            </div >
        </div>
    );
};

export default InfoVerticalePageA;
