// Footer.js
import React from 'react';
import './FooterX.scss';

const FooterX = () => {
    return (
        <footer>
            <p>&copy; Csp Faza .All rights reserved</p>
        </footer>
    );
};

export default FooterX;
