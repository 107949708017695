import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './InfoVerticalePageET.scss';

const InfoVerticalePageET = () => {


    const location = useLocation();
    const [selectedRectangleT, setSelectedRectangleT] = useState(null);

    useEffect(() => {
        // Function to map the path to rectangle index
        const rectangleTIndex = {
            '/InformationET': 1,
            '/Pieces-requises': 2,

            '/frais-admission': 3,

            '/dates-limites': 4,

            '/Traitment-demandes': 5,

            '/Test-dentree': 6,


        };

        // Set the selected rectangle based on the current path
        setSelectedRectangleT(rectangleTIndex[location.pathname] || 1);

    }, [location]);

    return (
        <div className="info-verticale-pageT">
            <div className="info-verticaleBack">
                <div className="info-verticale-wrap">
                    <Link to="/InformationET">
                        <div className={`info-rectangleET ${selectedRectangleT === 1 ? 'selectedT' : ''}`}>
                            <h1 className="info-titleT">Étapes pour déposer une demande d'admission</h1>
                        </div>
                    </Link>

                    <Link to="/Pieces-requises">
                        <div className={`info-rectangleET  ${selectedRectangleT === 2 ? 'selectedT' : ''}`}>

                            <h1 className="info-titleT">Pièces requises</h1>
                        </div>
                    </Link >

                    <Link to="/frais-admission">
                        <div className={`info-rectangleET  ${selectedRectangleT === 3 ? 'selectedT' : ''}`}>
                            <h1 className="info-titleT">Frais d'admission et reçu</h1>
                        </div>
                    </Link>

                    <Link to="/dates-limites">
                        <div className={`info-rectangleET  ${selectedRectangleT === 4 ? 'selectedT' : ''}`}>
                            <h1 className="info-titleT">Dates limites d'admission</h1>
                        </div>
                    </Link>


                    <Link to="/Traitment-demandes">
                        <div className={`info-rectangleET  ${selectedRectangleT === 5 ? 'selectedT' : ''}`}>
                            <h1 className="info-titleT">Traitement des demandes</h1>
                        </div>
                    </Link>


                    <Link to="/Test-dentree">
                        <div className={`info-rectangleET  ${selectedRectangleT === 6 ? 'selectedT' : ''}`}>
                            <h1 className="info-titleT">Test d'entrée </h1>
                        </div>
                    </Link>
                </div >
            </div >
        </div >
    );
};

export default InfoVerticalePageET;
