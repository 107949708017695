import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './InfoTest.scss';

const InfoTest = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesA(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesA, setDropdownStatesA] = useState({
        TestAI: false,
        Test2AI: false,
        Test3AI: false,


    });


    return (


        <div className="Etude-Test">
            <div className="Etude-Test-center">
                {/* Rest of your content */}


                <div className="First-Test">
                    <h1 className="Testy" id="title1">Test d'entrée à Faza</h1>
                    <div className="First-Test-wrap">

                        <div className="Etapes-Test">
                            <h1 className="TestI" id="title1" >Si vous avez fait une demande d’admission  et que vous êtes admissible, vous serez convoqué à un test, obligatoire et éliminatoire</h1>

                        </div>

                        <div className="Pieces-Test" >
                            <div className={`TestII  ${dropdownStatesA.TestAI ? 'dropdownA-open' : 'dropdownA-closed'}`}>
                                <div className="ATestWrap " onClick={(e) => handleInformationAClick('TestAI', e)} >

                                    <h1 className="TestAI" >Instructions</h1>

                                    <span className={`signTs ${dropdownStatesA.TestAI ? 'minus-signTs' : 'plus-signTs'}`} >
                                        {dropdownStatesA.TestAI ? '_' : '+'}
                                    </span>

                                </div>


                                {/* Dropdown content */}

                                <div className={`dropdown-contentTs ${dropdownStatesA.TestAI ? 'active' : ''}`}>

                                    <div to="/firstTestA">


                                        <div className="Instruc-T">
                                            <h1 className="Info-titleTs" id="title1" >Merci de bien lire les <span className="Info-titleL">instructions </span>relatives au test d'entrée à Faza.</h1>
                                        </div>

                                        <h1 className="Info-titleTs">
                                            <li>
                                                Instruction 1
                                            </li>
                                            <li>
                                                Instruction 2
                                            </li>
                                            <li>
                                                Instruction 3
                                            </li>
                                            <li>
                                                Instruction 4
                                            </li>



                                        </h1>
                                    </div>

                                </div>
                            </div>

                        </div>



                        <div className="Pieces-Test1">
                            <div className={`Test2II  ${dropdownStatesA.Test2AI ? 'dropdownA-open' : 'dropdownA-closed'}`}>
                                <div className="ATestWrap " onClick={(e) => handleInformationAClick('Test2AI', e)} >
                                    <h1 className="Test2AI" id="title1" >  Contenu du test</h1>

                                    <span className={`signTs ${dropdownStatesA.Test2AI ? 'minus-signTs' : 'plus-signTs'}`} >
                                        {dropdownStatesA.Test2AI ? '_' : '+'}
                                    </span>

                                </div>

                                {/* Dropdown content */}

                                <div className={`dropdown-contentTs ${dropdownStatesA.Test2AI ? 'active' : ''}`}>

                                    <div to="/firstTestB">

                                        <h1 className="Info-T"> Le test est divisé en deux parties:</h1>

                                        <h1 className="Info-T2">  La première partie cible le code linguistique et comporte des questions portant sur l'orthographe d'usage, l'orthographe grammaticale, le vocabulaire, la ponctuation et la syntaxe.</h1>
                                        <h1 className="Info-T3"> La deuxième partie comporte des questions portant sur les mathématiques et les questions de logique:</h1>
                                    </div>

                                </div>

                            </div>


                        </div>

                        <div className="Pieces-Test2" >
                            <div className={`Test3II  ${dropdownStatesA.Test3AI ? 'dropdownA-open' : 'dropdownA-closed'}`}>
                                <div className="ATestWrap " onClick={(e) => handleInformationAClick('Test3AI', e)} >
                                    <h1 className="Test3AI" id="title1" > Le jour du test</h1>

                                    <span className={`signTs ${dropdownStatesA.Test3AI ? 'minus-signTs' : 'plus-signTs'}`} >
                                        {dropdownStatesA.Test3AI ? '_' : '+'}
                                    </span>

                                </div>

                            </div>
                            {/* Dropdown content */}

                            <div className={`dropdown-contentTs ${dropdownStatesA.Test3AI ? 'active' : ''}`}>

                                <div to="/firstTestC">
                                    <div to="/Instruc-T2">
                                        <h1 className="Info-T"> <span className="Info-titleL">Le jour du test vous devez:</span></h1>
                                        <h2 className="Info-T2">Consultez les dates importantes du<Link to="/evmnt-cal"> <span className="Tescal">calendrier universitaire</span></Link> </h2>
                                    </div>


                                </div>

                            </div>

                        </div>










                    </div>


                </div>



            </div>
        </div>





    );

};


export default InfoTest;