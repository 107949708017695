import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import HeaderA from './HeaderA';
import BlackSectionA from './BlackSectionA';
import './Librairie.scss';
import FooterA from './FooterA';
import InfoLib from './InfoLibPage/InfoLib';
const Librairie = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation(); // Get the current location
    return (
        <div>
            <HeaderA />
            <div className="Grey-section-Pl">

                <div className="Grey-wrap-Al">
                    <div className="Grey-center-Al">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaAl-wrap">
                                <h1 className="Faza-Al" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Alarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposAl-wrap">
                                <h1 className="AproposAl" id="titleA">
                                    Activités
                                </h1>

                                <h2 className="AlarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AAAl" id="titleA">
                            Librairie
                        </h1>

                    </div>
                </div>

            </div>
            <BlackSectionA />

            <div className="infov-main">
                <h1 className="A1dmin" id="titleA">
                    <span class="bold-text">Librairie</span>
                </h1>

                <div className="infov-Ali">
                    <div className="infov-Border">



                        <InfoLib />
                    </div>
                </div>
            </div>





            <FooterA />
        </div >
    );
};

export default Librairie;