import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeaderJ from './HeaderJ';
import BlackSectionJ from './BlackSectionJ';
import './Bottin.scss';
import ModernFooter from './ModernFooter';
import Infobottin from './InfoBottinPage/Infobottin';
const Bottin = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const location = useLocation(); // Get the current location

    return (
        <div>

            <HeaderJ />

            <div className="Grey-section-couts">
                <div className="Grey-wrap-couts">
                    <div className="Grey-center-couts">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="Fazacouts-wrap">
                                <h1 className="FazaCouts" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="coutsarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>

                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="couts-wrap">
                                <h1 className="Aproposcouts" id="titleA">
                                    Nous joindre
                                </h1>

                                <h2 className="coutsarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AAcouts" id="titleA">
                            Bottin
                        </h1>

                    </div>
                </div>
            </div >


            <BlackSectionJ />

            <div className="infov-couts">
                <div className="infov-couts-bordure"> </div>
                <Infobottin />
            </div>


            <ModernFooter />
        </div>
    );
};

export default Bottin;