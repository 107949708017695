import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BlackSectionA.scss';
import newarrow2 from '/Users/BMB/Downloads/Faza/CSP FAZA_files/my-title-section-app/src/Symbols/golden arrow.png';

const BlackSectionA = () => {

    // Toggle dropdown and maintain its state
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const handleInformationTClick = (event) => {
        if (window.innerWidth <= 991) {
            event.preventDefault();
            // Toggle dropdown and maintain its state
            setDropdownOpen(!dropdownOpen);
            setDropdownStatesT(prevStates => ({
                ...prevStates,
                CalenA: !prevStates.CalenA

            }));


        }


    };

    ///Delay on the Navigation


    const handleDelayedNavigationE = (url, delay) => {
        // Start the closing animation
        const gridContainer3 = document.querySelector('.grid-container3');
        gridContainer3.classList.add('closing');

        setTimeout(() => {
            setIsMenuOpenE(false);
            gridContainer3.classList.remove('closing');

            // After the grid slides up, navigate to the new URL
            setTimeout(() => {
                window.location.href = url;
            }, delay); // Delay for the navigation

        }, 200); // Duration of the slide-up animation
    };
    ///Delay on the Navigation /End

    ///Grid


    ///close grid when click outside
    const blackSectionERef = useRef(null);
    const gridERef = useRef(null);
    const titleRef = useRef(null);
    const searchERef = useRef(null);

    // Handler to toggle the grid from the menu icon and black section
    const toggleGrid3 = (event) => {
        if (!titleRef.current.contains(event.target) &&
            !searchERef.current.contains(event.target)) {
            setIsMenuOpenE(!isMenuOpenE);
        }
    };

    // ... existing code

    // Close the grid when clicking on the empty surface
    // Handler for closing the grid when clicking outside
    const handleClickOutside = (event) => {
        if (blackSectionERef.current && !blackSectionERef.current.contains(event.target) &&
            gridERef.current && !gridERef.current.contains(event.target) &&
            !(titleRef.current && titleRef.current.contains(event.target)) &&
            !(searchERef.current && searchERef.current.contains(event.target))) {
            setIsMenuOpenE(false);
        }
    };


    const [isMenuOpenE, setIsMenuOpenE] = useState(false);
    const [dropdownStatesT, setDropdownStatesT] = useState({
        CalenA: false,
        ServA: false,
        LibA: false,
        JoindreA: false
        // ... other dropdowns if necessary

    });

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    ///close grid when click outside /End


    ///Grid /End










    const location = useLocation();
    const [selectedRectangle, setSelectedRectangle] = useState(null);
    const [selectedRectangleA, setSelectedRectangleA] = useState(null);

    useEffect(() => {
        // Map the path to a key that represents each title
        const rectangleIndex = {
            '/evmnt-cal': 1,
            '/sev-ali': 2,
            '/libr-airie': 3,
            '/JoindreA': 4,
            '/CalenA': 1,

            //Major titles Activite

            '/Kal-G': 1,
            '/Sevi-ali': 2,
            '/Libr-aria': 3,

        };


        const rectangleAIndex = {
            '/CalenA': 1,
            '/InfrimA': 2,
            '/medoc': 2,
            '/codevestiA': 3,
            '/vesti-code': 3,
            '/PlaintesA': 4,
            '/pla-inte': 4,
            //Major titles Activite
            '/evmnt-cal': 1,
            '/Inf-irm': 2,
            '/Cod-v2': 3,
            '/Plt-div': 4,

        };

        // Check if the current path is one of the dropdown items
        const isDropdownItem = Object.keys(rectangleAIndex).includes(location.pathname);
        // Set the selected rectangle based on the current path
        setSelectedRectangleA(rectangleAIndex[location.pathname] || null);
        // Set the selected path based on the current path
        setSelectedRectangle(rectangleIndex[location.pathname] || 1);


        // Set the dropdown to open if the current path is a dropdown item


        if (isDropdownItem) {
            setDropdownStatesT(prevStates => ({
                ...prevStates,
                CalenA: true
            }));
            setDropdownOpen(true); // Ensure dropdownOpen state is also set
        }
    }, [location]);



    ///Search Engine
    const [searchTermE, setSearchTermE] = useState('');

    const handleSearchChangeE = (event) => {
        setSearchTermE(event.target.value);
    };

    const handleSearchSubmitE = () => {
        window.location.href = `/search?query=${encodeURIComponent(searchTermE)}`;
    };
    ///Search Engine /End

    // New function to handle key press
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchSubmitE();
        }
    };
    return (
        <div className="black-sectionA" ref={blackSectionERef} onClick={toggleGrid3}>

            <div className="mobile-menu-icon3" >
                {/* Hamburger Icon */}
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div className="CspFaza4">
                <h1>Activités</h1>
            </div>


            <div className={`grid-container3 ${isMenuOpenE ? 'open' : ''}`} ref={gridERef}>
                <div className="header-wrapEtu" >
                    <div className="headerEtu">
                        <div className="search-barEtu" >
                            <input
                                type="text"
                                placeholder="Chercher..."
                                value={searchTermE}
                                onChange={handleSearchChangeE}
                                ref={searchERef}
                                onKeyPress={handleKeyPress}
                            />
                            <i className="fa fa-search search-iconEtud" onClick={handleSearchSubmitE}></i>
                        </div>
                    </div>

                    <div className="Title-wrapperACti" >



                        <Link to="/evmnt-cal" ref={titleRef} onClick={handleInformationTClick} >

                            <div className={`CalenA ${selectedRectangle === 1 ? 'selectedvi' : ''} ${dropdownStatesT.CalenA ? 'dropdown-openT' : ''}`}>

                                <div className="EvnWrap" ref={titleRef}>
                                    <h1 className="info-title" ref={titleRef}>Calendrier d'évènement Faza</h1>



                                    <span className={`signC1 ${dropdownStatesT.CalenA ? 'minus-signC1' : 'plus-signC1'}`}>
                                        {dropdownStatesT.CalenA ? '_' : '+'}
                                    </span>


                                </div>

                                <div className={`dropdown-contentA1S ${dropdownStatesT.CalenA ? 'activeE' : ''}`}>

                                    <Link to="/CalenA" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/CalenA', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleAct ${selectedRectangleA === 1 ? 'selectedA' : ''}`}>
                                            <h1 className="info-titleA">Calendrier</h1>
                                        </div>
                                    </Link>



                                    <Link to="/InfrimA" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/InfrimA', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleAct ${selectedRectangleA === 2 ? 'selectedA' : ''}`}>

                                            <h1 className="info-titleA">Infirmerie</h1>
                                        </div>
                                    </Link >


                                    <Link to="/codevestiA" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/codevestiA', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleAct ${selectedRectangleA === 3 ? 'selectedA' : ''}`}>
                                            <h1 className="info-titleA">Code vestimentaire</h1>
                                        </div>
                                    </Link>


                                    <Link to="/PlaintesA" onClick={(e) => {
                                        e.preventDefault(); // Prevent default link behavior
                                        handleDelayedNavigationE('/PlaintesA', 250); // Adjust the delay as needed
                                    }}>
                                        <div className={`info-rectangleAct ${selectedRectangleA === 4 ? 'selectedA' : ''}`}>
                                            <h1 className="info-titleA">Plaintes et divulgation</h1>
                                        </div>
                                    </Link>

                                </div>

                            </div>
                        </Link>
                        <div className="title-lineA"></div>


                        <Link to="/sev-ali" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigationE('/sev-ali', 200)
                        }}>
                            <div>
                                <div className={`ServA ${selectedRectangle === 2 ? 'selectedvi' : ''}`}>
                                    <h1 className="info-title">Service Alimentaire</h1>
                                </div>
                            </div>
                        </Link>



                        <div className="title-lineA2"></div>

                        <Link to="/libr-airie" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigationE('/libr-airie', 200)
                        }}>
                            <div>
                                <div className={`LibA ${selectedRectangle === 3 ? 'selectedvi' : ''}`}>
                                    <h1 className="info-title">Librairie </h1>
                                </div>
                            </div>
                        </Link>

                        <div className="title-lineA3"></div>

                        <Link to="/JoindreA" onClick={(e) => {
                            e.preventDefault(); // Prevent default link behavior
                            handleDelayedNavigationE('/JoindreA', 200)
                        }}>
                            <div>
                                <div className={`JoindreA ${selectedRectangle === 4 ? 'selectedvi' : ''}`}>
                                    <h1 className="info-title">Nous Joindre </h1>
                                </div>
                            </div>
                        </Link>



                    </div>
                    <img src={newarrow2} alt="Golden Arrow" className="D-arrow2" onClick={toggleGrid3} />
                </div>
            </div>
        </div>
    );
};

export default BlackSectionA;