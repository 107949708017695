import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import HeaderA from './HeaderA';
import BlackSectionA from './BlackSectionA';
import './Cafetariat.scss';
import FooterA from './FooterA';
import InfoCaf from './InfoCafPage/InfoCaf';

const Cafetariat = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const location = useLocation(); // Get the current location

    return (
        <div>

            <HeaderA />

            <div className="Grey-section-Caf">

                <div className="Grey-wrap-Caf">
                    <div className="Grey-center-Caf">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaCaf-wrap">
                                <h1 className="Faza-Caf" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Cafarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposCaf-wrap">
                                <h1 className="AproposCaf" id="titleA">
                                    Étudiants
                                </h1>

                                <h2 className="CafarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AACaf" id="titleA">
                            Cafétariat
                        </h1>

                    </div>
                </div>

            </div>
            <BlackSectionA />

            <div className="infov-main">
                <h1 className="A1dmin" id="titleA">
                    <span class="bold-text">Cafétariat</span>
                </h1>

                <div className="infov-Caf">
                    <div className="infov-Border">



                        <InfoCaf />
                    </div>
                </div>
            </div>





            <FooterA />
        </div >
    );
};

export default Cafetariat;