import React, { useEffect } from 'react';

import './Infoetude.scss';

const Infoetude = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (


        <div className="Etudesection-wrap" >
            <div className="Etudesection-center">
                {/* Rest of your content */}


                <div className="First-Etudesection">
                    <h1 className="ToutE" id="title1">Tout sur les études à Faza</h1>


                    <h2 className="ToutEI" id="title1" >Bienvenue au CSP Faza, l'institution éducative emblématique de Niamey depuis 2011. Découvrez une école dynamique, moderne et respectée, offrant une expérience éducative exceptionnelle.</h2>
                    <h3 className="ToutEII" id="title1" > Au CSP Faza, notre engagement envers des valeurs éthiques guide notre approche éducative. Nous créons un environnement chaleureux, favorisant l'inclusion et l'épanouissement de chaque élève.</h3>
                </div>

                <div className="Etudesection-line"></div>
                <div className="First-Etudesection2">

                    <h1 className="EtudeF" id="title2">Évolution Dynamique depuis sa Création</h1>
                    <h1 className="EtudeFI" id="title2" >
                        Depuis nos débuts en 2011, le CSP Faza a tracé un chemin dynamique dans l'éducation, introduisant des programmes novateurs qui ont profondément impacté la communauté éducative à Niamey.
                        Distinguez-vous avec nous.</h1>

                    <h1 className="EtudeFII" id="title2" >
                        Le CSP Faza se démarque par son approche pédagogique distinctive, des projets innovants axés sur des contributions sociales significatives, et une renommée locale bien méritée.
                        Modernité et Infrastructure propices à l'Apprentissage
                        Explorez nos installations modernes, équipées des dernières technologies éducatives.  </h1>

                    <h1 className="EtudeFIII" id="title2" >
                        Au CSP Faza, nous créons un environnement stimulant pour inspirer et soutenir l'apprentissage de qualité. </h1>
                </div>

                <div className="Etudesection-line2"></div>
                <div className="First-Etudesection3">
                    <h1 className="FazE" id="title3">Communauté Éducative Engagée et Atmosphère Inclusive</h1>

                    <h1 className="FazEI" id="title3" >
                        Rejoignez une communauté éducative passionnée et un corps enseignant dévoué. Au CSP Faza, nous cultivons une atmosphère inclusive, propice à des approches pédagogiques innovantes.  </h1>

                    <h1 className="FazEII" id="title3" >
                        Célébrant plus d'une décennie de succès, le CSP Faza affiche un bilan impressionnant. Découvrez nos réalisations et nos ambitieuses perspectives d'avenir, témoignant de notre engagement envers une éducation de qualité.  </h1>
                </div>

            </div>
        </div>





    );

};


export default Infoetude;