import React, { useEffect } from 'react';

import './Infocouts.scss';

const Infocouts = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (


        <div className="Etude-Couts">
            <div className="Etude-Couts-center">
                {/* Rest of your content */}


                <div className="First-couts">
                    <div className="First-couts-Title">
                        <h1>Coût des ètudes à Faza</h1>

                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th >Classes</th>
                                <th>Étudiants Nigériens</th>
                                <th>Étudiants Étrangers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Jardin */}
                            <tr id="Jardinb" >
                                <td colspan="3">
                                    <h2>Jardin d'enfant</h2>
                                </td>

                            </tr>

                            <tr>
                                <td> <h1>Petite Maternelle</h1></td>
                                <td>
                                    <h1>100.000 F</h1>
                                </td>
                                <td>
                                    <h1>150.000 F</h1>
                                </td>

                            </tr>

                            <tr>
                                <td> <h1>Grande Maternelle</h1></td>
                                <td>
                                    <h1>150.000 F</h1>

                                </td>
                                <td>
                                    <h1>200.000 F</h1>

                                </td>

                            </tr>

                            {/* Primaire */}
                            <tr id="Jardinb">
                                <td colspan="3"><h2>Primaire</h2></td>
                            </tr>
                            <tr>
                                <td> <h1>CI</h1></td>
                                <td>
                                    <h3>200.000 F</h3>
                                </td>
                                <td>
                                    <h3>250.000 F</h3>
                                </td>

                            </tr>

                            <tr>
                                <td> <h1>CP</h1></td>
                                <td>
                                    <h3>200.000 F</h3>

                                </td>
                                <td>
                                    <h3>250.000 F</h3>
                                </td>
                            </tr>

                            <tr>
                                <td> <h1>CE1</h1></td>
                                <td>
                                    <h3>230.000 F</h3>
                                </td>
                                <td>
                                    <h3>280.000 F</h3>
                                </td>
                            </tr>

                            <tr>
                                <td> <h1>CE2</h1></td>
                                <td>
                                    <h3>230.000 F</h3>
                                </td>
                                <td>
                                    <h3>280.000 F</h3>
                                </td>
                            </tr>

                            <tr>
                                <td> <h1>CM1</h1></td>
                                <td>
                                    <h3>260.000 F</h3>


                                </td>
                                <td>
                                    <h3>300.000 F</h3>
                                </td>
                            </tr>

                            <tr>
                                <td> <h1>CM2</h1></td>
                                <td>
                                    <h3>280.000 F</h3>

                                </td>
                                <td>
                                    <h3>320.000 F</h3>
                                </td>
                            </tr>

                            {/* Collège*/}

                            <tr id="Jardinb">
                                <td colspan="3"><h2>Collège</h2></td>
                            </tr>
                            <tr>
                                <td> <h1>Sixième</h1></td>
                                <td>
                                    <h3>300.000 F</h3>
                                </td>
                                <td>
                                    <h3>350.000 F</h3>
                                </td>
                            </tr>

                            <tr>
                                <td> <h1>Cinquième</h1></td>
                                <td>
                                    <h3>300.000 F</h3>
                                </td>
                                <td>
                                    <h3>350.000 F</h3>
                                </td>
                            </tr>

                            <tr>
                                <td> <h1>Quatrième</h1></td>
                                <td>
                                    <h3>350.000 F</h3>
                                </td>
                                <td>
                                    <h3>400.000 F</h3>
                                </td>
                            </tr>

                            <tr>
                                <td> <h1>Troisième</h1></td>
                                <td>
                                    <h3>350.000 F</h3>

                                </td>
                                <td>
                                    <h3>400.000 F</h3>
                                </td>
                            </tr>

                            {/* Lycée*/}

                            <tr id="Jardinb">
                                <td colspan="3"><h2>Lycée</h2></td>
                            </tr>
                            <tr>
                                <td> <h1>Seconde</h1></td>
                                <td>
                                    <h3>400.000 F</h3>
                                </td>
                                <td>
                                    <h3>450.000 F</h3>

                                </td>
                            </tr>

                            <tr>
                                <td> <h1>Première</h1></td>
                                <td>
                                    <h3>450.000 F</h3>

                                </td>
                                <td>
                                    <h3>500.000 F</h3>

                                </td>

                            </tr>

                            <tr>
                                <td> <h1>Terminale</h1></td>
                                <td>
                                    <h3>500.000 F</h3>


                                </td>
                                <td>
                                    <h3>550.000 F</h3>

                                </td>

                            </tr>


                        </tbody>
                    </table>
                    <div className="First-couts-Title2">
                        <h1>Montants en vigueur en juillet 2023</h1>
                        <h2>Ces montants sont approximatifs et sujets à augmentation sans préavis. </h2>
                        <h3>Ils Incluent les droits de scolarité et frais (ex: frais généraux, frais technologiques, etc.) </h3>
                    </div>
                </div>
            </div>
        </div>




    );

};


export default Infocouts;