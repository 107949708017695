import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link and useLocation
import HeaderX from './HeaderX';
import BlackSectionX from './BlackSectionX';
import './Partenaires.scss';
import FooterX from './FooterX';
import InfoPart from './InfoPartPage/InfoPart';

const Partenaires = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const location = useLocation(); // Get the current location

    return (
        <div>

            <HeaderX />

            <div className="Grey-section-Part">

                <div className="Grey-wrap-Part">
                    <div className="Grey-center-Part">

                        <Link to="/"> {/* Add Link to navigate back to the main page */}
                            <div className="FazaPart-wrap">
                                <h1 className="Faza-Part" id="title1">
                                    Complexe Scolaire Privé Faza
                                </h1>
                                <h2 className="Partarow" id="title2">{'>'}</h2>
                            </div>
                        </Link>
                        <Link to={location.pathname}> {/* Link to navigate back to the current page */}
                            <div className="AproposPart-wrap">
                                <h1 className="AproposPart" id="titleA">
                                    Autres
                                </h1>

                                <h2 className="PartarowA" id="titleA2">{'>'}

                                </h2>
                            </div>

                        </Link>
                        <h1 className="AAPart" id="titleA">
                            Partenaires
                        </h1>

                    </div>
                </div>

            </div>
            <BlackSectionX />

            <div className="infov-main">
                <h1 className="A1Part" id="titleA">
                    <span class="bold-text">Partenaires</span>
                </h1>

                <div className="infov-Part">
                    <div className="infov-Border-Part">



                        <InfoPart />
                    </div>
                </div>
            </div>





            <FooterX />
        </div >
    );
};

export default Partenaires;