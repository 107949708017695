import React, { useState, useEffect } from 'react';


import './InfoConfid.scss';


const InfoConfid = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    const handleInformationAClick = (dropdownKey, event) => {
        event.preventDefault();
        // Toggle only the specific dropdown
        setDropdownStatesA(prevStates => ({
            ...prevStates,
            [dropdownKey]: !prevStates[dropdownKey],
        }));
    };



    const [dropdownStatesA, setDropdownStatesA] = useState({
        ConFAI: false,
        ConFAI2: false,



    });





    return (


        <div className="Etude-ConF">
            <div className="Etude-ConF-center">


                <div className="First-ConF">
                    <h1 className="DemanConF" id="title1">Confidentialité</h1>

                    <div className="ConF-line"></div>
                    <div className="First-ConF-wrap">

                        <div className="Etapes-ConF">
                            <h1 className="DemanConF2" id="title1" >Politique de confidentialité des renseignements personnels recueillis par un moyen technologique</h1>

                        </div>

                    </div>
                    <div className="First-ConF2">

                        <h1 className="ConFIs" id="title2">En vigueur le 1er janvier 2024.</h1>


                        <h1 className="ConFIsI" id="title2" >
                            Faza s’engage à respecter la confidentialité des renseignements personnels qui lui sont confiés par sa communauté et prend les mesures nécessaires pour assurer leur protection. L’Université est d’ailleurs soumise à la Loi sur l’accès aux documents des organismes publics et sur la protection des renseignements personnels (RLRQ, c. A-2.1).

                        </h1>


                    </div>

                    <div className="Etapes-ConF">
                        <h1 className="DemanConF2" id="title1" >1. Témoins de connexion</h1>

                    </div>

                    <h1 className="ConFIsI" id="title2" >
                        Un témoin est un fichier texte envoyé par un serveur web et emmagasiné sur un navigateur web afin qu’il puisse être consulté plus tard à partir de ce navigateur. Il est enregistré dans le répertoire de ce dernier qui, lorsqu’en cours d’exécution, le met en mémoire. Il peut également être emmagasiné sur l’unité locale de stockage de l’appareil informatique employé lorsque vous vous déconnectez de ce site ou serveur web.

                    </h1>


                    <h1 className="DemanConF2-" id="title1" >1. 1  Utilisation des témoins
                    </h1>

                    <h1 className="ConFIsI" id="title2" >
                        Faza utilise les témoins afin de collecter, directement ou par l’entremise de tiers autorisés, de l’information sur les activités des gens visitant ses sites web.
                    </h1>

                    <h1 className="ConFIsI" id="title2" >
                        Faza peut recourir à cette information dans le but notamment de reconnaître les visiteuses et visiteurs actuels et nouveaux de ses sites web, de mieux comprendre leurs champs d’intérêt et de les orienter vers certains types de contenu, pour ainsi améliorer ses sites web et fournir une meilleure expérience utilisateur.
                    </h1>
                    <h1 className="ConFIsI" id="title2" >
                        Faza ne recueille que les renseignements nécessaires à la réalisation de sa mission et ne les utilise qu’aux fins et pour la durée requise pour lesquelles ils ont été récoltés.
                    </h1>


                    <h1 className="DemanConF2-" id="title1" >1. 2 Types de témoins
                    </h1>

                    <h1 className="ConFIsI" id="title2" >
                        Les témoins sont classés dans les catégories suivantes :
                    </h1>

                    <h1 className="ConFIsI" id="title2" >

                        <li>
                            <span class="bold-text">Témoins strictement nécessaires</span>  – Ils sont essentiels au fonctionnement du site web et ne peuvent être désactivés. Ils ne stockent aucune information d’identification personnelle.
                        </li>
                        <li>
                            <span class="bold-text">Témoins de fonctionnalité</span>  – Ils permettent d’améliorer et de personnaliser des fonctionnalités du site web (envoi d’une communication électronique, langue et résolution d’affichage, etc.).
                        </li>
                        <li>
                            <span class="bold-text">Témoins publicitaires</span>  – Ils sont mis en place par des partenaires publicitaires et employés pour établir un profil de vos champs d’intérêt et vous proposer, par exemple, des publicités de Faza sur d’autres sites web. Ils ne stockent pas de données personnelles, mais utilisent des identifiants uniques liés à votre navigateur.
                        </li>
                        <li>
                            <span class="bold-text">Témoins de performance</span>   – Ils fournissent des statistiques sur la fréquentation des sites de Faza (sources de trafic, pages les plus vues, mots recherchés, etc.) et permettent de comprendre comment vous y naviguez. Toutes les informations collectées par ces témoins sont anonymisées et ne vous identifient pas.
                        </li>
                    </h1>


                    <h1 className="DemanConF2-" id="title1" >1. 3 Gestion des témoins
                    </h1>

                    <h1 className="ConFIsI" id="title2" >
                        Lors de la visite initiale du site de Faza vous devrez déterminer les types de témoins dont vous acceptez l’utilisation. Il est possible, par la suite, de gérer les témoins installés dans les paramètres du navigateur. Il est à noter que la désactivation ou la suppression des témoins peut avoir une incidence sur la capacité à utiliser certaines fonctionnalités du side web de Faza. De plus, cette procédure doit être répétée pour chacun des navigateurs et appareils exploités.
                    </h1>

                    <h1 className="ConFIsI" id="title2" >
                        Pour en savoir plus sur la manière de consulter ou rectifier les renseignements personnels recueillis, vous pouvez regarder les outils de soutien de chaque navigateur (ex. : <a href="https://support.google.com/chrome/answer/95647?hl=fr&p=cpn_cookies"><span class="Link-text">Chrome</span></a>, <a href="https://support.mozilla.org/fr/products/firefox/protect-your-privacy/cookies"><span class="Link-text">Firefox</span></a>, <a href="https://support.microsoft.com/fr-fr/windows/supprimer-et-g%C3%A9rer-les-cookies-168dab11-0753-043d-7c16-ede5947fc64d"><span class="Link-text">Internet Explorer</span></a>, ou <a href="https://support.apple.com/fr-ca/guide/safari/sfri11471/mac"><span class="Link-text">Safari</span></a>.
                    </h1>






                    <div className="Etapes-ConF">
                        <h1 className="DemanConF2" id="title1" >2. Renseignements personnels recueillis</h1>

                    </div>




                    <h1 className="DemanConF2-" id="title1" >2.1 Description des renseignements personnels recueillis
                    </h1>

                    <h1 className="ConFIsI" id="title2" >
                        Dans certains cas, les renseignements personnels suivants sont recueillis à l’aide de témoins :

                        <ul>
                            <li>
                                Votre adresse IP
                            </li>
                            <li>
                                Votre identifiant client (numéro que Google attribue à chaque visiteur dans Google Analytics)
                            </li>
                            <li>
                                Votre fournisseur de services Internet
                            </li>
                            <li>
                                Le type et le modèle de votre appareil (ex. : iPhone 11)
                            </li>

                            <li>
                                La résolution d’écran de votre appareil
                            </li>

                            <li>
                                Le type, la langue, la version et les autres données relatives à votre navigateur (ex. : Chrome, Safari)
                            </li>

                            <li>
                                Votre région ou municipalité, déterminée d’après votre adresse IP
                            </li>

                            <li>
                                Le point d’origine (ex. : bannière, courriel, réseau social, etc.)
                            </li>

                            <li>
                                Les pages consultées sur uqam.ca (la séquence de consultation, les interactions dans la page, la date, l’heure, la durée et la fréquence de vos visites et activités [clics, défilement d’écran, etc.])
                            </li>


                        </ul>
                    </h1>
                    <h1 className="DemanConF2-" id="title1" >2. 2 Accès aux renseignements personnels recueillis
                    </h1>

                    <h1 className="ConFIsI" id="title2" >
                        Seuls le personnel et les consultantes et consultants autorisés de Faza peuvent avoir accès aux renseignements personnels recueillis. Faza s’assure que ces personnes ont qualité pour ce faire et que cela est nécessaire dans l’exercice de leurs fonctions.
                    </h1>


                    <h1 className="DemanConF2-" id="title1" >2. 3 Sécurité des renseignements personnels recueillis
                    </h1>

                    <h1 className="ConFIsI" id="title2" >
                        Faza met en place des mesures de sécurité raisonnables afin d’assurer la confidentialité des renseignements personnels recueillis, utilisés, communiqués, conservés, détruits ou anonymisés, dont des mesures techniques (ex. : pare-feu, antivirus, etc.) et physiques (ex. : systèmes d’alarme, mise sous clé des bureaux et classeurs, etc.).
                    </h1>





                    <div className="Etapes-ConF">
                        <h1 className="DemanConF2" id="title1" >3. Autres sites web</h1>

                    </div>


                    <h1 className="ConFIsI" id="title2" >Le site web de Faza peut comporter des liens vers d’autres sites. En cliquant sur ceux-ci, vous quittez les sites de Faza, et celle-ci n’est pas responsable du contenu ni des règles applicables sur ces autres sites.
                    </h1>

                    <div className="Etapes-ConF">
                        <h1 className="DemanConF2" id="title1" >4. Information et questions</h1>

                    </div>


                    <h1 className="ConFIsI" id="title2" >Toute question concernant cette politique et tout exercice des droits d’accès et de rectification en conformité avec la Loi sur l’accès aux documents des organismes publics et sur la protection des renseignements personnels peuvent être adressés à la secrétaire générale, au secrétaire général de l’UQAM à titre de responsable de la protection des renseignements personnels : <span class="Link-text"><a href="mailto:cspFaza@gmail.com">cspFaza@gmail.com</a></span>
                    </h1>








                    <div className="ConF-line2"></div>
                </div>



                <div className="First-ConF">
                    <div className="Etapes-ConF2">

                        <h1 className="ConFFI" id="title2">Avis de Modification.</h1>
                    </div>
                    <div className="Drop-ConF-wrap">
                        <div className="Pieces-ConF" >
                            <div className={`ConFII  ${dropdownStatesA.ConFAI ? 'dropdownConF-open' : 'dropdownConF-closed'}`}>

                                <div className="ACONWrap" onClick={(e) => handleInformationAClick('ConFAI', e)}>

                                    <h1 className="ConFAI" > Avis du 1er Février 2024
                                    </h1>

                                    <span className={`signCON ${dropdownStatesA.ConFAI ? 'minus-signCON' : 'plus-signCON'}`} >
                                        {dropdownStatesA.ConFAI ? '_' : '+'}
                                    </span>

                                </div>

                                {/* Dropdown content */}

                                <div className={`dropdown-contentConF ${dropdownStatesA.ConFAI ? 'active' : ''}`}>

                                    <div className="firstConF">


                                        <h1 className="Info-titleConF">

                                            <h1 className="ConFFI2" id="title1" >1er Février 2024 </h1>
                                            <ul>
                                                <li>
                                                    Article 1 – Ajout d’un hyperlien menant au paramétrage des témoins de connexion afin de faciliter un tel paramétrage
                                                </li>
                                                <li>
                                                    Article 2.1 – Ajout d’une liste exhaustive des renseignements personnels qui peuvent être recueillis par Faza par un moyen technologique
                                                </li>
                                                <li>
                                                    Article 2.2 – Ajout d’une précision quant aux autorisations d’accès aux renseignements personnels recueillis par un moyen technologique par les membres du personnel de Faza et ses consultantes et consultants, ces accès étant autorisés conformément à la Directive sur le traitement et la protection des renseignements personnels
                                                </li>


                                            </ul>

                                            <h1 className="ConFIsI" id="title1" >Les modifications ci-dessus énoncées entreront en vigueur dès le 1er janvier 2024, le tout afin de permettre à Faza de se conformer au Règlement sur les politiques de confidentialité des organismes publics recueillant des renseignements personnels par un moyen technologique, D. 1544-2023 (G.O. II), art. 2. </h1>


                                        </h1>


                                    </div>


                                </div>
                            </div>

                        </div>

                        {/* Débloquer au besoin 

                        <div className="Pieces-ConF2">
                            <div className={`ConFFII  ${dropdownStatesA.ConFAI2 ? 'dropdownConF-open' : 'dropdownConF-closed'}`}>
                                <h1 className="ConFAI2" onClick={(e) => handleInformationAClick('ConFAI2', e)} id="title1" >Normes respectées pour l’accessibilité éditoriale</h1>



                              

                                <div className={`dropdown-contentConF ${dropdownStatesA.ConFAI2 ? 'active' : ''}`}>

                                    <div className="SecondConF">
                                        <h1 className="Info-titleConF">
                                            <h1 className="ConFFI2" id="title1" >Mettre en forme les contenus de manière accessible </h1>
                                            <ul>
                                                <li>
                                                    Ne pas justifier le texte
                                                </li>
                                                <li>
                                                    Éviter les sauts de lignes multiples
                                                </li>

                                            </ul>

                                            <h1 className="ConFFI2" id="title1" >Utiliser correctement la hiérarchie des titres</h1>

                                            <h1 className="ConFFI2" id="title1" >Écrire des liens de manière accessible</h1>

                                            <ul>
                                                <li>
                                                    Rédiger des intitulés de liens explicites
                                                </li>
                                                <li>
                                                    Savoir utiliser les titres de liens, en dernier recours
                                                </li>


                                            </ul>

                                            <h1 className="ConFFI2" id="title1" >Utiliser les images de manière accessible</h1>

                                            <ul>
                                                <li>
                                                    Images décoratives/illustratives : ne pas rédiger de texte de remplacement
                                                </li>
                                                <li>
                                                    Images informatives : rédiger un texte de remplacement
                                                </li>

                                                <li>
                                                    Images-liens : rédiger un texte de remplacement qui décrit la fonction du lien
                                                </li>
                                                <li>
                                                    Images informatives complexes : rédiger un court texte de remplacement ainsi qu’une description détaillée
                                                </li>

                                            </ul>

                                            <h1 className="ConFFI2" id="title1" >Utiliser correctement les listes à puces et listes numérotées</h1>

                                            <h1 className="ConFFI2" id="title1" >Écrire les contenus de manière accessible</h1>

                                            <ul>
                                                <li>
                                                    Conserver les accents sur les lettres capitales
                                                </li>
                                                <li>
                                                    Expliciter les abréviations
                                                </li>

                                                <li>
                                                    Déclarer les citations en ligne et les blocs de citations
                                                </li>
                                                <li>
                                                    Ne pas faire référence à un élément en se basant uniquement sur sa couleur, sa forme ou sa position
                                                </li>

                                            </ul>

                                            <h1 className="ConFFI2" id="title1" >Utiliser correctement les couleurs et les contrastes</h1>

                                            <ul>
                                                <li>
                                                    Veiller à ce que les contrastes entre le texte et la couleur d’arrière-plan soient suffisants
                                                </li>
                                                <li>
                                                    Veiller à ce que les couleurs ne soient pas le seul moyen pour véhiculer de l’information
                                                </li>


                                            </ul>

                                            <h1 className="ConFFI2" id="title1" >Utiliser correctement les contenus riches et multimédias</h1>

                                            <ul>
                                                <li>
                                                    Vérifier la possibilité de mettre en pause les contenus en mouvement
                                                </li>


                                            </ul>


                                        </h1>
                                    </div>

                                </div>

                            </div>


                        </div>
                    */}

                    </div>
                </div>

                <div className="ConF-line3"></div>






            </div>
        </div>





    );

};


export default InfoConfid;