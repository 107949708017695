import React, { useEffect } from 'react';

import './InfoTrava.scss';
import logoP from './Images/Subject.png'; // Update the path accordingly
const InfoTrava = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (


        <div className="Etude-Trav">
            <div className="Etude-Trav-center">
                <h1 className="DemanAT" id="title1">Cette page sera bientôt disponible</h1>
                <img className="new-logoT" src={logoP} alt="" />

                {/* Rest of your content 
                <h1 className="Jobc" id="title1">Carrières</h1>

                <div className="First-Trav">
                    <h1 className="Job" id="title1">Professeur de Mathématiques.</h1>
                    <h1 className="JobI" id="title1" >Un poste de professeur de mathematique est disponible au Csp Faza </h1>
                    <h1 className="JobII" id="title1" > Au CSP Faza, notre engagement envers des valeurs éthiques guide notre approche éducative. Nous créons un environnement chaleureux, favorisant l'inclusion et l'épanouissement de chaque élève.</h1>

                </div>

                <div className="Trav-line"></div>
                <div className="First-Trav2">

                    <h1 className="Job2" id="title2">Professeur de Français</h1>
                    <h1 className="Job2I" id="title2" >
                        Un poste de professeur de français est disponible au Csp Faza .</h1>

                    <h1 className="Job2II" id="title2" >
                        Le CSP Faza se démarque par son approche pédagogique distinctive, des projets innovants axés sur des contributions sociales significatives, et une renommée locale bien méritée.
                        Modernité et Infrastructure propices à l'Apprentissage
                        Explorez nos installations modernes, équipées des dernières technologies éducatives.  </h1>
                </div>

                <div className="Trav-line2"></div>
                <div className="First-Trav3">
                    <h1 className="Job3" id="title3">Professeur d'EPS</h1>

                    <h1 className="Job3I" id="title3" >
                        Un poste de professeur de d'EPS est disponible au Csp Faza .  </h1>

                    <h1 className="Job3II" id="title3" >
                        Célébrant plus d'une décennie de succès, le CSP Faza affiche un bilan impressionnant. Découvrez nos réalisations et nos ambitieuses perspectives d'avenir, témoignant de notre engagement envers une éducation de qualité.  </h1>

                </div>
                */}
            </div>
        </div>

    );

};


export default InfoTrava;